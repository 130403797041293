import React from "react";
import Header from "../utils/header";
import PendingDocument from "../components/documents/toReviewTable/pendingDocument";

const PendingDocumentPage = () => {
  return (
    <div>
      <Header />
      <PendingDocument />
    </div>
  );
};

export default PendingDocumentPage;
