var initialState = {
}

const Document = (state = initialState, { type, payload }) => {
  switch (type) {
    // case "RESPONSE_USER_TOKEN_BALANCE":
    //   return {
    //     ...state,
    //     balanceData: payload,
    //   }
    default:
      return state
  }
}
export default Document
