import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import SucessImage from "../../../../assets/images/receipt/Group.svg";
import DocumentImage from "../../../../assets/images/receipt/image 2.svg";
import { Link } from "react-router-dom";
import {useParams} from 'react-router-dom';
import HighlightedImage from './HighlightedImage';
import Fuse from 'fuse.js';
import EventBus from "eventing-bus"
import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import Select from 'react-select';
import { overlayLoader } from "../../../../store/actions/Settings"
import { connect } from "react-redux"

const response = {
  // Your response object here
};

const defaultOptions = [
  { value: '', label: 'Connect Your ERP First' },
];

const requiredKeys = [
"Vendor Number",
"Invoice Number",
"Purchase Order",
"Payment Terms",
"Freight",
"Retention Amount",
"Document Type",
];

const queryKeys = [
  "What is the vendor name?",
  "What is the invoice date?",
  "What is the due date?",
  "What is the total?",
  "How much is the tax?"
];

const queryAliasMapping = [
  {question: "What is the vendor name?", alias: "Vendor"},
  {question: "What is the invoice date?", alias: "Invoice Date"},
  {question: "What is the due date?", alias: "Due Date"},
  {question: "What is the total?", alias: "Total"},
  {question: "How much is the tax?", alias: "Tax"}
];

const fuzzySearchOptions = {
// This will search in the keys of each entry in the object
keys: ['name', "value"],
// Threshold is a decimal indicating how closely the match must be. A value of 0 requires a perfect match, a value of 1 would match anything.
threshold: 0.3,
includeScore: true, // This includes the score in the result
};

const PendingDocument = (props) => {
  const [singledocument, setSingleDocument] = useState([]);
  const [clickedData, setClickedData] = useState('');
  const [matchedData, setMatchedData] = useState([]);
  const [matchedData2, setMatchedData2] = useState([]);
  const [ocrData, setOcrData] = useState([]);
  const [matches, setMatches] = useState({});
  const [glAccounts, setGlAccounts] = useState({});
  const [gloptions, setGlOptions] = useState({});
  const [selectedValue, setSelectedValue] = useState('');
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [additional, setAdditional] = useState(false);
  const [loading, setLoading] = useState(false);
  const [invoiceDate, setInvoiceDate] = useState(false);
  const [dueDate, setDueDate] = useState(false);
  const [queryMatches, setQueryMatches] = useState([]);
  const navigate = useNavigate();

const {id} = useParams();
  useEffect(() => {
    getsingleDocument()
    getAllAccounts()
  }, []);


const showAdditional = (event) => {
  setAdditional(!additional)
}
 // Function to update state when the user selects an option
 const handleSelectChange = (event) => {
   console.log('handleSelectChange', event);
   const value = event.value;
   setSelectedValue(value);

   // Find the account that matches the selected value
   if(value){
     const account = glAccounts.QueryResponse.Account.find(account => account.Name === value);
     setSelectedAccount(account); // Store the full object of the selected account
     console.log("Selected Account:", account);
   }
 };

 const getAllAccounts = () => {
   console.log('getAllAccounts');
   axios.get('/api/user/getAllGLAccounts', {
     headers: {
       'Access-Control-Allow-Origin': '*',
       mode: 'no-cors',
       Authorization: `${localStorage.getItem('token')}`,
     },
   })
   .then(function (response) {
     console.log('response api getAllAccounts', JSON.parse(response.data.body));
     const parsedData = JSON.parse(response.data.body);
     setGlAccounts(parsedData); // Assuming parsedData is the array you want to store

     const options = parsedData.QueryResponse.Account.map(item => ({
       value: item ? item.Name : "Nothing Here",
       label: item ? item.FullyQualifiedName : "Nothing Here"
     }));
     setGlOptions(options); // Now 'options' is defined in the right scope
   })
   .catch(function (error) {
     console.log('getAllAccounts error');

     setGlOptions(defaultOptions)
     // EventBus.publish("error", error)
     console.error('Error:', error);
   });
 };

  const updateDetails = () =>{
    console.log('final result', matches, selectedAccount);
    console.log('queryMatches', queryMatches);
    if(queryMatches[0]['answer'] == "Answer not found" || queryMatches[1]['answer'] == "Answer not found" || matches['Invoice Number']['value'] == "Not Found" || !selectedAccount){
      EventBus.publish("error", "Please fill the required fields.")
    }else{
      try{
        setLoading(true);
        const totalString = queryMatches[3].answer.replace(/\$/g, '').replace(/,/g, '');
        const totalValue = parseFloat(totalString);

        const taxString = queryMatches[4].answer.replace(/\$/g, '').replace(/,/g, '');
        const taxValue = parseFloat(taxString);

        const freightString = matches.Freight.value.replace(/\$/g, '').replace(/,/g, '');
        const freightValue = parseFloat(freightString);

        console.log('strings', totalValue, taxValue, freightValue);
        const apiUrl = '/api/document/updateDocumentData';
        const requestData = {
          documentId:singledocument._id,
          fileName: singledocument.fileName,
          url:singledocument.url,
          // metaData:'',
          purchaseOrder:matches['Purchase Order']['value'],
          data:ocrData.final_map,
          userId:localStorage.getItem('userId'),
          vendor:queryMatches[0].answer,
          invoiceDate:new Date(queryMatches[1]['answer']),
          invoiceNumber:matches['Invoice Number']['value'],
          total:totalValue,
          tax:taxValue,
          paymentTerms:matches['Payment Terms']['value'],
          freight:freightValue,
          documentType:selectedAccount.AccountType,
          accountId:selectedAccount.Id
        };
        const headers = {
          'Authorization': localStorage.getItem('token'),
          'Content-Type': 'application/json' // Assuming you're sending JSON data
        };
          axios.post(apiUrl, requestData, { headers })
          .then(function (response) {
            console.log('response api', response);
            getsingleDocument()
            setLoading(false);
            // approveDocument()
          })
          .catch(function (error) {
            EventBus.publish("error", error)
            setLoading(false);
            console.error('Error:', error);
            console.log(error);
          });
        } catch (error) {
          EventBus.publish("error", error)
          console.error('Error:', error);
          setLoading(false);
        }
    }
  }

  const approveDocument = () =>{
    if(queryMatches[0]['answer'] == "Answer not found" || queryMatches[1]['answer'] == "Answer not found" || matches['Invoice Number']['value'] == "Not Found" || !selectedAccount){
      EventBus.publish("error", "Update Document First.")
    }else{
      props.overlayLoader({
        status: true,
        loadingMessage: "Approve Document",
      })
      console.log('approveDocument', singledocument._id);
      try{
        const apiUrl = '/api/document/approveDocument';
        const requestData = {
          documentId:singledocument._id,
        };
        const headers = {
          'Authorization': localStorage.getItem('token'),
          'Content-Type': 'application/json' // Assuming you're sending JSON data
        };
        axios.post(apiUrl, requestData, { headers })
          .then(function (response) {
            props.overlayLoader({
              status: false,
              loadingMessage: "Approve Document",
            })
            navigate("/documents");
            EventBus.publish("success", 'Document Approved')
            setLoading(false);
            console.log('response api', response);
          })
          .catch(function (error) {
            EventBus.publish("error", error)
            console.error('Error:', error);
            console.log(error);
            setLoading(false);
            props.overlayLoader({
              status: false,
              loadingMessage: "Approve Document",
            })
          });
        } catch (error) {
          EventBus.publish("error", error)
          console.error('Error:', error);
          setLoading(false);
          props.overlayLoader({
            status: false,
            loadingMessage: "Approve Document",
          })
        }
    }
  }

  const getsingleDocument = () =>{
    props.overlayLoader({
      status: true,
      loadingMessage: "Fetching Details.",
    })
  try{
    axios.get(`/api/document/getSingleDocument/${id}`,{
      })
      .then(function (response) {
        console.log('response api getsingleDocument', response);
        setFuzzy(response.data.body.final_map)
        setSingleDocument(response.data.body)
        setOcrData(response.data.body)
        extractGeometricValues(response.data.body)
        const mappedResults = setQueryRes(response.data.body.query_results, queryAliasMapping);
        setQueryMatches(mappedResults);
        extractGeometricValues2(response.data.body);
        props.overlayLoader({
          status: false,
          loadingMessage: "Fetching Details.",
        })
      })
      .catch(function (error) {
        console.error('Error:', error);
        console.log(error);
        props.overlayLoader({
          status: false,
          loadingMessage: "Fetching Details.",
        })
      });
    } catch (error) {
      console.error('Error:', error);
      props.overlayLoader({
        status: false,
        loadingMessage: "Fetching Details.",
      })
    }
  }

  const normalizeDataKeys = (data) => {
    const normalizedData = {};
    Object.keys(data).forEach(key => {
      let normalizedKey = key.toLowerCase();
      if (normalizedKey.includes("order") || normalizedKey.includes("invoice no")) {
        normalizedKey = "Invoice Number";
      }

      if (normalizedKey.includes("PO Number") || normalizedKey.includes("PO")) {
        normalizedKey = "Purchase Order";
      }

      normalizedData[normalizedKey] = data[key];
    });
    return normalizedData;
  };

const identifyInvoiceNumber = (data) => {
  const invoiceAliases = ["invoice number", "order no", "no:"];
  let invoiceNumber = "Not Found";

  for (const key of Object.keys(data)) {
    const normalizedKey = key.toLowerCase();
    if (invoiceAliases.some(alias => normalizedKey.includes(alias))) {
      invoiceNumber = data[key];
      break;
    }
  }
  return invoiceNumber;
};

  // const setFuzzy = (final) =>{
  // const items = Object.keys(final).map(key => ({ name: key }));
  // const fuse = new Fuse(items, fuzzySearchOptions);
  // console.log('setFuzzy items', items);
  // console.log('setFuzzy fuse', fuse);
  // let tempMatches = {};
  //
  // requiredKeys.forEach((key) => {
  //   const results = fuse.search(key);
  //   if (results.length > 0) {
  //     // Selecting the last match assuming it's the most relevant one
  //     const lastMatch = results[results.length - 1].item;
  //     tempMatches[key] = { originalKey: lastMatch.name, value: final[lastMatch.name] };
  //   } else {
  //     tempMatches[key] = { originalKey: 'Not Found', value: 'Not Found' };
  //   }
  // });
  // console.log('tempMatches', tempMatches);
  // setMatches(tempMatches);
  // }
  const setQueryRes = (results, queryAliasMapping) => {
    return queryAliasMapping.map(mapping => {
      const foundResult = results.find(result => result.Question === mapping.question);
      return {
        question: mapping.question,
        answer: foundResult ? foundResult.Answer : 'No answer found',
        confidence: foundResult ? foundResult.Confidence : '0%',
        alias: mapping.alias
      };
    });
    console.log('queryAliasMapping', queryAliasMapping);
  };

  const setFuzzy = (finalMap) => {
    const items = Object.keys(finalMap).map(key => ({ name: key, value: finalMap[key] }));
    const fuse = new Fuse(items, fuzzySearchOptions);
    let tempMatches = {};

    // Define aliases for keys that might have different labels
    const keyAliases = {
        "Invoice Number": ["invoice number", "order no", "order number", "no:"],
        "Purchase Order": ["purchase order", "PO number", "po:", "po no"]
    };

    requiredKeys.forEach(key => {
        let found = false;
        // Check if there are aliases for the current key
        if (keyAliases[key]) {
            // Iterate through each alias to see if there's a match
            for (const alias of keyAliases[key]) {
                const results = fuse.search(alias);
                if (results.length > 0) {
                    const match = results[0].item;
                    tempMatches[key] = {
                        originalKey: match.name,
                        value: match.value,
                        matchedBy: alias // Save the alias it matched by
                    };
                    found = true;
                    break;
                }
            }
        }
        // If no alias matched or there are no aliases, do a direct match
        if (!found) {
            const results = fuse.search(key);
            if (results.length > 0) {
                const match = results[0].item;
                tempMatches[key] = {
                    originalKey: match.name,
                    value: match.value,
                    matchedBy: 'original' // Indicates matched by the original key
                };
                // if(key == "Invoice Date"){
                //   setInvoiceDate(match.value)
                // }
                // if(key == "Due Date"){
                //   console.log('setDueDate', match.value);
                //   setDueDate(match.value)
                // }
            } else {
              tempMatches[key] = {
                  originalKey: 'Not Found',
                  value: 'Not Found',
                  matchedBy: 'none' // Indicates no match was found
              };
            }
        }
    });
    console.log('tempMatches', tempMatches);

    setMatches(tempMatches);
};

//   const setFuzzy = (finalMap) => {
//   const final = normalizeDataKeys(finalMap);
//   const items = Object.keys(final).map(key => ({ name: key, value: final[key] }));
//   const fuse = new Fuse(items, fuzzySearchOptions);
//   console.log('Fuzzy initialized with items:', items);
//
//   let tempMatches = {};
//   requiredKeys.forEach((key) => {
//     const results = fuse.search(key);
//     if (results.length > 0) {
//       const match = results[0].item; // Choosing the best match
//       tempMatches[key] = { originalKey: match.name, value: match.value };
//     } else {
//       tempMatches[key] = { originalKey: 'Not Found', value: 'Not Found' };
//     }
//   });
//   console.log('tempMatches', tempMatches);
//   setMatches(tempMatches);
// };

  const extractGeometricValues = async (data) => {
    console.log('extractGeometricValues', data);
  const finalMap = data.final_map
  const updatedKeys = data.updated_keys

  const metaData = data.metaData
  const matchData = () => {
    // Assuming metaData.Blocks contains the array of objects
    const blocks = metaData.Blocks;

    const matches = Object.keys(finalMap).map(key => {
      // Attempt to find a block where the Text includes the key or is exactly the key
      // Note: Adjust the logic here if the key matching needs to be more flexible
      const entry = blocks.find(block => block.Text && (block.Text.includes(key) || block.Text === key));

      if (entry) {
        // Destructuring to get the needed properties from the entry
        const { BlockType, Confidence, Text, Geometry } = entry;
        return { BlockType, Confidence, Text, Geometry };
      }
      return null;
    }).filter(entry => entry !== null); // Filter out any null entries

    setMatchedData(matches);
  };

  matchData();
}

const extractGeometricValues2 = async (data) => {
  console.log('extractGeometricValues2', data);

  // Error handling for data structure
  if (!data || !data.metaData || !data.metaData.Blocks) {
    console.error('Invalid data structure:', data);
    return;
  }

  const blocks = data.metaData.Blocks;
  console.log('blocks', blocks);
  console.log('blocks query', queryMatches);

  const matches2 = queryMatches.map(key => {
    // Attempt to find a block where the Text includes the key answer or is exactly the key answer
    const entry2 = blocks.find(block => block.Text && (block.Text.includes(key.answer) || block.Text === key.answer));

    if (entry2) {
      // Destructuring to get the needed properties from the entry
      const { BlockType, Confidence, Text, Geometry } = entry2;
      return { BlockType, Confidence, Text, Geometry };
    }
    return null;
  }).filter(entry2 => entry2 !== null); // Filter out any null entries

  setMatchedData2(matches2);
  console.log('matchData2', matches2);
};

  const selectSection = async (key, value) => {
  console.log('selectSection', key, value);
  const entry = matchedData.find(block => block.Text && (block.Text.includes(key) || block.Text === key));
  console.log('entry', entry);
  setClickedData(entry);
  }

  const selectSection2 = async (key, value) => {
  console.log('selectSection', key, value);
  const entry = matchedData.find(block => block.Text && (block.Text.includes(value) || block.Text === value));
  console.log('entry', entry);
  setClickedData(entry);
  }

  const isValidDate = (dateString) => {
    if (!dateString) return false;
    const date = new Date(dateString);
    return date instanceof Date && !isNaN(date);
  };

  const handleInputChange = (value, index) => {
    const updatedMatches = [...queryMatches];
    updatedMatches[index].answer = value instanceof Date ? value.toISOString() : value;
    setQueryMatches(updatedMatches);
  };


  return (
    <div>
      <div className="container-class">
        <div className="flex items-center justify-between border-b border-b-[#ccc] pt-[50px] pb-[20px]">
          <div>
            <p className="text-[#052460] font-semibold text-base">
              {singledocument.fileName}
            </p>
          </div>
          {/* buttons */}
          <div className="flex items-center ">
            <div className="border border-[#052460] bg-[#052460] rounded-[5px] mx-2.5">
              <button className="py-[12px] px-12 text-white text-xs font-normal">
                Email to Vendor
              </button>
            </div>
            <div className="border border-[#052460] rounded-[5px]">
              <button className="py-[12px] px-12 text-[#052460] text-xs font-normal">
                Comments
              </button>
            </div>
          </div>
        </div>
        {/*

        */}
        <div className="flex items-start py-[35px]">
          <div className="border border-[#ccc] bg-[#F9F9F9] rounded-[10px] w-[40%]">
          <table className="table-auto w-full border-collapse">
            <thead>
              <tr>
                <th className="px-4 py-2 bg-gray-100 border">Key</th>
                <th className="px-4 py-2 bg-gray-100 border">Value</th>
              </tr>
            </thead>
            {console.log('ocrData ocrData ocrData ', ocrData, 'matches matches matches', matches)}
            <tbody>
            {queryMatches.map((quer, index) => (
              <tr key={index} className="border">
                <td className="px-4 py-2" onClick={() => selectSection2(quer.alias, quer.answer)}>
                  {quer.alias}
                </td>
                <td className="px-4 py-2 flex">
                  {(quer.alias === "Invoice Date" || quer.alias === "Due Date") ? (
                    <DatePicker
                      onChange={(date) => handleInputChange(date, index)}
                      value={isValidDate(quer.answer) ? new Date(quer.answer) : null}
                      format="y-MM-dd"
                    />
                  ) : (
                    <input
                      type="text"
                      value={quer.answer}
                      onChange={(e) => handleInputChange(e.target.value, index)}
                    />
                  )}
                </td>
              </tr>
            ))}

            {
              ocrData && ocrData.final_map &&
              <>
                {Object.entries(matches).map(([searchKey, { originalKey, value }]) => (
                  <tr key={searchKey} className="border">
                    <td className="px-4 py-2" onClick={() => selectSection(originalKey, value)}>{searchKey}</td>
                    {/*
                      <td className="px-4 py-2" onClick={() => selectSection(originalKey, value)}>{value}</td>
                      // Update the state with the new matches
                      // Create a copy of matches
                      // Assuming you have a function to update state named `setMatches`
                      // Update the value for the current key
                      */}
                      <td className="px-4 py-2 flex">
                      {searchKey === 'Vendor Number' ?
                        <span>{value}</span> // Render as a span if the field should not be editable
                        :
                        <input
                          type="text"
                          value={value}
                          onChange={(e) => {
                            const updatedValue = e.target.value;
                            const updatedMatches = { ...matches }; // Create a copy of matches
                            updatedMatches[searchKey].value = updatedValue; // Update the value for the current key
                            // Update the state with the new matches
                            // Assuming you have a function to update state named `setMatches`
                            setMatches(updatedMatches);
                          }}
                        />
                      }
                      </td>
                      {/*
                        <td className="px-4 py-2 flex">
                          {searchKey === 'Vendor Number' ? (
                            <span>{value}</span> // Render as a span if the field should not be editable
                          ) : searchKey === 'Vendor' || searchKey === 'Invoice Number' ||searchKey === 'Total' ? (
                            <>*<input
                              type="text"
                              value={value}
                              onChange={(e) => {
                                const updatedValue = e.target.value;
                                const updatedMatches = { ...matches }; // Create a copy of matches
                                updatedMatches[searchKey].value = updatedValue; // Update the value for the current key
                                // Update the state with the new matches
                                // Assuming you have a function to update state named `setMatches`
                                setMatches(updatedMatches);
                              }}
                            /> </>
                          ) : searchKey === 'Invoice Date' || searchKey === 'Due Date' ? (
                              <>
                              <div>
                                <DatePicker
                                value={invoiceDate}
                                onChange={(value)=>setInvoiceDate(value)}
                                />
                              </div>
                              </>
                            ) : searchKey === 'Due Date' ? (
                                <>
                                <div>
                                  <DatePicker
                                  value={dueDate}
                                  onChange={setDueDate(value)}
                                  />
                                </div>
                                </>
                          ) : (
                            <input
                              type="text"
                              value={value}
                              onChange={(e) => {
                                const updatedValue = e.target.value;
                                const updatedMatches = { ...matches }; // Create a copy of matches
                                updatedMatches[searchKey].value = updatedValue; // Update the value for the current key
                                // Update the state with the new matches
                                // Assuming you have a function to update state named `setMatches`
                                setMatches(updatedMatches);
                              }}
                            />
                          )}
                        </td>
                      */}
                  </tr>
                ))}
                </>
            }
            <tr key="GLAccounts" className="border">
            <td className="px-4 py-2" >GL Accounts *</td>
            </tr>
            <tr key="GLAccountsSelection" className="border">
            <td className="px-4 py-2" >
            {/*
              <select
              value={selectedValue} onChange={handleSelectChange}
              className="bg-[#F2F4F7] border border-[#ccc]  text-sm rounded-[5px] text-[#666] outline-none py-1.5 px-8 appearance-none pr-8">
              <option>
              </option>
              {glAccounts && glAccounts.QueryResponse && glAccounts.QueryResponse.Account.map((option) => {
                return (
                  <option
                    key={option.Name}
                    value={option.Name}
                    className="pl-2 selectOptions"
                  >
                    {option.Name}
                  </option>
                )
              })}
              </select>
            */}
            <Select
              value={selectedValue}
              onChange={handleSelectChange}
              options={gloptions}
            />
            </td>
            <td className="subtext pl-2" >
            Type: {selectedAccount && selectedAccount.AccountType}
            </td>
            </tr>

            </tbody>
          </table>

          <table className="table-auto w-full border-collapse">
            <thead>
              <tr>
              </tr>
            </thead>

            <tbody>
            <tr key="GLAccounts" className="border">
            {additional
            ?
            <td className="px-4 py-2" onClick={showAdditional}>Hide Additional Data</td>
            :
            <td className="px-4 py-2" onClick={showAdditional}>View Additional Data</td>
            }
            </tr>
            {additional &&
              <>
              {
                ocrData && ocrData.final_map &&
                <>
                  {Object.entries(ocrData.final_map).map(([key, value]) => (
                    <tr className="border">
                    <td className="px-4 py-2" onClick={() => selectSection(key, value)}>{key}</td>
                      <td className="px-4 py-2" onClick={() => selectSection(key, value)}>{value}</td>
                    </tr>
                  ))}
                  </>
              }
              </>
            }

            </tbody>
            </table>

{/*
  <table className="table-auto w-full border-collapse">
    <thead>
      <tr>
        <th className="px-4 py-2 bg-gray-100 border">Key</th>
        <th className="px-4 py-2 bg-gray-100 border">Value</th>
      </tr>
    </thead>
    {console.log('ocrData ocrData ocrData ', ocrData)}
    {
      ocrData && ocrData.final_map &&
      <tbody>
        {Object.entries(ocrData.final_map).map(([key, value]) => (
          <tr key={key} className="border">
            <td className="px-4 py-2" onClick={() => selectSection(key, value)}>{key}</td>
            <td className="px-4 py-2" onClick={() => selectSection(key, value)}>{value}</td>
          </tr>
        ))}
      </tbody>
    }

  </table>
  */}


            {/*
              <div className="px-[30px] py-[20px]">
              <p className="text-base font-normal text-black">
                Document Details
              </p>
            </div>
            <hr className="border-b border-b-[#ccc] mb-[20px]" />
            <div className="pl-[30px] pr-[140px] pb-[30px]">
              <div className="flex items-center  justify-between pb-[20px]">
                <div className="flex items-center">
                  <img src={SucessImage} alt="Sucess" className="pr-[16px]" />
                  <p className="text-black text-sm font-medium">Vendor</p>
                </div>
                <p className="text-[#666] text-sm font-medium">TOMO </p>
              </div>
              <div className="flex items-center  justify-between pb-[20px]">
                <div className="flex items-center">
                  <img src={SucessImage} alt="Sucess" className="pr-[16px]" />
                  <p className="text-black text-sm font-medium">Invoice Date</p>
                </div>
                <p className="text-[#666] text-sm font-medium">06/01/2024 </p>
              </div>
              <div className="flex items-center  justify-between pb-[20px]">
                <div className="flex items-center">
                  <img src={SucessImage} alt="Sucess" className="pr-[16px]" />
                  <p className="text-black text-sm font-medium">
                    Invoice Number
                  </p>
                </div>
                <p className="text-[#666] text-sm font-medium">INV - 0005</p>
              </div>
              <div className="flex items-center  justify-between pb-[20px]">
                <div className="flex items-center">
                  <img src={SucessImage} alt="Sucess" className="pr-[16px]" />
                  <p className="text-black text-sm font-medium">Total</p>
                </div>
                <p className="text-[#666] text-sm font-medium">$5500.00</p>
              </div>
              <div className="flex items-center  justify-between pb-[20px]">
                <div className="flex items-center">
                  <img src={SucessImage} alt="Sucess" className="pr-[16px]" />
                  <p className="text-black text-sm font-medium">Due Date</p>
                </div>
                <p className="text-[#666] text-sm font-medium">06/12/2024</p>
              </div>
              <div className="flex items-center  justify-between pb-[20px]">
                <div className="flex items-center">
                  <img src={SucessImage} alt="Sucess" className="pr-[16px]" />
                  <p className="text-black text-sm font-medium">
                    Purchase Order
                  </p>
                </div>
                <p className="text-[#666] text-sm font-medium">-</p>
              </div>
              <div className="flex items-center  justify-between pb-[20px]">
                <div className="flex items-center">
                  <img src={SucessImage} alt="Sucess" className="pr-[16px]" />
                  <p className="text-black text-sm font-medium">Tax</p>
                </div>
                <p className="text-[#666] text-sm font-medium">-</p>
              </div>
              <div className="flex items-center  justify-between pb-[20px]">
                <div className="flex items-center">
                  <img src={SucessImage} alt="Sucess" className="pr-[16px]" />
                  <p className="text-black text-sm font-medium">
                    Payment Terms
                  </p>
                </div>
                <p className="text-[#666] text-sm font-medium">-</p>
              </div>
              <div className="flex items-center  justify-between pb-[20px]">
                <div className="flex items-center">
                  <img src={SucessImage} alt="Sucess" className="pr-[16px]" />
                  <p className="text-black text-sm font-medium">Freight</p>
                </div>
                <p className="text-[#666] text-sm font-medium">-</p>
              </div>
              <div className="flex items-center  justify-between ">
                <div className="flex items-center">
                  <img src={SucessImage} alt="Sucess" className="pr-[16px]" />
                  <p className="text-black text-sm font-medium">
                    Document Type
                  </p>
                </div>
                <select className="text-[#666] text-sm font-medium outline-none bg-[#F9F9F9]">
                  <option>Invoice</option>
                  <option>1</option>
                  <option>2</option>
                </select>
              </div>
            </div>
            <hr className="border-b border-b-[#ccc] mb-[20px]" />
            <div className="pl-[30px] pr-[140px] pb-[20px] flex items-center  justify-between ">
              <p className="text-black text-sm font-medium">Account Details</p>
              <p className="text-[#666] text-sm font-medium"></p>
            </div>
            <hr className="border-b border-b-[#ccc] mb-[20px]" />
            <div className="pl-[30px] pr-[140px] pb-[20px]">
              <div className="flex items-center  justify-between pb-[20px]">
                <div className="flex items-center">
                  <img src={SucessImage} alt="Sucess" className="pr-[16px]" />
                  <p className="text-black text-sm font-medium">GL Account</p>
                </div>
              </div>
              <div className="flex items-center  justify-between pb-[20px]">
                <div className="flex items-center">
                  <img src={SucessImage} alt="Sucess" className="pr-[16px]" />
                  <p className="text-black text-sm font-medium">Job ID</p>
                </div>
              </div>
              <div className="flex items-center  justify-between pb-[20px]">
                <div className="flex items-center">
                  <img src={SucessImage} alt="Sucess" className="pr-[16px]" />
                  <p className="text-black text-sm font-medium">Cost ID</p>
                </div>
              </div>
              <div className="flex items-center  justify-between ">
                <div className="flex items-center">
                  <img src={SucessImage} alt="Sucess" className="pr-[16px]" />
                  <p className="text-black text-sm font-medium">Cost Type</p>
                </div>
              </div>
            </div>
            <hr className="border-b border-b-[#ccc] mb-[20px]" />
            <div class="flex items-center pl-[30px] pb-[20px]">
              <input
                id="default-checkbox"
                type="checkbox"
                value=""
                class="w-[18px] h-[18px] rounded text-blue-600 bg-white border-[#ccc]"
              />
              <label
                for="default-checkbox"
                class="ms-2 text-[14px] font-medium text-black"
              >
                Verify All
              </label>
            </div>
            */}
            {/* button */}
            <div>
            {/*
              <Link to="/approved-document">
              </Link>
               */}
               {
                 !loading
                 ?
                 <button onClick={updateDetails} className="bg-[#052460] border border-[#052460] text-center py-5 flex justify-center w-full rounded-b-[10px] text-base text-white font-medium">
                   Verify
                 </button>
                 :
                 <div className="bg-[#052460] border border-[#052460] text-center py-5 flex justify-center w-full rounded-b-[10px] text-base text-white font-medium flex items-center justify-center">
                   <div role="status">
                     <svg
                       aria-hidden="true"
                       class="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                       viewBox="0 0 100 101"
                       fill="none"
                       xmlns="http://www.w3.org/2000/svg"
                     >
                       <path
                         d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                         fill="currentColor"
                       />
                       <path
                         d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                         fill="currentFill"
                       />
                     </svg>
                     <span class="sr-only">Loading...</span>
                   </div>
                 </div>
               }
               <button onClick={approveDocument} className=" mt-[50px] bg-[#052460] border border-[#052460] text-center py-5 flex justify-center w-full rounded-b-[10px] text-base text-white font-medium">
                 Approve
               </button>
            </div>
          </div>
          {/* image-div */}
          <div className="pl-[30px]">
          <HighlightedImage imgSrc={singledocument.url} ocrData={clickedData} className="h-[864px]" />
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = ({ Settings, Auth }) => {
  let { } = Settings
  return {
  }
}

const mapDispatchToProps = {
  overlayLoader
}
export default connect(mapStateToProps, mapDispatchToProps)(PendingDocument)
