import React, { useState } from "react";
import LoginLogo from "../assets/images/login/Group.svg";
import axios from "axios";
import EventBus from "eventing-bus";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

const SignUp = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    name: "",
    lastName: "",
    role: "Admin",
    address: "Docusynth Company",
  });

  const createUser = () => {
    try {
      axios
        .post(`/api/user/createUser`, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            mode: "no-cors",
          },
          email: formData.email,
          password: formData.password,
          firstName: formData.name,
          lastName: " ",
          role: formData.role,
          address: formData.address,
        })
        .then(function (response) {
          localStorage.setItem("token", response.data.body.token);
          axios.defaults.headers.common["Authorization"] =
            response.data.body.token;
          console.log("response api", response);
          navigate("/");
        })
        .catch(function (error) {
          console.error("Error:", error);
          EventBus.publish("error", error.response.data.message);
        });
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    console.log(name, value);
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    console.log(`${name}: ${value}`);
  };

  return (
    <div className="flex  ">
      <div className="login-bg w-[45%]">
        <div className="h-[100vh] w-full flex justify-center items-center">
          <div>
            <div className="flex justify-center">
              <img src={LoginLogo} alt="LoginLogo" />
            </div>
            <div className="flex items-center justify-center pt-[50px]">
              <p className="text-white font-[400] text-[40px] md:text-[44px]  xl:text-[48.629px] cursor-pointer">
                DocuSynth Ai
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="flex  w-[55%] justify-center ">
        <div className="pt-[80px]">
          <div className="pt-[70px] ">
            <div className="flex justify-center">
              <p className="text-black font-[600] text-[44px] md:text-[48px] xl:text-[52px] text-center">
                Welcome To DocuSynth
              </p>
            </div>
            <div className="flex justify-center pt-[18px]">
              <p className="text-[#666] font-[400] text-[18px]  text-center w-[376px]">
                Revolutionizing Construction Companies with AI-powered OCR
              </p>
            </div>
          </div>
          <div className="pt-[30px] ">
            <div
              className="rounded-[20px] p-[30px]"
              style={{
                background:
                  "linear-gradient(125deg, rgba(255, 255, 255, 0.40) 15.83%, rgba(255, 255, 255, 0.55) 100%)",
                backdropFilter: "blur(5px)",
              }}
            >
              <div class="mb-[24px]">
                <input
                  type="text"
                  class="w-full py-[20px] pl-[20px] rounded-[10px] outline-none text-[#52525B] text-sm font-normal border border-[#CCC]"
                  placeholder="Name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  style={{
                    background:
                      "linear-gradient(125deg, rgba(255, 255, 255, 0.70) 15.83%, rgba(255, 255, 255, 0.85) 100%)",
                    backdropFilter: "blur(17.5px)",
                  }}
                />
              </div>
              <div class="mb-[14px]">
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  class="w-full py-[20px] pl-[20px] rounded-[10px] outline-none text-[#52525B] text-sm font-normal border border-[#CCC]"
                  placeholder="Email"
                  style={{
                    background:
                      "linear-gradient(125deg, rgba(255, 255, 255, 0.70) 15.83%, rgba(255, 255, 255, 0.85) 100%)",
                    backdropFilter: "blur(17.5px)",
                  }}
                />
              </div>
              <div class="mb-[14px]">
                <input
                  type="password"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  class="w-full py-[20px] pl-[20px] rounded-[10px] outline-none text-[#52525B] text-sm font-normal border border-[#CCC]"
                  placeholder="Password"
                  style={{
                    background:
                      "linear-gradient(125deg, rgba(255, 255, 255, 0.70) 15.83%, rgba(255, 255, 255, 0.85) 100%)",
                    backdropFilter: "blur(17.5px)",
                  }}
                />
              </div>
              <div class="mb-[14px]">
                <input
                  type="password"
                  class="w-full py-[20px] pl-[20px] rounded-[10px] outline-none text-[#52525B] text-sm font-normal border border-[#CCC]"
                  placeholder="Confirm Password"
                  style={{
                    background:
                      "linear-gradient(125deg, rgba(255, 255, 255, 0.70) 15.83%, rgba(255, 255, 255, 0.85) 100%)",
                    backdropFilter: "blur(17.5px)",
                  }}
                />
              </div>
              <div className="py-[24px]">
                <p className="text-[#004862] font-[400] text-[18px]  text-end">
                  Forgot Password?
                </p>
              </div>
              <div
                onClick={createUser}
                className="border border-[#004862] cursor-pointer rounded-[5px] bg-[#004862] text-center"
              >
                <button className="  py-[18px] text-white text-[16px] font-[600]">
                  Sign Up
                </button>
              </div>
              <div className="pt-[24px]">
                <p className=" text-[#222] font-normal text-[14px]  text-center">
                  Already have an account?{" "}
                  <Link to="/log-in">
                    <span className="text-[#004862] font-semibold">Log in</span>
                  </Link>
                </p>
              </div>

              <div className="pt-[22px] flex  justify-center">
                <input type="checkbox" className="accent-[#00D588] " />
                <label className=" text-[#666] font-[400] text-[14px]  text-center pl-[22px]">
                  I agree to the{" "}
                  <span className="text-[#004862] font-[400] underline">
                    Terms of Use
                  </span>{" "}
                  and {""}
                  <span className="text-[#004862] font-[400] underline">
                    Privacy Policy
                  </span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
