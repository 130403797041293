import React, { useState, useEffect } from "react";
import axios from "axios";
import EventBus from "eventing-bus"

const Users = () => {
  const [users, setUsers] = useState(null);
  const [isShow, setShow] = useState(true);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    name: "",
    role: "",
  });

  useEffect(() => {
    getAllUsers();
  }, []);

  const getAllUsers = () => {
    axios
      .get("/api/user/getAllUsers", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          mode: "no-cors",
          Authorization: `${localStorage.getItem("token")}`,
        },
      })
      .then(function (response) {
        setUsers(response.data.body);
        console.log("response api getAllUsers", response.data.body);
      })
      .catch(function (error) {
        console.error("Error:", error);
      });
  };

  const addUser = () => {
  try{
    const apiUrl = `/api/user/addUser`
    const requestData = {
            email:formData.email,
            password:formData.password,
            name:formData.name,
            role:formData.role,
    };
    const headers = {
      'Authorization': localStorage.getItem('token'),
      'Content-Type': 'application/json' // Assuming you're sending JSON data
    };
    axios.post(apiUrl, requestData, { headers })
      .then(function (response) {
        console.log('response api', response);
        getAllUsers()
        setShow(true)
        EventBus.publish("success", response.data.message)
      })
      .catch(function (error) {
        EventBus.publish("error", error.response.data.message)
        console.error('Error:', error.response.data.message);
      });
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    console.log(name, value);
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    console.log(`${name}: ${value}`);
  };

  const userData = [
    {
      id: 1,
      email: "johnsmith@mail.com",
      userRole: "AP Person",
      signUp: "  Completed",
    },
    {
      id: 2,
      email: "johnsmith@mail.com",
      userRole: "AP Person",
      signUp: "  Completed",
    },
    {
      id: 3,
      email: "johnsmith@mail.com",
      userRole: "AP Person",
      signUp: "  Completed",
    },
    {
      id: 4,
      email: "johnsmith@mail.com",
      userRole: "AP Person",
      signUp: "  Completed",
    },
    {
      id: 5,
      email: "johnsmith@mail.com",
      userRole: "AP Person",
      signUp: "  Completed",
    },
  ];
  return (
    <div>
      <div className="container-class">
        <div className="flex items-center justify-between  pt-[50px] pb-[20px]">
          <div>
            <p className="text-[#004761] font-semibold text-base">Users</p>
          </div>
          {/* buttons */}
          <div className="border border-[#004761] bg-[#004761] rounded-[5px] mx-2.5">
            <button onClick={()=>setShow(false)} className="py-[12px] px-12 text-white text-xs font-normal">
              Add User
            </button>
          </div>
        </div>
        <div className="border-b border-b-[#ccc] w-full">
          <hr className="border  border-[#004761] w-[100px]" />
        </div>

        {isShow ? (
          <div class="relative overflow-x-auto mt-[30px] sm:rounded-lg">
            <table class="w-full text-sm text-left rtl:text-right ">
              <thead class="text-sm text-black font-medium border-b border-b-[#ccc] pb-[18px]">
                <tr>
                  <th scope="col" class="px-6 py-3">
                    Email
                  </th>
                  <th scope="col" class="px-6 py-3">
                    User Role
                  </th>
                  <th scope="col" class="px-6 py-3">
                    Sign Up
                  </th>
                  <th scope="col" class="px-6 py-3">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {users?.map((item, index) => (
                  <tr key={index} class="bg-white border-b border-b-[#ccc]">
                    <td class="px-6 py-[26px] font-normal text-xs text-[#666]  whitespace-nowrap ">
                      {item?.email}
                    </td>
                    <td class="px-6  py-[26px] font-normal text-xs text-[#666]  whitespace-nowrap ">
                      {item?.role}
                    </td>
                    <td class="px-6  py-[26px] font-normal text-xs text-[#666]  whitespace-nowrap ">
                      {item?.stage}
                    </td>
                    <td class="px-6  py-[26px] font-normal text-xs text-[#666]  whitespace-nowrap ">
                      <button className="bg-[#EF5350] py-[6px] px-5 border border-[#Ef5350] rounded-[50px] text-xs text-white font-medium">
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <div className="flex w-[100%] justify-center ">
            <div className="pt-[24px] w-full ">
              <div
                className="w-full rounded-[20px] py-[30px]"
                style={{
                  background:
                    "linear-gradient(125deg, rgba(255, 255, 255, 0.40) 15.83%, rgba(255, 255, 255, 0.55) 100%)",
                  backdropFilter: "blur(5px)",
                }}
              >
                <div className="w-full mb-[24px] flex items-center  justify-between">
                  <div className="w-[48%] ">
                    <input
                      type="text"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      class="w-full py-[20px] pl-[20px] rounded-[10px] outline-none text-[#52525B] text-sm font-normal border border-[#CCC]"
                      placeholder="Email Address"
                      style={{
                        background:
                          "linear-gradient(125deg, rgba(255, 255, 255, 0.70) 15.83%, rgba(255, 255, 255, 0.85) 100%)",
                        backdropFilter: "blur(17.5px)",
                      }}
                    />
                  </div>
                  <div className="w-[48%]">
                    <input
                      type="password"
                      name="password"
                      value={formData.password}
                      onChange={handleChange}
                      class="w-full py-[20px] pl-[20px] rounded-[10px] outline-none text-[#52525B] text-sm font-normal border border-[#CCC]"
                      placeholder="Password"
                      style={{
                        background:
                          "linear-gradient(125deg, rgba(255, 255, 255, 0.70) 15.83%, rgba(255, 255, 255, 0.85) 100%)",
                        backdropFilter: "blur(17.5px)",
                      }}
                    />
                  </div>
                </div>
                <div className="w-full mb-[24px] flex items-center  justify-between">
                  <div className="w-[48%]">
                    <input
                      type="text"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      class="w-full py-[20px] pl-[20px] rounded-[10px] outline-none text-[#52525B] text-sm font-normal border border-[#CCC]"
                      placeholder="Name"
                      style={{
                        background:
                          "linear-gradient(125deg, rgba(255, 255, 255, 0.70) 15.83%, rgba(255, 255, 255, 0.85) 100%)",
                        backdropFilter: "blur(17.5px)",
                      }}
                    />
                  </div>
                  <div className="w-[48%]">
                    <input
                      type="text"
                      name="role"
                      value={formData.role}
                      onChange={handleChange}
                      class="w-full py-[20px] pl-[20px] rounded-[10px] outline-none text-[#52525B] text-sm font-normal border border-[#CCC]"
                      placeholder="role"
                      style={{
                        background:
                          "linear-gradient(125deg, rgba(255, 255, 255, 0.70) 15.83%, rgba(255, 255, 255, 0.85) 100%)",
                        backdropFilter: "blur(17.5px)",
                      }}
                    />
                  </div>
                </div>
                <div className="w-full flex  items-center justify-end gap-3 ">
                  <button onClick={()=>setShow(true)} className="w-[18%] border border-[#004761] bg-[#004761] rounded-[5px] py-[12px] px-12 text-white text-xs font-normal">
                    Cancel
                  </button>
                  <button onClick={addUser} className="w-[18%] border border-[#004761] bg-[#004761] rounded-[5px]  py-[12px] px-12 text-white text-xs font-normal">
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Users;
