import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { overlayLoader } from "../../store/actions/Settings";
import VendorImage from "../../assets/images/vendor/images.jpeg";
import VendorImage1 from "../../assets/images/vendor/images.jpeg";
import { useParams } from 'react-router-dom';
import axios from 'axios';

const SingleVendor = (props) => {
  const [editMode, setEditMode] = useState(false);
  const [singleVendor, setSingleVendor] = useState({});
  const { id } = useParams();

  useEffect(() => {
    fetchSingleVendor();
  }, []);

  const fetchSingleVendor = () => {
    props.overlayLoader(true, "Fetching Vendor Details.");
    axios.get(`/api/user/getSingleVendor?vendorId=${id}`, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Authorization": localStorage.getItem("token")
      }
    }).then(response => {
      setSingleVendor(response.data.body);
      props.overlayLoader(false);
    }).catch(error => {
      console.error('Error:', error);
      props.overlayLoader(false);
    });
  };

  const handleEdit = () => {
    setEditMode(true);
  };

  const handleSave = () => {
    setEditMode(false);
    console.log('singleVendor', singleVendor);
    // props.overlayLoader(true, "Saving Vendor Details...");
    // axios.post(`/api/user/updateVendorDetails`, singleVendor, {
    //   headers: { "Authorization": localStorage.getItem("token") }
    // }).then(response => {
    //   props.overlayLoader(false);
    //   console.log("Save successful");
    // }).catch(error => {
    //   props.overlayLoader(false);
    //   console.error("Save failed:", error);
    // });
  };

  const handleChange = (field, value) => {
    setSingleVendor(prev => ({ ...prev, [field]: value }));
  };

  return (
    <div className="container-class">
      <div className="flex items-center justify-between pt-[50px] pb-[20px]">
        <p className="text-[#004761] font-semibold text-base">Vendor Details</p>
        <button onClick={handleSave} className="bg-[#004761] text-white py-[12px] px-12 rounded-[5px] text-xs">Save</button>
      </div>
      <div className="flex items-center justify-end pt-[35px] pb-[18px]">
        <button onClick={handleSave} className="bg-[#81C784] text-white py-[6px] px-5 rounded-[5px] text-xs">Save</button>
        <button onClick={handleEdit} className="bg-white text-[#EF5350] border py-[6px] px-5 rounded-[5px] text-xs">Edit</button>
      </div>
      <div className="bg-white shadow-lg rounded-lg max-w-full">
        <table className="min-w-full divide-y divide-gray-200">
          <tbody className="divide-y divide-gray-200 bg-white">
            <tr>
              <td className="px-6 py-4 whitespace-nowrap font-medium text-gray-900">ID</td>
              <td className="px-6 py-4 whitespace-nowrap text-gray-700">
              {singleVendor.Id}
              </td>
            </tr>
            <tr>
              <td className="px-6 py-4 whitespace-nowrap font-medium text-gray-900">Name</td>
              <td className="px-6 py-4 whitespace-nowrap text-gray-700">
                {editMode ? (
                  <input type="text" value={singleVendor.DisplayName || ''} onChange={(e) => handleChange('DisplayName', e.target.value)} className="form-input rounded-none shadow-sm" />
                ) : (
                  singleVendor.DisplayName
                )}
              </td>
            </tr>
            <tr>
              <td className="px-6 py-4 whitespace-nowrap font-medium text-gray-900">Number</td>
              <td className="px-6 py-4 whitespace-nowrap text-gray-700">
                {editMode ? (
                  <input type="text" value={singleVendor.email || ''} onChange={(e) => handleChange('number', e.target.value)} className="form-input rounded-none shadow-sm" />
                ) : (
                  singleVendor.number
                )}
              </td>
            </tr>

            <tr>
              <td className="px-6 py-4 whitespace-nowrap font-medium text-gray-900">Email</td>
              <td className="px-6 py-4 whitespace-nowrap text-gray-700">
                {editMode ? (
                  <input type="text" value={singleVendor.email || ''} onChange={(e) => handleChange('email', e.target.value)} className="form-input rounded-none shadow-sm" />
                ) : (
                  singleVendor.email
                )}
              </td>
            </tr>

            <tr>
              <td className="px-6 py-4 whitespace-nowrap font-medium text-gray-900">Lein Waiver Required</td>
              <td className="px-6 py-4 whitespace-nowrap text-gray-700">
                <input
                  id="leinWaiverRequired-checkbox"
                  type="checkbox"
                  checked={singleVendor.leinWaiverRequired || false}
                  onChange={(e) => handleChange('leinWaiverRequired', e.target.checked)}
                  className="w-[18px] h-[18px] rounded text-blue-600 bg-white border-[#ccc]"
                />
              </td>
            </tr>

            <tr>
              <td className="px-6 py-4 whitespace-nowrap font-medium text-gray-900">Lein Waiver Uploaded</td>
              <td className="px-6 py-4 whitespace-nowrap text-gray-700">
                <input
                  id="leinWaiverUploaded-checkbox"
                  type="checkbox"
                  checked={singleVendor.leinWaiverUploaded || false}
                  onChange={(e) => handleChange('leinWaiverUploaded', e.target.checked)}
                  className="w-[18px] h-[18px] rounded text-blue-600 bg-white border-[#ccc]"
                />
              </td>
            </tr>

            <tr>
              <td className="px-6 py-4 whitespace-nowrap font-medium text-gray-900">COI Required</td>
              <td className="px-6 py-4 whitespace-nowrap text-gray-700">
                <input
                  id="coiRequired-checkbox"
                  type="checkbox"
                  checked={singleVendor.coiRequired || false}
                  onChange={(e) => handleChange('coiRequired', e.target.checked)}
                  className="w-[18px] h-[18px] rounded text-blue-600 bg-white border-[#ccc]"
                />
              </td>
            </tr>

            <tr>
              <td className="px-6 py-4 whitespace-nowrap font-medium text-gray-900">COI Uploaded</td>
              <td className="px-6 py-4 whitespace-nowrap text-gray-700">
                <input
                  id="coiUploaded-checkbox"
                  type="checkbox"
                  checked={singleVendor.coiUploaded || false}
                  onChange={(e) => handleChange('coiUploaded', e.target.checked)}
                  className="w-[18px] h-[18px] rounded text-blue-600 bg-white border-[#ccc]"
                />
              </td>
            </tr>
            {/* Add similar entries for each field as needed */}
          </tbody>
        </table>
      </div>
      <div className="pt-[40px] flex justify-between items-center">
        <img className="w-[30%]" src={VendorImage} alt="VendorImage" />
        <img className="w-[30%]" src={VendorImage1} alt="VendorImage1" />
      </div>
    </div>
  );
};

const mapStateToProps = ({ Settings, Auth }) => {
  // Add relevant state mappings
};

const mapDispatchToProps = {
  overlayLoader,
};

export default connect(mapStateToProps, mapDispatchToProps)(SingleVendor);
