import React from 'react'
import Header from "../utils/header"
import SingleVendor from '../components/Vendors/SingleVendor'
const Vendors = () => {
  return (
    <div>
        <Header/>
        <SingleVendor/>
    </div>
  )
}

export default Vendors
