import React from "react";
import Header from "../utils/header";
import Tabs from "../components/documents/tabs";

const Documents = () => {
  return (
    <div>
      <Header />
      <Tabs/>
    </div>
  );
};

export default Documents;
