import React from "react";
import integrationImage from "../../../assets/images/integrations/image 3.svg";
const ManualSyncing = () => {
  return (
    <div className="flex items-start justify-between flex-col xl:flex-row gap-4">
      <div className="border border-[#CCC] rounded-[5px] w-full xl:w-[24%] h-[466px]">
        <div className="border-b border-b-[#CCC]">
          <div className="pt-[30px] pl-[30px] pb-[24px]">
            <p className="text-[#004761] font-[500] text-[14px]">VENDORS</p>
          </div>
        </div>
        <div className="pt-[30px] pl-[24px]">
        <p className="text-[#999] text-sm font-normal">No Record</p>
        </div>
      </div>
      <div className="border border-[#CCC] rounded-[5px] w-full xl:w-[24%] h-[466px]">
        <div className="border-b border-b-[#CCC]">
          <div className="pt-[30px] pl-[30px] pb-[24px]">
            <p className="text-[#004761] font-[500] text-[14px]">GL ACCOUNTS</p>
          </div>
        </div>
        <div className="pt-[30px] pl-[24px]">
        <p className="text-[#999] text-sm font-normal">No Record</p>
        </div>
      </div>
      <div className="border border-[#CCC] rounded-[5px] w-full xl:w-[24%] h-[466px]">
        <div className="border-b border-b-[#CCC]">
          <div className="pt-[30px] pl-[30px] pb-[24px]">
            <p className="text-[#004761] font-[500] text-[14px]">
              PURCHASE ORDERS
            </p>
          </div>
        </div>
        <div className="pt-[30px] pl-[24px]">
          <p className="text-[#999] text-sm font-normal">No Record</p>
        </div>
      </div>
      <div className="bg-[#0524600d] px-7 rounded-[5px] w-full xl:w-[24%] h-[430px]">
        <div
          className=" bg-[#0524600d]  mt-7 mb-[50px] py-[50px] px-[104px]"
          style={{ boxShadow: "4px 4px 15px 0px rgba(78, 78, 78, 0.15)" }}
        >
          <div className="flex items-center justify-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="81"
              height="68"
              viewBox="0 0 81 68"
              fill="none"
            >
              <path
                d="M3.94729 43.0271C4.86642 43.0271 5.77636 42.6165 6.37686 41.8322L7.7096 40.1012C9.70411 51.9335 17.9916 62.0562 29.7565 66.1402C33.3779 67.3963 37.0942 67.9999 40.7615 67.9999C51.7421 67.9999 62.309 62.5831 68.6357 53.0456C69.5702 51.6363 69.1841 49.7337 67.7748 48.7992C66.3593 47.8586 64.4659 48.2508 63.5254 49.6601C56.6349 60.0586 43.5741 64.4582 31.7694 60.3527C21.9745 56.9519 15.1423 48.4254 13.6839 38.5233L16.3065 40.5424C17.6515 41.5687 19.5725 41.3236 20.6019 39.9817C21.6344 38.6398 21.3863 36.7188 20.0443 35.6863C20.0443 35.6863 12.7985 30.1072 12.7801 30.0949C11.2574 28.9215 9.09136 28.2628 7.71266 30.0612L1.52385 38.0975C0.491355 39.4394 0.739521 41.3604 2.08145 42.3929C2.63599 42.8187 3.29471 43.0271 3.94729 43.0271Z"
                fill="#004761"
              />
              <path
                d="M13.659 20.1868C15.0652 21.1212 16.9678 20.7352 17.9084 19.3259C24.7988 8.93049 37.872 4.53091 49.6644 8.6333C59.4593 12.0341 66.2915 20.5606 67.7498 30.4627L65.1273 28.4436C63.7823 27.4142 61.8613 27.6593 60.8318 29.0043C59.7994 30.3462 60.0475 32.2672 61.3894 33.2997C61.3894 33.2997 68.6353 38.8758 68.6536 38.8911C70.2039 40.0829 72.321 40.7508 73.7211 38.9248L79.9099 30.8885C80.9424 29.5466 80.6943 27.6256 79.3523 26.5931C78.0104 25.5606 76.0864 25.8119 75.0569 27.1538L73.7242 28.8848C71.7297 17.0525 63.4422 6.92984 51.6773 2.84584C37.2163 -2.17262 21.2357 3.21043 12.7981 15.9373C11.8636 17.3497 12.2496 19.2493 13.659 20.1868Z"
                fill="#004761"
              />
            </svg>
          </div>
        </div>
        <div>
          <p className="text-[#004761] text-[20px] font-[500]">MANUAL SYNC</p>
          <p className="pt-[14px] text-[#999] text-[14px] font-normal w-[220px] leading-6">
            Manually upload your files to Docusynth
          </p>
        </div>
        <div className=" pt-[18px] ">
          <button className="bg-[#EF5350] w-full rounded-[5px] py-[14px] text-white text-[16px] font-medium  ">
            Disabled
          </button>
        </div>
      </div>
    </div>
  );
};

export default ManualSyncing;
