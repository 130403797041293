import React from "react";
import { LineChart } from "@mui/x-charts/LineChart";
import { PieChart } from "@mui/x-charts/PieChart";

const Charts = (props) => {
  console.log('Charts', props);
  const data = [
    { id: 1, value: props.graphData && props.graphData.statusPercentage && props.graphData.statusPercentage[0] && props.graphData.statusPercentage[0].percentage ? props.graphData.statusPercentage[0].percentage : 0, label: "Approved", color: "#BAEDBD" },
    { id: 2, value: props.graphData && props.graphData.statusPercentage && props.graphData.statusPercentage[1] && props.graphData.statusPercentage[1].percentage ? props.graphData.statusPercentage[1].percentage : 0, label: "Deleted", color: "#95A4FC" },
    { id: 3, value: props.graphData && props.graphData.statusPercentage && props.graphData.statusPercentage[2] && props.graphData.statusPercentage[2].percentage ? props.graphData.statusPercentage[2].percentage : 0, label: "Pending", color: "#B1E3FF" },
  ];
  const data1 = [
    { id: 1, value: props.graphData && props.graphData.totalBasedDocs[0] && props.graphData.totalBasedDocs[0].count, label: "0-1000", color: "#052460" },
    { id: 2, value: props.graphData && props.graphData.totalBasedDocs[1] && props.graphData.totalBasedDocs[1].count, label: "1000-2000", color: "#BAEDBD" },
    // { id: 3, value: props.graphData && props.graphData.totalBasedDocs[2] && props.graphData.totalBasedDocs[2].count, label: "2000-3000", color: "#95A4FC" },
    // { id: 4, value: props.graphData && props.graphData.totalBasedDocs[3] && props.graphData.totalBasedDocs[3].count, label: "3000-4000", color: "#B1E3FF" },
    // { id: 5, value: props.graphData && props.graphData.totalBasedDocs[4] && props.graphData.totalBasedDocs[4].count, label: "4000-5000", color: "#B1E3FF" },
  ];
  function useResponsiveWidth() {
    const screenWidth = window.innerWidth;
    if (screenWidth < 640) {
      return 420;
    } else if (screenWidth < 1024) {
      return 480;
    } else {
      return 650;
    }
  }

  function useResponsiveHeight() {
    const screenHeight = window.innerHeight;
    if (screenHeight < 640) {
      return 300;
    } else if (screenHeight < 1024) {
      return 300;     } else {
      return 350;
    }
  }
  return (
    <div className="h-[100vh]">
      <div className="container-class">
        {/* line-charts */}
        {/* <div className="flex items-center flex-col xl:flex-row justify-between mb-[30px] gap-4">
          <div className="bg-[#F7F9FB] p-[24px] w-full xl:w-[49%] rounded-2xl">
            <p className="text-[#1c1c1c] text-[14px] font-medium pb-4">
              Average approval time for each document
            </p>
            <LineChart
              xAxis={[{ data: [1, 2, 3, 5, 8, 10] }]}
              series={[
                {
                  data: [2, 5.5, 2, 8.5, 1.5, 5],
                  color: "#052460",
                },
              ]}
              width={650}
              height={350}
            />
          </div>
          <div className="bg-[#F7F9FB] p-[24px] w-full xl:w-[49%] rounded-2xl">
            <p className="text-[#1c1c1c] text-[14px] font-medium pb-4">
              Average changes per document{" "}
            </p>
            <LineChart
              xAxis={[{ data: [1, 2, 3, 5, 8, 10] }]}
              series={[
                {
                  data: [2, 5.5, 2, 8.5, 1.5, 5],
                  color: "#052460",
                },
              ]}
              width={650}
              height={350}
            />
          </div>
        </div> */}
        {/*
          <div className="flex items-center flex-col xl:flex-row justify-between mb-8 gap-4">
            <div className="bg-[#F7F9FB] p-6 w-full xl:w-[49%] rounded-2xl">
              <p className="text-[#1c1c1c] text-xs xl:text-sm font-medium pb-4">
                Average approval time for each document
              </p>
              <LineChart
                xAxis={[{ data: [1, 2, 3, 5, 8, 10] }]}
                series={[
                  {
                    data: [2, 5.5, 2, 8.5, 1.5, 5],
                    color: "#052460",
                  },
                ]}
                width={useResponsiveWidth()}
                height={useResponsiveHeight()}
              />
            </div>
            <div className="bg-[#F7F9FB] p-6 w-full xl:w-[49%] rounded-2xl">
              <p className="text-[#1c1c1c] text-xs xl:text-sm font-medium pb-4">
                Average changes per document
              </p>
              <LineChart
                xAxis={[{ data: [1, 2, 3, 5, 8, 10] }]}
                series={[
                  {
                    data: [2, 5.5, 2, 8.5, 1.5, 5],
                    color: "#052460",
                  },
                ]}
                width={useResponsiveWidth()}
                height={useResponsiveHeight()}
              />
            </div>
          </div>
        */}

        {/* pie-charts */}
        {/*
          <div className="flex items-center  flex-col xl:flex-row justify-between mb-[30px] gap-4">
            <div className="bg-[#F7F9FB] p-[24px] w-full xl:w-[49%] rounded-2xl">
              <p className="text-[#1c1c1c] text-[12px] xl:text-[14px] font-medium pb-4">
                Documents by Vendor
              </p>
              <div>
                <PieChart series={[{ data }]} height={300} />
              </div>

            </div>
            <div className="bg-[#F7F9FB] p-[24px] w-full xl:w-[49%] rounded-2xl">
              <p className="text-[#1c1c1c] text-[12px] xl:text-[14px] font-medium pb-4">
                Documents by Aging
              </p>
              <div>
                <PieChart series={[{ data }]} height={300} />
              </div>
            </div>
          </div>
          <div className="flex items-center flex-col xl:flex-row justify-between mb-[60px] gap-4 ">
            <div className="bg-[#F7F9FB] p-[24px] w-full xl:w-[49%] rounded-2xl">
              <p className="text-[#1c1c1c] text-[12px] xl:text-[14px] font-medium pb-4">
                Documents by Job ID
              </p>
              <div>
                <PieChart series={[{ data: data1 }]} height={300} />
              </div>
            </div>
            <div className="bg-[#F7F9FB] p-[24px] w-full xl:w-[49%] rounded-2xl">
              <p className="text-[#1c1c1c] text-[12px] xl:text-[14px] font-medium pb-4">
                Documents by Cost ID
              </p>
              <div>
                <PieChart series={[{ data: data1 }]} height={300} />
              </div>
            </div>
          </div>
          */}


      </div>
    </div>
  );
};

export default Charts;
