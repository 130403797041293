var initialState = {
}
const Quickbook = (state = initialState, { type, payload }) => {
  switch (type) {
    // case "GET_WIRES_RESPONSE":
    // return {
    //   ...state,
    //   wires: payload,
    // }
    default:
    return state
  }
}
export default Quickbook
