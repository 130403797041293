import React from "react";
import { Route, Routes } from "react-router-dom";
import DashBoard from "../container/DashBoard";
import Documents from "../container/Documents";
import PendingDocumentPage from "../container/PendingDocument";
import ApprovedDocumentPage from "../container/ApprovedDocument";
import IntegrationsPage from "../container/IntegrationsPage";
import UsersPage from "../container/Users";
import Policy from "../container/Policy";
import Vendors from "../container/Vendors";
import Integrations from "../container/Integrations";
import LoginPage from "../container/loginPage";
import Profile from "../container/profile";
import SignUp from "../utils/signUp";
import SignUpPage from "../container/SignUpPage";

const RoutesPages = () => {
  return (
    <Routes>
      <Route path="/" element={<DashBoard />} />
      <Route path="/documents" element={<Documents/>} />
      <Route path="/pending-document/:id" element={<PendingDocumentPage/>} />
      <Route path="/approved-document" element={<ApprovedDocumentPage/>} />
      <Route path="/integrations" element={<Integrations/>} />
      <Route path="/users" element={<UsersPage/>} />
      <Route path="/policy" element={<Policy/>} />
      <Route path="/vendor/:id" element={<Vendors/>} />
      <Route path="/integrations" element={<Integrations/>} />
      <Route path="/profile" element={<Profile/>} />
      <Route path="/log-in" element={<LoginPage/>} />
      <Route path="/sign-up" element={<SignUpPage/>} />
    </Routes>
  );
};

export default RoutesPages;
