import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import { render } from "react-dom"

import { Provider } from "react-redux"
import createSagaMiddleware from "redux-saga"
import { createStore, applyMiddleware } from "redux"
import rootSaga from "./store/sagas"
import rootReducer from "./store/reducers"
import { GoogleOAuthProvider } from '@react-oauth/google';

const sagaMiddleware = createSagaMiddleware()

const store = createStore(rootReducer, applyMiddleware(sagaMiddleware))

// const root = ReactDOM.createRoot(document.getElementById("root"));
sagaMiddleware.run(rootSaga)
render(
  <GoogleOAuthProvider clientId="67100697973-ckhr0terbs2dhkl7aetrveuf6fpvqbar.apps.googleusercontent.com">
  <Provider store={store}>
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
  </Provider>
  </GoogleOAuthProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
