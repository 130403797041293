import React from "react";

const Invoices = () => {
  return (
    <div className="pt-[34px]">
      <div className="relative overflow-x-auto  sm:rounded-lg">
        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase  dark:text-gray-400">
            <tr>
              <th
                scope="col"
                className="px-6 py-3 text-[#000] text-[14px] font-[500]"
              >
                Date
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-[#000] text-[14px] font-[500]"
              >
                Type
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-[#000] text-[14px] font-[500]"
              >
                Amount
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-[#000] text-[14px] font-[500]"
              >
                Status
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-[#000] text-[14px] font-[500]"
              >
                Invoice
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className="even:bg-white  odd:bg-[#00476105]  border rounded-[5px] ">
              <th
                scope="row"
                className="px-6 py-4 font-normal text-[#666] text-[12px] whitespace-nowrap "
              >
                2024-05-01
              </th>
              <td className="px-6 py-4 font-normal text-[#666] text-[12px]">
                Silver
              </td>
              <td className="px-6 py-4 font-normal text-[#666] text-[12px]">
                Laptop
              </td>
              <td className="px-6 py-4 font-normal text-[#01D387] text-[12px]">
                $2999
              </td>
              <td className="px-6 py-4">
                <a
                  href="#"
                  className="font-normal text-[#59A8D4] text-[12px] hover:underline"
                >
                  View Invoice
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Invoices;
