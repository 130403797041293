import React, { useState, useEffect } from 'react';
import { connect } from "react-redux"
import axios from 'axios';
import integrationImage from "../../../assets/images/integrations/image 3.svg";
import EventBus from "eventing-bus"
import { overlayLoader } from "../../../store/actions/Settings"
import { useNavigate } from "react-router-dom";

const MyIntegrations = (props) => {
  const navigate = useNavigate();
  const [glAccounts, setGlAccounts] = useState(null);
  const [vendors, setVendors] = useState(null);
  const [porders, setPOrders] = useState(null);
  const [quickbookStatus, setQuickbookStatus] = useState([]);

  useEffect(() => {
    getAllAccounts()
  }, []);

  const getAllAccounts = () => {
    console.log('getAllAccounts');
    props.overlayLoader({
      status: true,
      loadingMessage: "Fetching Details.",
    })
    console.log('getAllAccounts');
  try{
    // axios.get(`/connectbook?state=${id}`,{
    axios.get(`/api/user/getAllGLAccounts`,{
        headers: {
          'Access-Control-Allow-Origin': '*',
          mode: 'no-cors',
          Authorization: `${localStorage.getItem('token')}`,
        },
      })
      .then(function (response) {
        console.log('response api getAllAccounts', JSON.parse(response.data.body));
        setGlAccounts(JSON.parse(response.data.body))
        console.log('getAllAccounts');
        getAllVendors()
      })
      .catch(function (error) {
        getAllVendors()
        console.error('Error:', error);
        console.log(error);
      });
    } catch (error) {
      getAllVendors()
      console.error('Error:', error);
    }
  }

  const getAllVendors = () => {
  try{
    // axios.get(`/connectbook?state=${id}`,{
    axios.get(`/api/user/getAllVendors`,{
        headers: {
          'Access-Control-Allow-Origin': '*',
          mode: 'no-cors',
          Authorization: `${localStorage.getItem('token')}`,
        },
      })
      .then(function (response) {
        console.log('response api getAllVendors', JSON.parse(response.data.body));
        setVendors(JSON.parse(response.data.body))
        getAllPurchaseOrders()
      })
      .catch(function (error) {
        console.error('Error:', error);
        console.log(error);
        getAllPurchaseOrders()
      });
    } catch (error) {
      console.error('Error:', error);
      getAllPurchaseOrders()
    }
  }

  const getAllPurchaseOrders = () => {
  try{
    // axios.get(`/connectbook?state=${id}`,{
    axios.get(`/api/user/getAllPurchaseOrders`,{
        headers: {
          'Access-Control-Allow-Origin': '*',
          mode: 'no-cors',
          Authorization: `${localStorage.getItem('token')}`,
        },
      })
      .then(function (response) {
        console.log('response api getAllPurchaseOrders', JSON.parse(response.data.body));
        setPOrders(JSON.parse(response.data.body))
        getConnectedBooks()
      })
      .catch(function (error) {
        console.error('Error:', error);
        console.log(error);
        getConnectedBooks()
      });
    } catch (error) {
      console.error('Error:', error);
      getConnectedBooks()
    }
  }

  const getConnectedBooks = () => {
  console.log('getConnectedBooks');
  try{
    axios.get(`/api/user/getConnectedBooks`,{
        headers: {
          'Access-Control-Allow-Origin': '*',
          mode: 'no-cors',
          Authorization: `${localStorage.getItem('token')}`,
        },
      })
      .then(function (response) {
        console.log('response api getConnectedBooks', response);
        setQuickbookStatus(response.data.body[0])
        props.overlayLoader({
          status: false,
          loadingMessage: "",
        })
      })
      .catch(function (error) {
        console.error('Error:', error);
        console.log(error);
        props.overlayLoader({
          status: false,
          loadingMessage: "",
        })
      });
    } catch (error) {
      console.error('Error:', error);
      props.overlayLoader({
        status: false,
        loadingMessage: "",
      })
    }
  }

  const connectQuickbook = () => {
    const id = '661fa901b0afec460cb0ceae';
    try{
      const url = `https:/documentai-dev.akru.co/connectbook?state=${localStorage.getItem('userId')}`
      // const url = `/connectbook?state=${id}`
      window.open(`https://documentai-dev.akru.co/connectbook?state=${localStorage.getItem('userId')}`, "_blank")
      } catch (error) {
        console.error('Error:', error);
      }
  }

  const disconnectQuickbook = () => {
    try{
      axios.get(`/api/user/disconnectQuickbook`,{
          headers: {
            'Access-Control-Allow-Origin': '*',
            mode: 'no-cors',
            Authorization: `${localStorage.getItem('token')}`,
          },
        })
        .then(function (response) {
          console.log('response', response);
          EventBus.publish("success", "ERP Disconnected")
          getConnectedBooks()
        })
        .catch(function (error) {
          console.error('Error:', error);
          console.log(error);
        });
      } catch (error) {
        console.error('Error:', error);
      }
  }

  return (
    <div className="flex items-start flex-col xl:flex-row justify-between gap-4">
      <div className="border border-[#CCC] rounded-[5px] w-full xl:w-[24%] h-full lg:h-[466px]">
        <div className="border-b border-b-[#CCC]">
          <div className="pt-[30px] pl-[30px] pb-[24px]">
            <p className="text-[#004761] font-[500] text-[14px]">VENDORS</p>
          </div>
        </div>
        <div className="pt-[30px] pl-[24px]">
          <ol className="pt-[30px] pl-[24px] overflow-auto h-[336px]">
          {vendors && vendors.QueryResponse &&
            vendors.QueryResponse.Vendor.length > 0 &&
            vendors.QueryResponse.Vendor.map((item, index) => (
              <li onClick={()=>navigate("/vendor/" + item._id)} className="list-decimal text-[#666] text-sm font-normal pb-[24px] ">
                {item.DisplayName}
              </li>
            ))}
          </ol>
        </div>
      </div>
      <div className="border border-[#CCC] rounded-[5px] w-full xl:w-[24%] h-[466px]">
        <div className="border-b border-b-[#CCC]">
          <div className="pt-[30px] pl-[30px] pb-[24px]">
            <p className="text-[#004761] font-[500] text-[14px]">GL ACCOUNTS</p>
          </div>
        </div>
        <div className="pt-[30px] pl-[24px]">
          <ol className="pt-[30px] pl-[24px] overflow-auto h-[336px]">
          {glAccounts && glAccounts.QueryResponse &&
            glAccounts.QueryResponse.Account.length > 0 &&
            glAccounts.QueryResponse.Account.map((item, index) => (
              <li className="list-decimal text-[#666] text-sm font-normal pb-[24px] ">
                {item.Name}
              </li>
            ))}
          </ol>
        </div>
      </div>
      <div className="border border-[#CCC] rounded-[5px] w-full xl:w-[24%] h-[466px]">
        <div className="border-b border-b-[#CCC]">
          <div className="pt-[30px] pl-[30px] pb-[24px]">
            <p className="text-[#004761] font-[500] text-[14px]">
              PURCHASE ORDERS
            </p>
          </div>
        </div>
        <div className="pt-[30px] pl-[24px]">
          <ol className="pt-[30px] pl-[24px] overflow-auto h-[336px]">
          {porders && porders.QueryResponse &&
            porders.QueryResponse.PurchaseOrder.length > 0 &&
            porders.QueryResponse.PurchaseOrder.map((item, index) => (
              <li className="list-decimal text-[#666] text-sm font-normal pb-[24px] ">
                {item.DocNumber}
              </li>
            ))}
          </ol>
        </div>
      </div>
      <div className="bg-[#0524600d] px-7 rounded-[5px] w-full xl:w-[24%] h-[430px]">
        <div
          className=" bg-[#0524600d]  mt-7 mb-[50px] py-[50px] px-[104px]"
          style={{ boxShadow: "4px 4px 15px 0px rgba(78, 78, 78, 0.15)" }}
        >
          <div className="flex items-center justify-center">
            <img src={integrationImage} alt="integrationImage" />
          </div>
        </div>
        <div>
          <p className="text-[#004761] text-[20px] font-[500]">
            QUICKBOOKS ONLINE
          </p>
          <p className="pt-[14px] text-[#999] text-[14px] font-normal w-[220px] leading-6">
            Integrate your Docusynth with QuickBooks
          </p>
          {console.log('quickbookStatus', quickbookStatus)}
        </div>
        <div className=" pt-[18px] ">
        {
          quickbookStatus && !quickbookStatus.isExpired
          ?
          <button onClick={disconnectQuickbook} className="bg-[#EF5350] w-full rounded-[5px] py-[14px] text-white text-[16px] font-medium  ">
            Disconnect
          </button>
          :
          <button onClick={connectQuickbook} className="bg-[#81C784] w-full rounded-[5px] py-[14px] text-white text-[16px] font-medium  ">
            Connect
          </button>
        }
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ Settings, Auth }) => {
  let { } = Settings
  return {
  }
}

const mapDispatchToProps = {
  overlayLoader
}
export default connect(mapStateToProps, mapDispatchToProps)(MyIntegrations)
