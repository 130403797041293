import Auth from "./Auth"
import Quickbook from "./Quickbook"
import Settings from "./Settings"
import Document from "./Document"
import { combineReducers } from "redux"

export default combineReducers({
  Auth,
  Quickbook,
  Settings,
  Document,
})
