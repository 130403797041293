import authSagas from "./Auth"
import settingSagas from "./Settings"
import quickbookSagas from "./Quickbook"
import documentSagas from "./Document"
// import Sockets from "./Wallet.sockets"

import { all } from "redux-saga/effects"

export default function* rootSaga() {
  yield all([authSagas(), quickbookSagas(), settingSagas(), documentSagas()])
}
