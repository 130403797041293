import { setToken } from "../axios"
import { PURGE } from "redux-persist"


var initialState = {
  signUpUser: "",
  signData: "",
  // id: localStorage.getItem("id"),
  // auth: localStorage.getItem("token"),
  // email: localStorage.getItem("email"),
  // lastName: localStorage.getItem("lastName"),
  // firstName: localStorage.getItem("firstName"),
}

const Auth = (state = initialState, { type, payload }) => {
  switch (type) {
    case PURGE:
      return initialState

    case "LOGOUT":
      setToken()
      localStorage.clear()

    // case "SHOW_IFRAME":
    //   return {
    //     ...state,
    //     showIframe: payload,
    //   }

    // case "LOGIN_SUCCESS":
    //   updateID(payload["id"])
    //   localStorage.setItem("id", payload["id"])
    //   localStorage.setItem("email", payload["email"])
    //   localStorage.setItem("lastName", payload["lastName"])
    //   localStorage.setItem("firstName", payload["firstName"])
    //   setToken(payload["token"])
    //   localStorage.setItem("token", payload["token"])
    //   sessionStorage.setItem("token", payload["token"])
    //
    //   return {
    //     ...state,
    //     auth: payload["token"],
    //
    //     id: payload["id"],
    //     step: payload["step"],
    //     email: payload["email"],
    //     lastName: payload["lastName"],
    //     firstName: payload["firstName"],
    //   }

    default:
      return state
  }
}
export default Auth
