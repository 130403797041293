import React, { useState, useEffect } from "react";
import axios from "axios";
import ProfileImage from "../../assets/images/profile/Rectangle 50.png";

const ProfileDetails = () => {
  const [profile, setProfile] = useState(false);

  useEffect(() => {
    getProfile();
  }, []);

  const getProfile = () => {
    try {
      // axios.get(`/connectbook?state=${id}`,{
      axios
        .get(`/api/user/myProfile`, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            mode: "no-cors",
            Authorization: `${localStorage.getItem("token")}`,
          },
        })
        .then(function (response) {
          console.log("setProfile", response);
          setProfile(response.data.body);
        })
        .catch(function (error) {
          console.error("Error:", error);
          console.log(error);
        });
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div>
      <div>
        <div className="flex items-start justify-between pt-[34px]">
          <div className="bg-[#0524600d]  rounded-[5px] w-[25%] ">
            <div
              className="mx-7 my-[20px]"
              style={{
                boxShadow: "4px 4px 15px 0px rgba(78, 78, 78, 0.15)",
              }}
            >
              <div className="width-[290px] height-[200px]">
                <img
                  src={profile.avatar}
                  style={{ borderRadius: "50%" }}
                  className="h-full w-full"
                />
              </div>
            </div>
            <div className=" mx-7 ">
              <p className="text-[#004761] text-[20px] font-[500]">
                {profile.firstName}
              </p>
              <p className="text-[#999] text-[14px] font-normal ">Docusynth</p>
            </div>
            <div className="pt-[14px] pb-[24px] mx-7 ">
              <div className="flex items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="16"
                  viewBox="0 0 20 16"
                  fill="none"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M1.60628 0.920088L7.617 7.13515C8.92796 8.48749 11.0709 8.48865 12.383 7.13515L18.3937 0.920088C18.4542 0.857525 18.4454 0.754494 18.3752 0.703877C17.7621 0.261978 17.0162 0 16.2127 0H3.7873C2.98377 0 2.23786 0.262023 1.62479 0.703877C1.55457 0.754494 1.54575 0.857525 1.60628 0.920088ZM0 3.91431C0 3.26127 0.156656 2.64426 0.432597 2.10193C0.475748 2.01709 0.585124 1.99993 0.650698 2.06771L6.58533 8.20143C8.46464 10.1466 11.5342 10.1477 13.4147 8.20143L19.3493 2.06771C19.4149 1.99993 19.5243 2.01709 19.5674 2.10193C19.8433 2.64426 20 3.26132 20 3.91431V12.0856C20 14.2457 18.3 16 16.2127 16H3.7873C1.70005 16 0 14.2457 0 12.0856V3.91431Z"
                    fill="#004761"
                  />
                </svg>
                <p className="pl-[10px]  text-[#999] text-[14px] font-normal ">
                  {profile.email}
                </p>
              </div>
              <div className="flex items-center pt-[14px]">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="17"
                  viewBox="0 0 16 17"
                  fill="none"
                >
                  <path
                    d="M14.0695 11.8323C13.0425 10.9539 12.0002 10.4218 10.9859 11.2989L10.3802 11.8289C9.937 12.2137 9.11303 14.0116 5.92727 10.3468C2.74217 6.68674 4.63757 6.11686 5.0814 5.73539L5.69043 5.20465C6.69949 4.32562 6.31869 3.21903 5.59091 2.07993L5.15173 1.38997C4.42063 0.253524 3.62453 -0.492827 2.61281 0.384882L2.06614 0.862547C1.619 1.18829 0.369107 2.24711 0.0659227 4.25861C-0.29896 6.67215 0.85208 9.43597 3.48919 12.4685C6.12298 15.5023 8.7017 17.0262 11.1444 16.9997C13.1745 16.9778 14.4005 15.8884 14.784 15.4924L15.3326 15.014C16.3417 14.137 15.7148 13.244 14.6871 12.3637L14.0695 11.8323Z"
                    fill="#004761"
                  />
                </svg>
                <p className="pl-[10px]  text-[#999] text-[14px] font-normal "></p>
              </div>
            </div>
            <div className="border border-[#ccc]"></div>
            <div className=" py-[20px]  mx-7  ">
              <div className="flex items-center justify-between">
                <p className="text-[#222] text-[14px] font-[400]">
                  Total Documents Allowed
                </p>
                <p className="text-[#004761] text-[14px] font-normal ">3</p>
              </div>
              <div className="pt-[20px] flex items-center justify-between">
                <p className="text-[#222] text-[14px] font-[400]">
                  Total pages Allowed
                </p>
                <p className="text-[#004761] text-[14px] font-normal ">100</p>
              </div>
            </div>
          </div>
          <div className="w-[73%]">
            <div className="border border-[#CCC] rounded-[6px] w-full ">
              <div className="py-[30px] pl-[24px] pr-[50px]">
                <div className="flex items-center justify-between">
                  <div className="flex items-center justify-between w-[40%]">
                    <p className="text-[#004761] font-medium text-sm">
                      Enable Duplicate Bill No Check
                    </p>
                    <label class="inline-flex items-center cursor-not-allowed">
                      <input
                        type="checkbox"
                        value=""
                        className="sr-only peer"
                        disabled
                      />
                      <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-[#004761] rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[#004761]"></div>
                    </label>
                  </div>
                  <div className="flex items-center justify-between w-[40%]">
                    <p className="text-[#999] font-medium text-sm">
                      Enable G702 Upload
                    </p>
                    <label class="inline-flex items-center  cursor-not-allowed">
                      <input
                        type="checkbox"
                        value=""
                        className="sr-only peer"
                        disabled
                      />
                      <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-[#004761] rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[#004761]"></div>
                    </label>
                  </div>
                </div>
                <div className="pt-[34px] flex items-center justify-between">
                  <div className="flex items-center justify-between w-[40%]">
                    <p className="text-[#004761] font-medium text-sm">
                      Enable Doc Splitter
                    </p>
                    <label class="inline-flex items-center cursor-not-allowed">
                      <input
                        type="checkbox"
                        value=""
                        className="sr-only peer"
                        disabled
                      />
                      <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-[#004761] rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[#004761]"></div>
                    </label>
                  </div>
                  <div className="flex items-center justify-between w-[40%]">
                    <p className="text-[#999] font-medium text-sm">
                      Enable Phase, Cost Type, Job Id & Cost Id
                    </p>
                    <label class="inline-flex items-center cursor-not-allowed">
                      <input
                        type="checkbox"
                        value=""
                        className="sr-only peer"
                        disabled
                      />
                      <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-[#004761] rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[#004761]"></div>
                    </label>
                  </div>
                </div>
                <div className="pt-[34px] flex items-center justify-between">
                  <div className="flex items-center justify-between w-[40%]">
                    <p className="text-[#999] font-medium text-sm">
                      Enable Purchase Order Matching
                    </p>
                    <label class="inline-flex items-center cursor-not-allowed">
                      <input
                        type="checkbox"
                        value=""
                        className="sr-only peer"
                        disabled
                      />
                      <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-[#004761] rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[#004761]"></div>
                    </label>
                  </div>
                  <div className="flex items-center justify-between w-[40%]">
                    <p className="text-[#999] font-medium text-sm">
                      Allow Duplicate Detection on File Processing
                    </p>
                    <label class="inline-flex items-center cursor-not-allowed">
                      <input
                        type="checkbox"
                        value=""
                        className="sr-only peer"
                        disabled
                      />
                      <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-[#004761] rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[#004761]"></div>
                    </label>
                  </div>
                </div>
                <div className="py-[34px] flex items-center justify-between">
                  <div className="flex items-center justify-between w-[40%]">
                    <p className="text-[#999] font-medium text-sm">
                      Enable Receipt Upload
                    </p>
                    <label class="inline-flex items-center cursor-not-allowed">
                      <input
                        type="checkbox"
                        value=""
                        className="sr-only peer"
                        disabled
                      />
                      <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-[#004761] rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[#004761]"></div>
                    </label>
                  </div>
                  <div className="flex items-center justify-between w-[40%]">
                    <p className="text-[#999] font-medium text-sm">
                      Enable Quote and PO Upload
                    </p>
                    <label class="inline-flex items-center cursor-not-allowed">
                      <input
                        type="checkbox"
                        value=""
                        className="sr-only peer"
                        disabled
                      />
                      <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-[#004761] rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[#004761]"></div>
                    </label>
                  </div>
                </div>
              </div>
              <div className="border border-[#ccc] mb-[20px]"></div>
              <div className="pl-[30px] pb-[20px]">
                <p className="text-[14px] text-[400] text-[#999]">
                  Select ERP Option
                </p>
                <div>
                  <div className="pt-[14px]">
                    <form className="w-[400px] flex justify-between ">
                      <select class="text-[14px] text-[400] text-[#999] outline-none w-full border-b border-b-[#ccc] ">
                        <option>QuickBooks Online</option>
                      </select>
                    </form>
                  </div>
                  <p className="text-[14px] text-[400] text-[#999] pt-2.5">
                    Disconnect your previous integration to change ERP type.
                  </p>
                </div>
              </div>
            </div>
            <div className="flex justify-end py-[34px]">
              <button className="bg-[#004761] border border-[#004761] text-center py-[14px] flex justify-center w-[130px] rounded-[5px] text-base text-white font-medium">
                Save
              </button>
            </div>
            <div className="border border-[#CCC] rounded-[6px] w-full ">
              <div className="p-[30px]">
                <div className="pb-[32px]">
                  <p className="text-[#000] font-semibold text-[16px]">
                    Basic Details
                  </p>
                </div>
                <form className="flex items-center w-full justify-between">
                  <div className="w-[40%]">
                    <input
                      className="border border-[#CCC] w-full rounded-[5px] py-[16px] px-[30px]"
                      type="email"
                    />
                  </div>
                  <div className="w-[58%] flex gap-4">
                    <button className="bg-[#00476133] px-[24px] py-[14px] rounded-[5px] text-[#FFF] text-[16px] font-medium ">
                      Update Email
                    </button>
                    <button className="bg-white border border-[#004761] px-[24px] py-[14px] rounded-[5px] text-[#004761] text-[16px] font-medium ">
                      Cancel
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div className="border border-[#CCC] rounded-[6px] w-full mt-[30px] mb-[100px]">
              <div className="p-[30px]">
                <div className="pb-[32px]">
                  <p className="text-[#000] font-semibold text-[16px]">
                    Password
                  </p>
                </div>
                <form>
                  <div className="flex flex-col gap-3">
                    <div className="w-[60%]">
                      <input
                        className="border border-[#CCC] w-full rounded-[5px] py-[16px] px-[30px] placeholder:text-[#666] placeholder:text-[14px] placeholder:font-[500]"
                        type="password"
                        placeholder="Old Password*"
                      />
                    </div>
                    <div className="w-[60%]">
                      <input
                        className="border border-[#CCC] w-full rounded-[5px] py-[16px] px-[30px] placeholder:text-[#666] placeholder:text-[14px] placeholder:font-[500]"
                        type="password"
                        placeholder="New Password*"
                      />
                    </div>
                    <div className="w-[60%]">
                      <input
                        className="border border-[#CCC] w-full rounded-[5px] py-[16px] px-[30px] placeholder:text-[#666] placeholder:text-[14px] placeholder:font-[500]"
                        type="password"
                        placeholder="Conform Password*"
                      />
                    </div>
                  </div>

                  <div className="w-[58%] flex gap-4 pt-[20px]">
                    <button className="bg-[#00476133] px-[24px] py-[14px] rounded-[5px] text-[#FFF] text-[16px] font-medium ">
                      Change Password
                    </button>
                    <button className="bg-white border border-[#004761] px-[24px] py-[14px] rounded-[5px] text-[#004761] text-[16px] font-medium ">
                      Cancel
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileDetails;
