import React, { Component } from "react"
import { connect } from "react-redux"
import './loader.css';

var Spinner = require("react-spinkit")

class Loader extends Component {
  state = {}
  render() {
    return (
      <>
      {this.props.overlayLoading
      &&
      <div className="loader-overlay">
        <div className="loader-logo">
          <img src="https://akru-images.s3.us-east-2.amazonaws.com/Group.svg" alt="logo" />
        </div>
        <div className="loader-body">
          <Spinner name="line-scale-party" color="#fff" />
          <p className="message">{this.props.loadingMessage}</p>
        </div>
        <div className="loader-footer">
          <p>
            <strong>©</strong> {new Date().getFullYear()} Docusynth. All Rights Reserved.
          </p>
        </div>
      </div>
      }
      </>

    )
  }
}

const mapStateToProps = ({ Settings }) => {
  let { overlayLoading, loadingMessage } = Settings
  return {
    overlayLoading,
    loadingMessage,
  }
}

export default connect(mapStateToProps)(Loader)
