import React from 'react';
import './HighlightedImage.css';
// import { Document, Page } from 'react-pdf';
// Make sure to import the worker as a URL or set it up in your project's build configuration
// import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
//
// pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;

const HighlightedImage = ({ imgSrc, ocrData }) => {
  // Function to transform OCR data to CSS-friendly format
  const transformOcrDataToBoxes = (ocrData) => {
    if (ocrData){
      return ocrData = {
        x: `${ocrData.Geometry.BoundingBox.Left * 100}%`,
        y: `${ocrData.Geometry.BoundingBox.Top * 100}%`,
        width: `${ocrData.Geometry.BoundingBox.Width * 100}%`,
        height: `${ocrData.Geometry.BoundingBox.Height * 100}%`,
      };
    }
  };

  const boxes = transformOcrDataToBoxes(ocrData);

  return (
    <div className="image-container">

<img src={imgSrc} alt="Document" />

      {/*

        <div>
      <Document file={imgSrc} >
        <Page />
      </Document>
      <p>
      </p>
    </div>



        boxes.map((box, index) => (
        <div
          key={index}
          className="highlight-overlay"
          style={{
            left: box.x,
            top: box.y,
            width: box.width,
            height: box.height,
          }}
        ></div>
      ))*/}
      {
        boxes && (
          <>
          <div
            className="line"
            style={{
              height: boxes.height,
              top: boxes.y,
            }}
          ></div>
          <div
            className="highlight-overlay"
            style={{
              left: boxes.x,
              top: boxes.y,
              width: boxes.width,
              height: boxes.height,
            }}
          >
          </div>
          </>
        )
      }
    </div>
  );
};

export default HighlightedImage;
