import React from 'react'
import Header from "../utils/header"
import Users from '../components/users'
const UsersPage = () => {
  return (
    <div>
        <Header/>
        <Users/>
    </div>
  )
}

export default UsersPage