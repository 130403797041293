import { Socket } from "socket.io-client"

/* -- set app title --*/
const AppTitle = "DocuSynth"
const { REACT_APP_SERVER } = process["env"]

/* -- set app mode -- */
// const AppMode = [""]
const AppMode = [REACT_APP_SERVER]

/* -- set API URLs --*/
const staging = "https://documentai-dev.akru.co"
const prototype = "https://documentai-dev.akru.co"
const development = "https://documentai-dev.akru.co"
const production = "https://documentai-dev.akru.co"

let SocketUrl
switch (AppMode[0]) {
  case "development":
    SocketUrl = development
    break
  case "staging":
    SocketUrl = staging
    break
  case "prototype":
    SocketUrl = prototype
    break
  case "production":
    SocketUrl = production
    break
  default:
    SocketUrl = "https://documentai-dev.akru.co"
}

// https:/documentai-dev.akru.co/connectbook?state=661fa901b0afec460cb0ceae
let ApiUrl = `${SocketUrl}`
let envoirnment = AppMode[0]

export { AppTitle, ApiUrl, SocketUrl, envoirnment }
