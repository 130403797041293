import axios from "axios"
import EventBus from "eventing-bus"
import { put, all, takeLeading, call } from "redux-saga/effects"

// import { otpLoader, overlayLoader } from "../actions/Settings"

// function* cancelDeposit({ payload }) {
//   yield put(
//     overlayLoader({ status: true, loadingMessage: "Canceling your deposit..." })
//   )
//   const { error, response } = yield call(getCall, `/wallet/bankDeposit/${payload}`)
//   if (error) EventBus.publish("error", error["response"]["data"]["message"])
//   if (response) EventBus.publish("success", response["data"]["message"])
//   yield put(overlayLoader({ status: false, loadingMessage: "" }))
//   setTimeout(function* () {
//     yield put(getHistoryDW())
//   }, 500)
// }

function* actionWatcher() {
  // yield takeLeading("CANCEL_BANK", cancelDeposit)
}

function postCall({ body, path }) {
  return axios
    .post(path, body)
    .then((response) => ({ response }))
    .catch((error) => {
      if (error.response.status === 401) {
        EventBus.publish("tokenExpired")
        return
      }
      return { error }
    })
}

function getCall(path) {
  return axios
    .get(path)
    .then((response) => ({ response }))
    .catch((error) => {
      if (error.response.status === 401) {
        EventBus.publish("tokenExpired")
        return
      }
      return { error }
    })
}

function deleteCall(path) {
  return axios
    .delete(path)
    .then((response) => ({ response }))
    .catch((error) => {
      if (error.response.status === 401) {
        EventBus.publish("tokenExpired")
        return
      }
      return { error }
    })
}

export default function* rootSaga() {
  yield all([actionWatcher()])
}
