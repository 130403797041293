import React from "react";
import SucessImage from "../../../../assets/images/receipt/Group.svg";
import DocumentImage from "../../../../assets/images/receipt/image 2.svg";
import { Link } from "react-router-dom";
const ApprovedDocument = () => {
  return (
    <div>
      <div>
        <div className="container-class">
          <div className="flex items-center justify-between border-b border-b-[#ccc] pt-[50px] pb-[20px]">
            <div>
              <p className="text-[#052460] font-semibold text-base">
                receipt.jpg
              </p>
            </div>
            {/* buttons */}
              <div className="border border-[#052460] bg-[#052460] rounded-[5px] mx-2.5">
                <Link to="/pending-document">
                <button className="py-[12px] px-12 text-white text-xs font-normal">
                  Back
                </button>
                </Link>
              </div>
          </div>
          <div className="flex items-start py-[35px]">
            <div className="border border-[#ccc] bg-[#F9F9F9] rounded-[10px] w-[40%]">
              <div className="px-[30px] py-[30px]">
                <p className="text-base font-normal text-black">
                  Document Details
                </p>
              </div>
              <hr className="border-b border-b-[#ccc] mb-[22px]" />
              <div className="pl-[30px] pr-[140px] pb-[30px]">
                <div className="flex items-center  justify-between pb-[24px]">
                  <div className="flex items-center">
                    <img src={SucessImage} alt="Sucess" className="pr-[16px]" />
                    <p className="text-black text-sm font-medium">Vendor</p>
                  </div>
                  <p className="text-[#666] text-sm font-medium">TOMO </p>
                </div>
                <div className="flex items-center  justify-between pb-[24px]">
                  <div className="flex items-center">
                    <img src={SucessImage} alt="Sucess" className="pr-[16px]" />
                    <p className="text-black text-sm font-medium">
                      Invoice Date
                    </p>
                  </div>
                  <p className="text-[#666] text-sm font-medium">06/01/2024 </p>
                </div>
                <div className="flex items-center  justify-between pb-[24px]">
                  <div className="flex items-center">
                    <img src={SucessImage} alt="Sucess" className="pr-[16px]" />
                    <p className="text-black text-sm font-medium">
                      Invoice Number
                    </p>
                  </div>
                  <p className="text-[#666] text-sm font-medium">INV - 0005</p>
                </div>
                <div className="flex items-center  justify-between pb-[24px]">
                  <div className="flex items-center">
                    <img src={SucessImage} alt="Sucess" className="pr-[16px]" />
                    <p className="text-black text-sm font-medium">Total</p>
                  </div>
                  <p className="text-[#666] text-sm font-medium">$5500.00</p>
                </div>
                <div className="flex items-center  justify-between pb-[24px]">
                  <div className="flex items-center">
                    <img src={SucessImage} alt="Sucess" className="pr-[16px]" />
                    <p className="text-black text-sm font-medium">Due Date</p>
                  </div>
                  <p className="text-[#666] text-sm font-medium">06/12/2024</p>
                </div>
                <div className="flex items-center  justify-between pb-[24px]">
                  <div className="flex items-center">
                    <img src={SucessImage} alt="Sucess" className="pr-[16px]" />
                    <p className="text-black text-sm font-medium">
                      Purchase Order
                    </p>
                  </div>
                  <p className="text-[#666] text-sm font-medium">-</p>
                </div>
                <div className="flex items-center  justify-between pb-[24px]">
                  <div className="flex items-center">
                    <img src={SucessImage} alt="Sucess" className="pr-[16px]" />
                    <p className="text-black text-sm font-medium">Tax</p>
                  </div>
                  <p className="text-[#666] text-sm font-medium">-</p>
                </div>
                <div className="flex items-center  justify-between pb-[24px]">
                  <div className="flex items-center">
                    <img src={SucessImage} alt="Sucess" className="pr-[16px]" />
                    <p className="text-black text-sm font-medium">
                      Payment Terms
                    </p>
                  </div>
                  <p className="text-[#666] text-sm font-medium">-</p>
                </div>
                <div className="flex items-center  justify-between pb-[24px]">
                  <div className="flex items-center">
                    <img src={SucessImage} alt="Sucess" className="pr-[16px]" />
                    <p className="text-black text-sm font-medium">Freight</p>
                  </div>
                  <p className="text-[#666] text-sm font-medium">-</p>
                </div>
                <div className="flex items-center  justify-between ">
                  <div className="flex items-center">
                    <img src={SucessImage} alt="Sucess" className="pr-[16px]" />
                    <p className="text-black text-sm font-medium">
                      Document Type
                    </p>
                  </div>
                  <select className="text-[#666] text-sm font-medium outline-none bg-[#F9F9F9]">
                    <option>Invoice</option>
                    <option>1</option>
                    <option>2</option>
                  </select>
                </div>
              </div>
              <hr className="border-b border-b-[#ccc] mb-[22px]" />
              <div className="pl-[30px] pr-[140px] pb-[20px] flex items-center  justify-between ">
                <p className="text-black text-sm font-medium">
                  Account Details
                </p>
                {/* tag-for-account-details */}
                <p className="text-[#666] text-sm font-medium"></p>
              </div>
              <hr className="border-b border-b-[#ccc] mb-[22px]" />
              <div className="pl-[30px] pr-[140px] pb-[20px]">
                <div className="flex items-center  justify-between pb-[24px]">
                  <div className="flex items-center">
                    <img src={SucessImage} alt="Sucess" className="pr-[16px]" />
                    <p className="text-black text-sm font-medium">GL Account</p>
                  </div>
                </div>
                <div className="flex items-center  justify-between pb-[24px]">
                  <div className="flex items-center">
                    <img src={SucessImage} alt="Sucess" className="pr-[16px]" />
                    <p className="text-black text-sm font-medium">Job ID</p>
                  </div>
                </div>
                <div className="flex items-center  justify-between pb-[24px]">
                  <div className="flex items-center">
                    <img src={SucessImage} alt="Sucess" className="pr-[16px]" />
                    <p className="text-black text-sm font-medium">Cost ID</p>
                  </div>
                </div>
                <div className="flex items-center  justify-between ">
                  <div className="flex items-center">
                    <img src={SucessImage} alt="Sucess" className="pr-[16px]" />
                    <p className="text-black text-sm font-medium">Cost Type</p>
                  </div>
                </div>
              </div>
            </div>
            {/* image-div */}
            <div className="pl-[30px]">
              <img src={DocumentImage} alt="document"  />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApprovedDocument;
