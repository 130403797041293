import React from 'react'
import Header from '../utils/header'
import ApprovedDocument from '../components/documents/toReviewTable/approvedDocument'

const ApprovedDocumentPage = () => {
  return (
    <div>
        <Header/>
        <ApprovedDocument/>
    </div>
  )
}

export default ApprovedDocumentPage