import React, { useState, useEffect } from "react";
import ToReviewTable from "./toReviewTable/index";
import Rejected from "./Rejected/index";
import Exported from "./Exported/index";
import UploadDocuments from "./toReviewTable/uploadDocuments";
import axios from "axios";

const Tabs = () => {
  const [activeTab, setActiveTab] = useState("To Review");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const tabData = {
    "To Review": <ToReviewTable />,
    "Rejected": <Rejected />,
    "Exported": <Exported />,
  };

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      <div className="container-class">
        <div>
          <div className="text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700">
            <div className="pt-[50px] flex flex-col-reverse lg:flex-row items-center justify-between">
              {/* tabs-links */}
              <ul className="flex flex-wrap w-full lg:w-[50%] ">
                {Object.keys(tabData).map((tabName) => (
                  <li key={tabName} className="me-2 cursor-pointer">
                    <button
                      onClick={() => handleTabClick(tabName)}
                      className={`inline-block p-[20px]  border-b-2 rounded-t-lg ${
                        activeTab === tabName
                          ? "text-[#004761] border-[#004761] "
                          : "border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 font-normal"
                      }`}
                      aria-current={activeTab === tabName ? "page" : "false"}
                    >
                      {tabName}
                    </button>
                  </li>
                ))}
              </ul>
              {/* buttons */}
              <div className="flex items-center mb-[18px] w-full lg:w-[50%] justify-center lg:justify-end">
                {/*
                <div className="border border-[#004761] rounded-[5px]">
                  <button className="py-[12px] px-12 text-[#004761] text-xs font-normal">
                    Split PDF
                  </button>
                </div>
              */}
                <div
                  onClick={openModal}
                  className="border border-[#004761] bg-[#004761] rounded-[5px] w-[48%] lg:w-[40%] mx-2.5"
                >
                  <button className="py-[12px] px-12 text-white text-xs font-normal">
                    Upload Files
                  </button>
                </div>
                <div className="relative  flex items-center w-[48%] lg:w-[40%] ">
                  <input
                    placeholder="search"
                    className=" outline-none  border border-[#ccc] w-full  rounded-[5px] text-xs text-[#999] py-[12px] pl-[15px] pr-[30px]"
                  />
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon icon-tabler icon-tabler-search"
                    width="14"
                    height="14"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="#ccc"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    className="mr-2 right-0 cursor-pointer absolute"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0" />
                    <path d="M21 21l-6 -6" />
                  </svg>
                </div>
              </div>
            </div>
          </div>
          <div className="pt-[35px]">{tabData[activeTab]}</div>
        </div>
      </div>
      {isModalOpen && <UploadDocuments closeModal={closeModal} />}
    </div>
  );
};

export default Tabs;
