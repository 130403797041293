import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import EventBus from "eventing-bus";
import moment from "moment";
import { overlayLoader } from "../../../store/actions/Settings";
import { connect } from "react-redux";
// import Modal from 'react-bootstrap/Modal';

const Exported = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const [show, setShow] = useState(true);

  useEffect(() => {}, []);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const navigateToFile = (event) => {
    navigate("/pending-document/" + event);
  };

  const navigateToHome = (event) => {
    // navigate("/pending-document/");
  };
  const handleOpenModal = (item) => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const data = [
    {
      id: "-",
      receivedAt: "-",
      vendorName: "-",
      amountDue: "-",
      poNumber: "-",
      dueDate: "-",
      documentName: "-",
      documentID: "-",
      status: "-",
    },
  ];
  // status-colors
  const statusColors = {
    duplicate: "text-[#EF5350]",
    pending: "text-[#FFA500]",
    approved: "text-[#4CAF50]",
    rejected: "text-[#F44336]",
  };

  return (
    <div>
      <div className="relative overflow-x-auto sm:rounded-lg">
        <table className="w-full text-sm text-left rtl:text-right">
          <thead className="text-xs uppercase border-b border-b-[#ccc]">
            <tr>
              <th scope="col" className="p-4">
                <div className="flex items-center">
                  <input
                    id="checkbox-all-search"
                    type="checkbox"
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label htmlFor="checkbox-all-search" className="sr-only">
                    checkbox
                  </label>
                </div>
              </th>
              <th scope="col" className="px-6 py-3 text-[10px] lg:text-sm font-medium">
                Received At
              </th>
              <th scope="col" className="px-6 py-3 text-[10px] lg:text-sm font-medium">
                Vendor Name
              </th>
              <th scope="col" className="px-6 py-3 text-[10px] lg:text-sm font-medium">
                Amount Due
              </th>
              <th scope="col" className="px-6 py-3 text-[10px] lg:text-sm font-medium">
                PO Number
              </th>
              <th scope="col" className="px-6 py-3 text-[10px] lg:text-sm font-medium">
                Due Date
              </th>
              <th scope="col" className="px-6 py-3 text-[10px] lg:text-sm font-medium">
                Document Name
              </th>
              <th scope="col" className="px-6 py-3 text-[10px] lg:text-sm font-medium">
                Document ID
              </th>
              <th scope="col" className="px-6 py-3 text-[10px] lg:text-sm font-medium">
                Status
              </th>
              <th scope="col" className="px-6 py-3 text-[10px] lg:text-sm font-medium">
                Actions
              </th>
              <th scope="col" className="px-6 py-3 text-[10px] lg:text-sm font-medium"></th>
            </tr>
          </thead>
          <tbody>
            {/* Map over data and generate table rows */}
            {data.map((item, index) => (
              <tr
                key={index}
                className="bg-white border-b border-b-[#ccc] dark:bg-gray-800 dark:border-gray-700"
              >
                <td className="w-4 px-4 py-[26px]">
                  <div className="flex items-center">
                    <input
                      id={`checkbox-table-search-${index}`}
                      type="checkbox"
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label
                      htmlFor={`checkbox-table-search-${index}`}
                      className="sr-only"
                    >
                      checkbox
                    </label>
                  </div>
                </td>
                <td className="px-6 py-4 font-normal text-[#666] text-xs whitespace-nowrap">
                  {item.receivedAt}
                </td>
                <td className="px-6 py-4 font-normal text-[#666] text-xs">
                  {item.vendorName}
                </td>
                <td className="px-6 py-4 font-normal text-[#666] text-xs">
                  {item.amountDue}
                </td>
                <td className="px-6 py-4 font-normal text-[#666] text-xs">
                  {item.poNumber}
                </td>
                <td className="px-6 py-4 font-normal text-[#666] text-xs">
                  {item.dueDate}
                </td>
                <td
                  onClick={navigateToHome}
                  className="cursor-pointer px-6 py-4 font-normal text-[#666] text-xs"
                >
                  {item.documentName}
                </td>
                <td className="px-6 py-4 font-normal text-[#666] text-xs">
                  {item.documentID}
                </td>
                <td
                  className={`px-6 py-4 font-normal text-xs ${
                    statusColors[item.status.toLowerCase()] || "text-[#666]"
                  } cursor-pointer`}
                >
                  {item.status}
                </td>
                <td className="px-6 py-4 font-normal text-[#666] text-xs">
                  Send to ERP
                </td>
                <td class="flex items-center px-6 pt-[20px]">
                  <button className="font-medium text-blue-600 dark:text-blue-500 hover:underline">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="icon icon-tabler icon-tabler-trash"
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="#ff2825"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M4 7l16 0" />
                      <path d="M10 11l0 6" />
                      <path d="M14 11l0 6" />
                      <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
                      <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                    </svg>
                  </button>
                  <button
                    onClick={navigateToHome}
                    className="font-medium text-red-600 dark:text-red-500 hover:underline ms-3"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="icon icon-tabler icon-tabler-edit"
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="#052460"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1" />
                      <path d="M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z" />
                      <path d="M16 5l3 3" />
                    </svg>
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const mapStateToProps = ({ Settings, Auth }) => {
  let {} = Settings;
  return {};
};

const mapDispatchToProps = {
  overlayLoader,
};
export default connect(mapStateToProps, mapDispatchToProps)(Exported);
