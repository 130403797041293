import React, { useState, useEffect } from "react";

import axios from "axios";
import ProfileDetails from "./ProfileDetails";
import ManagePlan from "./ManagePlan";
import Invoices from "./Invoices";

const Tabs = () => {
  const [activeTab, setActiveTab] = useState("Profile Details");

  const tabData = {
    "Profile Details": <ProfileDetails />,
    "Manage plan": <ManagePlan />,
    Invoices: <Invoices />,
  };

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  return (
    <div>
      <div className="container-class">
        <div>
          <div className="text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700">
            <div className="pt-[50px] flex flex-col-reverse lg:flex-row items-center justify-between">
              {/* tabs-links */}
              <ul className="flex flex-wrap w-full lg:w-[50%] ">
                {Object.keys(tabData).map((tabName) => (
                  <li key={tabName} className="me-2 cursor-pointer">
                    <button
                      onClick={() => handleTabClick(tabName)}
                      className={`inline-block p-[20px]  border-b-2 rounded-t-lg ${
                        activeTab === tabName
                          ? "text-[#004761] border-[#004761] "
                          : "border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 font-normal"
                      }`}
                      aria-current={activeTab === tabName ? "page" : "false"}
                    >
                      {tabName}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div>{tabData[activeTab]}</div>
        </div>
      </div>
    </div>
  );
};

export default Tabs;
