import React, { useEffect, useState } from "react";
import Logo from "../assets/images/header/Group.svg";
import Bell from "../assets/images/header/Bell.svg";
import Profile from "../assets/images/header/ByeWind.svg";
import { Link, useLocation } from "react-router-dom";
const Header = () => {
  const [activeLink, setActiveLink] = useState("dashboard");
  const location = useLocation();
  const handleLinkClick = (linkName) => {
    setActiveLink(linkName);
  };
  useEffect(() => {
    if (location.pathname === "/") {
      setActiveLink("dashboard");
    } else if (location.pathname === "/documents") {
      setActiveLink("Documents");
    } else if (location.pathname === "/integrations") {
      setActiveLink("Integrations");
    } else if (location.pathname === "/policy") {
      setActiveLink("Policy");
    }
  }, [location.pathname]);
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  return (
    <div className="border-b border-b-[#1c1c1c1a] ">
      <div className="container-class">
        <div className=" py-2.5 flex items-center justify-between ">
          <div className="cursor-pointer ">
            <Link to="/">
              <img src={Logo} alt="Logo" className="w-full h-full" />
            </Link>
          </div>
          {/* links */}
          <div className="flex items-center justify-between">
            {/* Dashboard Link */}
            <Link to="/">
              <div
                className={`border-r-[1px] cursor-pointer ${
                  activeLink === "dashboard"
                    ? "border-r-[#004761]"
                    : "border-r-[#999]"
                }`}
                onClick={() => handleLinkClick("dashboard")}
              >
                <p
                  className={`text-xs xl:text-sm ${
                    activeLink === "dashboard" ? "font-semibold" : "font-normal"
                  } leading-[20px] px-4 ${
                    activeLink === "dashboard"
                      ? "text-[#004761]"
                      : "text-[#999]"
                  }`}
                >
                  Dashboard
                </p>
              </div>
            </Link>

            {/* Documents Link */}
            <Link to="/documents">
              <div
                className={`border-r-[1px] cursor-pointer ${
                  activeLink === "Documents"
                    ? "border-r-[#004761]"
                    : "border-r-[#999]"
                }`}
                onClick={() => handleLinkClick("Documents")}
              >
                <p
                  className={`text-xs xl:text-sm ${
                    activeLink === "Documents" ? "font-semibold" : "font-normal"
                  } leading-[20px] px-4 ${
                    activeLink === "Documents"
                      ? "text-[#004761]"
                      : "text-[#999]"
                  }`}
                >
                  Documents
                </p>
              </div>
            </Link>
            {/* Integrations Link */}
            <Link to="/integrations">
              <div
                className={`border-r-[1px] cursor-pointer ${
                  activeLink === "Integrations"
                    ? "border-r-[#004761]"
                    : "border-r-[#999]"
                }`}
                onClick={() => handleLinkClick("Integrations")}
              >
                <p
                  className={`text-xs xl:text-sm ${
                    activeLink === "Integrations"
                      ? "font-semibold"
                      : "font-normal"
                  } leading-[20px] px-4 ${
                    activeLink === "Integrations"
                      ? "text-[#052460]"
                      : "text-[#999]"
                  }`}
                >
                  Integrations
                </p>
              </div>
            </Link>
            {/* Policy Link */}
            <Link to="/policy">
              <div
                className="cursor-pointer"
                onClick={() => handleLinkClick("Policy")}
              >
                <p
                  className={`text-xs xl:text-sm ${
                    activeLink === "Policy" ? "font-semibold" : "font-normal"
                  } leading-[20px] pl-4 ${
                    activeLink === "Policy" ? "text-[#004761]" : "text-[#999]"
                  }`}
                >
                  Policy
                </p>
              </div>
            </Link>
          </div>
          {/* profile */}
          <div className="flex items-center justify-between">
            {/* <div className="relative"> */}
            <div className="cursor-pointer">
              <img alt="Bell" src={Bell} />
            </div>
            <div
              className="flex items-center pl-6 cursor-pointer"
              onClick={toggleDropdown}
            >
              <img
                alt="Profile"
                src={
                  localStorage.getItem("userImage") || Logo
                }
                style={{ borderRadius: "50%", width: "40px", height: "40px" }}
              />
              <p className="text-[#004761] text-sm font-normal px-3">
                {localStorage.getItem("userName")}
              </p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="icon icon-tabler icon-tabler-chevron-down"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="#004761"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M6 9l6 6l6 -6" />
              </svg>
            </div>
            {isOpen && (
              <div className="absolute right-[30px] lg:right-[60px] xl:right-[85px] top-[40px] mt-2 w-48 bg-white border border-gray-200 rounded-md shadow-lg z-10">
                {/* Dropdown items */}
                <div className="py-2">
                  <a
                    href="/users"
                    className="text-xs xl:text-sm font-normal leading-[20px] px-4 text-[#999] hover:text-[#004761]"
                  >
                    Users
                  </a>
                  <a
                    href="/profile"
                    className="block py-2 text-xs xl:text-sm font-normal leading-[20px] px-4 text-[#999] hover:text-[#004761]"
                  >
                    Profile
                  </a>
                  <a
                    href="/log-in"
                    className="block py-2 text-xs xl:text-sm font-normal leading-[20px] px-4 text-[#999] hover:text-[#004761]"
                  >
                    Log Out
                  </a>
                </div>
              </div>
            )}
          </div>
          {/* </div> */}
        </div>
      </div>
    </div>
  );
};

export default Header;
