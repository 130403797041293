import axios from "axios"
import EventBus from "eventing-bus"
import { userDashHeader } from "../actions/Auth"
import { put, all, takeLeading, call } from "redux-saga/effects"

import { overlayLoader, otpLoader } from "../actions/Settings"

// function* getAllNotifications() {
//   const { error, response } = yield call(getCall, "/notification")
//   if (error) EventBus.publish("error", error["response"]["data"]["message"])
//   else if (response) {
//     yield put({ type: "ALL_NOTIFICATIONS", payload: response["data"]["body"] })
//   }
// }

function* actionWatcher() {
  // yield takeLeading("GET_ALL_NOTIFICATIONS", getAllNotifications)
}

function postCall({ body, path }) {
  return axios
    .post(path, body)
    .then((response) => ({ response }))
    .catch((error) => {
      if (error.response.status === 401) {
        EventBus.publish("tokenExpired")
        return
      }
      return { error }
    })
}

function getCall(path) {
  return axios
    .get(path)
    .then((response) => ({ response }))
    .catch((error) => {
      if (error.response.status === 401) {
        EventBus.publish("tokenExpired")
        return
      }
      return { error }
    })
}

function deleteCall(path) {
  return axios
    .delete(path)
    .then((response) => ({ response }))
    .catch((error) => {
      if (error.response.status === 401) {
        EventBus.publish("tokenExpired")
        return
      }
      return { error }
    })
}

export default function* rootSaga() {
  yield all([actionWatcher()])
}
