import axios from "axios"
import EventBus from "eventing-bus"
import { put, all, takeLeading, call } from "redux-saga/effects"

// function* transactionHistory() {
//   const { error, response } = yield call(getCall, `/exchange/history`)
//   if (error) EventBus.publish("error", error["response"]["data"]["message"])
//   else if (response) {
//     yield put(responseTransactionHistory(response["data"]["body"]))
//   }
//   yield put(overlayLoader({ status: false, loadingMessage: "" }))
// }

function* actionWatcher() {
  // yield takeLeading("TRANSACTION_HISTORY", transactionHistory)
}

function postCall({ body, path }) {
  return axios
    .post(path, body)
    .then((response) => ({ response }))
    .catch((error) => {
      if (error.response.status === 401) {
        EventBus.publish("tokenExpired")
        return
      }
      return { error }
    })
}

function getCall(path) {
  return axios
    .get(path)
    .then((response) => ({ response }))
    .catch((error) => {
      if (error.response.status === 401) {
        EventBus.publish("tokenExpired")
        return
      }
      return { error }
    })
}

export default function* rootSaga() {
  yield all([actionWatcher()])
}
