import React, { useState, useEffect } from 'react';
import Header from "../utils/header";
import ProcessCards from "../components/dashBoard/processCards";
import Charts from "../components/dashBoard/charts";
import axios from 'axios';

const DashBoard = () => {

  const [dashData, setDashData] = useState(null);
  const [graphData, setGraphData] = useState(null);

  useEffect(() => {
    dashboardAggregate()
    dashboardGraphs()
  }, []);

  const dashboardAggregate = () => {
  try{
    // axios.get(`/connectbook?state=${id}`,{
    axios.get(`/api/document/dashboardAggregate`,{
        headers: {
          'Access-Control-Allow-Origin': '*',
          mode: 'no-cors',
          Authorization: `${localStorage.getItem('token')}`,
        },
      })
      .then(function (response) {
        console.log('response api dashboardAggregate', response);
        setDashData(response.data.body)
      })
      .catch(function (error) {
        console.error('Error:', error);
        console.log(error);
      });
    } catch (error) {
      console.error('Error:', error);
    }
  }

  const dashboardGraphs = () => {
  try{
    // axios.get(`/connectbook?state=${id}`,{
    axios.get(`/api/document/dashboardGraphs`,{
        headers: {
          'Access-Control-Allow-Origin': '*',
          mode: 'no-cors',
          Authorization: `${localStorage.getItem('token')}`,
        },
      })
      .then(function (response) {
        console.log('response api dashboardGraphs', response);
        setGraphData(response.data.body)
      })
      .catch(function (error) {
        console.error('Error:', error);
        console.log(error);
      });
    } catch (error) {
      console.error('Error:', error);
    }
  }

  return (
    <div>
      <Header dashData={dashData} graphData={graphData} />
      <ProcessCards dashData={dashData} graphData={graphData}/>
      <Charts dashData={dashData} graphData={graphData}/>
    </div>
  );
};

export default DashBoard;
