import React from "react";

const ProcessCards = ({dashData}) => {
  return (
    <div>
      <div className="container-class">
        {/* cards-div */}
        <div className="py-[30px] flex items-center flex-col xl:flex-row justify-between gap-2">
          <div className="p-[24px] bg-[#81c7841a] w-full xl:w-[220px] rounded-[16px] border border-[#81c78480]">
            <p className="text-xs xl:text-sm font-semibold ">Processing</p>
            <p className="text-[18px] xl:text-[24px] font-semibold pt-2">0</p>
          </div>
          <div className="p-[24px] bg-[#ffd54f1a] w-full xl:w-[220px] rounded-[16px] border border-[#ffd54f80]">
            <p className="text-xs xl:text-sm font-semibold ">Pending</p>
            <p className="text-[18px] xl:text-[24px] font-semibold pt-2">{dashData && dashData.length > 0 && dashData[1] ? dashData[1].total : 0}</p>
          </div>
          <div className="p-[24px] bg-[#ef53501a] w-full xl:w-[220px] rounded-[16px] border border-[#ef535080]">
            <p className="text-xs xl:text-sm font-semibold ">Error</p>
            <p className="text-[18px] xl:text-[24px] font-semibold pt-2">0</p>
          </div>
          <div className="p-[24px] bg-[#42a5f51a] w-full xl:w-[220px] rounded-[16px] border border-[#42a5f580]">
            <p className="text-xs xl:text-sm font-semibold ">Approved</p>
            <p className="text-[18px] xl:text-[24px] font-semibold pt-2">{dashData && dashData.length > 0 && dashData[0] ? dashData[0].total :0}</p>
          </div>
          <div className="p-[24px] bg-[#fff1761a] w-full xl:w-[220px] rounded-[16px] border border-[#fff17680]">
            <p className="text-xs xl:text-sm font-semibold ">In Review</p>
            <p className="text-[18px] xl:text-[24px] font-semibold pt-2">0</p>
          </div>
          <div className="p-[24px] bg-[#ef53501a] w-full xl:w-[220px] rounded-[16px] border border-[#ef535080]">
            <p className="text-xs xl:text-sm font-semibold ">Rejected</p>
            <p className="text-[18px] xl:text-[24px] font-semibold pt-2">0</p>
          </div>
          <div className="p-[24px] bg-[#0524601a] w-full xl:w-[220px] rounded-[16px] border border-[#05246080]">
            <p className="text-xs xl:text-sm font-semibold ">Total</p>
            <p className="text-[18px] xl:text-[24px] font-semibold pt-2">0</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProcessCards;
