import React from 'react'
import Header from "../utils/header"
import Tabs from '../components/integrations/tabs'
const Integrations = () => {
  return (
    <div>
      <Header/>
      <Tabs/>
    </div>
  )
}

export default Integrations