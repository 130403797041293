import React from "react";
import Header from "../utils/header";
import Tabs from "../components/profile/tabs";
const Profile = () => {
  return (
    <div>
      <Header />
      <Tabs />
    </div>
  );
};

export default Profile;
