import axios from "axios"
// import jwt_decode from "jwt-decode"
import EventBus from "eventing-bus"
import { put, all, takeLeading, call } from "redux-saga/effects"
import { otpLoader, overlayLoader } from "../actions/Settings"

function* loginRequest({ payload, history }) {
  console.log('loginRequest', payload);
  const { address, email } = payload
  const { error, response } = yield call(getCall, `/user/getUser/${address}`)
  if (error) {
    yield put({ type: 'RETRY_ADD_WALLET', payload: { history, publicAddress: address, email }})
  }
  if (response) {
    yield put({
      type: "SIGN_MESSAGE",
      data: {
        history,
        type: "login",
        publicAddress: address,
        nonce: response["data"]["body"]["nonce"],
      },
    })
  }
}

function* actionWatcher() {
  yield takeLeading("LOGIN_REQUEST", loginRequest)
}

function postCall({ body, path }) {
  return axios
    .post(path, body)
    .then((response) => ({ response }))
    .catch((error) => {
      if (error.response.status === 401) {
        EventBus.publish("tokenExpired")
        return
      }
      return { error }
    })
}

function getCall(path) {
  return axios
    .get(path)
    .then((response) => ({ response }))
    .catch((error) => {
      if (error.response.code === 401) {
        EventBus.publish("tokenExpired")
        return
      }
      return { error }
    })
}

export default function* rootSaga() {
  yield all([actionWatcher()])
}
