import React, { useState, useEffect } from 'react';
import "./assets/style/style.css";
import RoutesPages from "./routes";
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import EventBus from "eventing-bus"
import Loader from "./components/Loader"

const App = () => {

  const handleError = (e) =>
  toast(` ${e}`, {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    className:"dangerToastMessage",
  });

  const handleSuccess = (e) =>
  toast(` ${e}`, {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    className:"successToastMessage",
  });

  useEffect(() => {
    EventBus.on("error", handleError)
    EventBus.on("success", handleSuccess)

    return () => {
      EventBus.unregisterCallbacksForEvent("error", handleError);
      EventBus.unregisterCallbacksForEvent("success", handleSuccess);
    };
  }, []);

  return (
    <div>
    <Loader />
    <ToastContainer
    position="top-right"
    autoClose={5000}
    limit={1}
    hideProgressBar={false}
    newestOnTop={false}
    closeOnClick
    rtl={false}
    pauseOnFocusLoss
    draggable
    pauseOnHover
    theme="dark"
    />
      <RoutesPages />
    </div>
  );
};

export default App;
