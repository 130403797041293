import React, { useState } from "react";

const ManagePlan = () => {
  const [activePlan, setActivePlan] = useState(null);

  const plans = [
    {
      name: "30 Days Trial",
      price: "$0",
      description: "5 Invoices",
      monthlyPrice: "/Month",
      invoices: "5 Invoices",
      overInvoices: "",
    },
    {
      name: "Starter",
      price: "$99",
      description: "100 Invoices",
      monthlyPrice: "/Month",
      invoices: "100 Invoices",
      overInvoices: "$1.5 per invoice over 100",
    },
    {
      name: "Premium",
      price: "$199",
      description: "200 Invoices",
      monthlyPrice: "/Month",
      invoices: "200 Invoices",
      overInvoices: "$1.5 per invoice over 200",
    },
    {
      name: "Enterprise",
      price: "Call For Pricing",
      description:
        "API Access $2500 monthly negotiable on invoice over 1,000 per month",
      monthlyPrice: "",
      invoices:
        "API Access $2500 monthly negotiable on invoice over 1,000 per month",
      overInvoices: "",
    },
  ];

  const handleClick = (planName) => {
    setActivePlan(planName);
  };

  return (
    <div className="pt-[34px]">
      <div className="border border-[#CCC] rounded-[6px] w-full">
        <div className="p-[30px]">
          <div className="pb-[30px]">
            <p className="text-[#000] text-[16px] font-[600]">
              Current Plan:<span className="text-[#004761]">Starter</span>{" "}
            </p>
          </div>
          <div className="flex items-center justify-between w-[70%]">
            <div>
              <p className="text-[#222] text-[14px] font-[400px] pb-[4px]">
                Total Invoices Limit
              </p>
              <p className="text-[#004761] text-[14px] font-[400px]">100</p>
            </div>
            <div>
              <p className="text-[#222] text-[14px] font-[400px] pb-[4px]">
                Invoice over 100
              </p>
              <p className="text-[#004761] text-[14px] font-[400px]">
                $1.5 per invoice
              </p>
            </div>
            <div>
              <p className="text-[#222] text-[14px] font-[400px] pb-[4px]">
                Estimated Monthly Charge
              </p>
              <p className="text-[#004761] text-[14px] font-[400px]">$99</p>
            </div>
            <div>
              <p className="text-[#222] text-[14px] font-[400px] pb-[4px]">
                Next Payment Date
              </p>
              <p className="text-[#004761] text-[14px] font-[400px]">
                July 12, 2024
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-between pt-[50px] pb-[20px]">
        <div>
          <p className="text-[#004761] font-semibold text-base">Upgrade Plan</p>
        </div>
      </div>
      <div className="border-b border-b-[#ccc] w-full">
        <hr className="border border-[#004761] w-[100px]" />
      </div>
      <div className="py-[34px] flex items-center justify-between">
        {plans.map((plan, index) => (
          <div
            key={index}
            className={`border rounded-[10px] h-[300px] w-[24%] cursor-pointer ${
              activePlan === plan.name ? "border-[#004761]" : "border-[#ccc]"
            }`}
            onClick={() => handleClick(plan.name)}
          >
            <div className="px-[30px] pt-[30px] pb-[10px] flex items-center justify-between">
              <div>
                <p className="text-[#004761] text-[24px] font-semibold">
                  {plan.name}
                </p>
              </div>
              <div>
                {activePlan === plan.name ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <rect width="20" height="20" rx="10" fill="#004761" />
                    <circle cx="10" cy="10" r="6" fill="white" />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <rect width="20" height="20" rx="10" fill="#CCCCCC" />
                    <circle cx="10" cy="10" r="8" fill="white" />
                  </svg>
                )}
              </div>
            </div>
            <div className="pb-[20px] px-[30px]">
              <p className="text-[#004761] text-[22px] font-bold">
                {plan.price}
                <span className="text-[8px] text-[#0F172A] font-[400]">
                  {plan.monthlyPrice}
                </span>
              </p>
            </div>
            <div
              className={`border ${
                activePlan === plan.name ? "border-[#004761]" : "border-[#ccc]"
              }`}
            ></div>
            <div className="pt-[30px] px-[30px]">
              <p className="text-[#666] font-normal text-[18px] opacity-90">
                {plan.invoices}
              </p>
              {plan.overInvoices && (
                <p className="text-[#666] font-normal text-[18px] pt-[20px] w-[140px] opacity-90">
                  {plan.overInvoices}
                </p>
              )}
            </div>
          </div>
        ))}
      </div>
      <div className="border-[0.5px] border-[#ccc]"></div>
      <div className=" pb-[120px]">
        <div className="flex justify-end py-[34px]">
          <div className="pr-[40px]">
            <div>
              <p className="text-[#000] text-[16px] font-normal">Sub Total</p>
            </div>
            <div>
              <p className="text-[#004761] text-[30px] font-medium">
                $199
                <span className="text-[#0F172A] text-[10px] font-normal">
                  /Month
                </span>
              </p>
            </div>
          </div>
          <div className="flex items-center gap-5">
            <button className="bg-[#004761] border border-[#004761] text-center py-[14px] flex justify-center w-[130px] rounded-[5px] text-base text-white font-medium">
              Upgrade
            </button>
            <button className="bg-[#FFF] border border-[#004761] text-center py-[14px] flex justify-center w-[130px] rounded-[5px] text-base text-[#004761] font-medium">
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManagePlan;
