import React from "react";
import DuplicateImage from "../../../../assets/images/duplicate/image 1.svg";
import DuplicateImage1 from "../../../../assets/images/duplicate/Group (1).svg";
const Duplicate = ({ closeModal }) => {
  return (
    <div className="bg-black bg-opacity-60 h-screen absolute inset-0 z-30">
      <div className="flex justify-center h-[inherit] items-center">
        <div className="bg-white w-[70%] py-[32px] px-[114px] rounded-lg">
          <div className="flex justify-center">
            <div>
              <p className="text-[#191D23] font-medium text-xl">
                Duplicate Document
              </p>
            </div>
          </div>
          <div className="border-[2px] border-dashed border-dashed-[#CCC] rounded-lg p-[30px] my-[38px] flex flex-col items-center">
            <div className="flex items-center justify-between w-[100%]">
              <div>
                <p className="text-[#191D23] text-xl font-medium pb-[30px] text-center">
                  receipt.jpg
                </p>
                <img src={DuplicateImage} alt="same" />
              </div>
              <div className="px-[76px]">
                <img src={DuplicateImage1} alt="" />
              </div>
              <div>
                <p className="text-[#191D23] text-xl font-medium pb-[30px] text-center">
                  receipt.jpg
                </p>
                <img src={DuplicateImage} alt="same" />
              </div>
            </div>
          </div>
          <div className="flex justify-center ">
            <div className="border border-[#CCC] bg-[#EF5350]  rounded-[5px] mr-[20px]">
              <button className="py-[12px] px-[40px] text-white text-xs font-normal">
                Delete
              </button>
            </div>
            <div
              onClick={closeModal}
              className="border border-[#CCC] bg-[#052460]  rounded-[5px]"
            >
              <button className="py-[12px] px-[40px] text-white text-xs font-normal">
                Proceed
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Duplicate;
