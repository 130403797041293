import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Duplicate from "./duplicate";
import EventBus from "eventing-bus";
import moment from "moment";
import { overlayLoader } from "../../../store/actions/Settings";
import { connect } from "react-redux";
// import Modal from 'react-bootstrap/Modal';

const ToReviewTable = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [accounts, setAccounts] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [items, setItems] = useState([]);
  const [taxCodes, setTaxCodes] = useState([]);
  const [vendor, setVendor] = useState([]);
  const [show, setShow] = useState(true);

  useEffect(() => {
    // refreshToken();
    getallDocuments();
    getAllCustomers();
    getAllVendors();
    getAllItems();
    gelAllTaxCodes();
    getallAccounts();
  }, []);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const navigateToFile = (event) => {
    navigate("/pending-document/" + event);
  };

  const navigateToHome = (event) => {
    navigate("/pending-document/");
  };
  const handleOpenModal = (item) => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    getallDocuments();
    setIsModalOpen(false);
  };

  const refreshToken = () => {
    setLoading(true);
    try {
      axios
        .get("https://documentai-dev.akru.co/api/user/refreshToken", {
          headers: {
            "Access-Control-Allow-Origin": "*",
            mode: "no-cors",
            Authorization: `${localStorage.getItem("token")}`,
          },
        })
        .then(function (response) {})
        .catch(function (error) {
          console.error("Error:", error);
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const getAllVendors = () => {
    setLoading(true);
    try {
      axios
        .get("https://documentai-dev.akru.co/api/user/getAllVendors", {
          headers: {
            "Access-Control-Allow-Origin": "*",
            mode: "no-cors",
            Authorization: `${localStorage.getItem("token")}`,
          },
        })
        .then(function (response) {
          setVendors(response.data.body);
          console.log("getAllVendors", JSON.parse(response.data.body));
        })
        .catch(function (error) {
          console.error("Error:", error);
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const getAllNewVendors = (item) => {
    props.overlayLoader({
      status: true,
      loadingMessage: "Checking Vendors",
    });
    setLoading(true);
    try {
      axios
        .get("https://documentai-dev.akru.co/api/user/getAllVendors", {
          headers: {
            "Access-Control-Allow-Origin": "*",
            mode: "no-cors",
            Authorization: `${localStorage.getItem("token")}`,
          },
        })
        .then(function (response) {
          setVendors(response.data.body);
          setTimeout(function () {
            sendtoERP(item, response.data.body);
          }, 3000);
          console.log("getAllVendors", JSON.parse(response.data.body));
        })
        .catch(function (error) {
          console.error("Error:", error);
          console.log(error);
          props.overlayLoader({
            status: false,
            loadingMessage: "",
          });
        })
        .finally(() => {
          setLoading(false);
          props.overlayLoader({
            status: false,
            loadingMessage: "",
          });
        });
    } catch (error) {
      console.error("Error:", error);
      props.overlayLoader({
        status: false,
        loadingMessage: "",
      });
    }
  };

  const getAllCustomers = () => {
    setLoading(true);
    try {
      axios
        .get("https://documentai-dev.akru.co/api/user/getAllCustomers", {
          headers: {
            "Access-Control-Allow-Origin": "*",
            mode: "no-cors",
            Authorization: `${localStorage.getItem("token")}`,
          },
        })
        .then(function (response) {
          setCustomers(response.data.body);
          console.log("getAllCustomers", JSON.parse(response.data.body));
        })
        .catch(function (error) {
          console.error("Error:", error);
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const getallDocuments = () => {
    setLoading(true);
    try {
      axios
        .get("https://documentai-dev.akru.co/api/document/getAllDocuments", {
          headers: {
            "Access-Control-Allow-Origin": "*",
            mode: "no-cors",
            Authorization: `${localStorage.getItem("token")}`,
          },
        })
        .then(function (response) {
          setDocuments(response.data.body);
          console.log("getallDocuments", response);
        })
        .catch(function (error) {
          console.error("Error:", error);
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const getallAccounts = () => {
    setLoading(true);
    try {
      axios
        .get("https://documentai-dev.akru.co/api/user/getAllGLAccounts", {
          headers: {
            "Access-Control-Allow-Origin": "*",
            mode: "no-cors",
            Authorization: `${localStorage.getItem("token")}`,
          },
        })
        .then(function (response) {
          setAccounts(response.data.body);
          console.log("getallAccounts", response);
        })
        .catch(function (error) {
          console.error("Error:", error);
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const getAllItems = () => {
    setLoading(true);
    try {
      axios
        .get("https://documentai-dev.akru.co/api/user/getAllItems", {
          headers: {
            "Access-Control-Allow-Origin": "*",
            mode: "no-cors",
            Authorization: `${localStorage.getItem("token")}`,
          },
        })
        .then(function (response) {
          setItems(response.data.body);
          console.log("getAllItems", JSON.parse(response.data.body));
        })
        .catch(function (error) {
          console.error("Error:", error);
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const gelAllTaxCodes = () => {
    setLoading(true);
    try {
      axios
        .get("https://documentai-dev.akru.co/api/user/readAllTaxCodes", {
          headers: {
            "Access-Control-Allow-Origin": "*",
            mode: "no-cors",
            Authorization: `${localStorage.getItem("token")}`,
          },
        })
        .then(function (response) {
          setTaxCodes(response.data.body);
          console.log(response);
        })
        .catch(function (error) {
          console.error("Error:", error);
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const checkPrerequisites = (item) => {
    props.overlayLoader({
      status: true,
      loadingMessage: "Checking Prerequisites",
    });
    console.log("checkPrerequisites", item, JSON.parse(vendors));
    const allVendors = JSON.parse(vendors);
    const account = allVendors.QueryResponse.Vendor.find(
      (filt) => filt.DisplayName === item.vendor
    );
    console.log("account", account);
    if (!account) {
      props.overlayLoader({
        status: true,
        loadingMessage: "Creating New Vendor",
      });
      console.log("ID not found in array. Performing action.", account);
      const apiUrl = "/api/user/createVendor";
      const requestData = {
        DisplayName: item.vendor,
        Suffix: "Sr",
        Title: "Ms",
        FamilyName: "Bradley",
        GivenName: "Dianne",
      };
      const headers = {
        Authorization: localStorage.getItem("token"),
        "Content-Type": "application/json", // Assuming you're sending JSON data
      };
      try {
        axios
          .post(apiUrl, requestData, { headers })
          .then((response) => {
            const newVendor = JSON.parse(response.data.body);
            console.log("Response:", response.data);
            console.log("Response:", JSON.parse(response.data.body));
            console.log("Vendor:", response.data.body.Vendor);
            setVendor(newVendor.Vendor.DisplayName);
            EventBus.publish("success", "New Vendor Created");
            item.vendor = newVendor.Vendor.DisplayName;
            getAllNewVendors(item);
            props.overlayLoader({
              status: false,
              loadingMessage: "",
            });
            // sendtoERP(item)
          })
          .catch((error) => {
            props.overlayLoader({
              status: false,
              loadingMessage: "",
            });
            EventBus.publish("error", error);
            console.error("Error:", error);
          });
      } catch (error) {
        props.overlayLoader({
          status: false,
          loadingMessage: "",
        });
        EventBus.publish("error", error);
        console.error("Error:", error);
      }
      // Add more code here to perform whatever action you need
    } else {
      console.log("ID found:", account, item.Vendor);
      setVendor(item.vendor);
      sendtoERP(item, vendors);
    }
  };

  const sendtoERP = (item, vendorsss) => {
    props.overlayLoader({
      status: true,
      loadingMessage: "Sending to ERP",
    });
    console.log("sendtoERP", item, vendor);
    const apiUrl = "/api/user/createBill";
    const allVendors = JSON.parse(vendorsss);
    console.log("allVendors", allVendors);
    const vendorAccount = allVendors.QueryResponse.Vendor.find(
      (filt) => filt.DisplayName === item.vendor
    );
    console.log("vendorAccount", vendorAccount);
    // Define the data to send in the request body
    const requestData = {
      amount: item.total,
      vendorId: vendorAccount.Id,
      accountId: item.accountId,
    };
    // Define your token
    // Define the headers, including the Authorization header with the token
    const headers = {
      Authorization: localStorage.getItem("token"),
      "Content-Type": "application/json", // Assuming you're sending JSON data
    };
    try {
      axios
        .post(apiUrl, requestData, { headers })
        .then((response) => {
          // Handle successful response
          console.log("Response:", response.data);
          console.log("Response:", JSON.parse(response.data.body));
          EventBus.publish("success", "Sent to ERP");
          props.overlayLoader({
            status: false,
            loadingMessage: "",
          });
        })
        .catch((error) => {
          // Handle error
          EventBus.publish("error", error);
          console.error("Error:", error);
          props.overlayLoader({
            status: false,
            loadingMessage: "",
          });
        });
    } catch (error) {
      EventBus.publish("error", error);
      console.error("Error:", error);
      props.overlayLoader({
        status: false,
        loadingMessage: "",
      });
    }
  };

  const deleteDocument = (item) => {
    console.log("item", item);
    const apiUrl = `/api/document/deleteDocument?documentId=${item._id}`;
    const headers = {
      Authorization: localStorage.getItem("token"),
      "Content-Type": "application/json", // Assuming you're sending JSON data
    };
    try {
      axios
        .get(apiUrl, { headers })
        .then((response) => {
          getallDocuments();
          EventBus.publish("success", "Document Deleted");
        })
        .catch((error) => {
          EventBus.publish("error", error);
          console.error("Error:", error);
        });
    } catch (error) {
      EventBus.publish("error", error);
      console.error("Error:", error);
    }
  };

  // const sendtoERP = () => {
  //method two
  //   console.log('sendtoERP');
  //   const apiUrl = '/api/user/createPurchaseOrder';
  //
  //   // Define the data to send in the request body
  //   const requestData = {
  //           amount:3700,
  //           customerId:3,
  //           customerName:'Cool Cars',
  //           billable:'Billable',
  //           itemId:11,
  //           itemName:"PendingDocumentPage",
  //           Qty:2,
  //           UnitPrice:11,
  //           TaxCodeRef:'NON',
  //           vendorId:56,
  //           vendorName:"Bob's Burger Joint",
  //           apaAccountId:33,
  //           apaAccountName:'Accounts Payable (A/P)',
  //           TotalAmt:3700
  //   };
  //
  //   // Define your token
  //   // Define the headers, including the Authorization header with the token
  //   const headers = {
  //     'Authorization': localStorage.getItem('token'),
  //     'Content-Type': 'application/json' // Assuming you're sending JSON data
  //   };
  //
  //   try{
  //     axios.post(apiUrl, requestData, { headers })
  //       .then(response => {
  //         // Handle successful response
  //         console.log('Response:', response.data);
  //         console.log('Response:', JSON.parse(response.data.body));
  //       })
  //       .catch(error => {
  //         // Handle error
  //         console.error('Error:', error);
  //       });
  //     } catch (error) {
  //       console.error('Error:', error);
  //     }
  // };

  // const sendtoERP = () => {
  //   console.log('sendtoERP');
  //   try{
  //     axios.post(`/api/user/createPurchaseOrder`,{
  //       headers: {
  //         'Access-Control-Allow-Origin': '*',
  //         mode: 'no-cors',
  //         Authorization: `${localStorage.getItem('token')}`,
  //       },
  //       amount:3700,
  //       customerId:3,
  //       customerName:'Cool Cars',
  //       billable:'Billable',
  //       itemId:11,
  //       itemName:'Pump',
  //       Qty:2,
  //       UnitPrice:11,
  //       TaxCodeRef:'NON',
  //       vendorId:41,
  //       vendorName:"Hicks Hardware",
  //       apaAccountId:33,
  //       apaAccountName:'Accounts Payable (A/P)',
  //       TotalAmt:1
  //       })
  //       .then(function (response) {
  //         console.log('response api', response);
  //       })
  //       .catch(function (error) {
  //         console.error('Error:', error);
  //         console.log(error);
  //       });
  //     } catch (error) {
  //       console.error('Error:', error);
  //     }
  // };

  // status-colors
  const statusColors = {
    duplicate: "text-[#EF5350]",
    pending: "text-[#FFA500]",
    approved: "text-[#4CAF50]",
    rejected: "text-[#F44336]",
  };

  return (
    <div>
      <div
        className="modal show"
        style={{ display: "block", position: "initial" }}
      ></div>
      <div className="relative overflow-x-auto sm:rounded-lg">
        {loading ? (
          <div className=" flex items-center justify-center">
            <div role="status">
              <svg
                aria-hidden="true"
                class="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        ) : (
          <table className="w-full text-sm text-left rtl:text-right">
            <thead className="text-xs uppercase border-b border-b-[#ccc]">
              <tr>
                <th scope="col" className="p-4">
                  <div className="flex items-center">
                    <input
                      id="checkbox-all-search"
                      type="checkbox"
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label htmlFor="checkbox-all-search" className="sr-only">
                      checkbox
                    </label>
                  </div>
                </th>
                <th scope="col" className="px-6 py-3 text-[10px] lg:text-sm font-medium">
                  Received At
                </th>
                <th scope="col" className="px-6 py-3 text-[10px] lg:text-sm font-medium">
                  Vendor Name
                </th>
                <th scope="col" className="px-6 py-3 text-[10px] lg:text-sm font-medium">
                  Amount Due
                </th>
                <th scope="col" className="px-6 py-3 text-[10px] lg:text-sm font-medium">
                  PO Number
                </th>
                <th scope="col" className="px-6 py-3 text-[10px] lg:text-sm font-medium">
                  Invoice Date
                </th>
                <th scope="col" className="px-6 py-3 text-[10px] lg:text-sm font-medium">
                  Document Name
                </th>
                <th scope="col" className="px-6 py-3 text-[10px] lg:text-sm font-medium">
                  Document ID
                </th>
                <th scope="col" className="px-6 py-3 text-[10px] lg:text-sm font-medium">
                  Status
                </th>
                <th scope="col" className="px-6 py-3 text-[10px] lg:text-sm font-medium">
                  Actions
                </th>
                <th scope="col" className="px-6 py-3 text-[10px] lg:text-sm font-medium"></th>
              </tr>
            </thead>
            <tbody>
              {/* Map over data and generate table rows */}
              {documents &&
                documents.length > 0 &&
                documents.map((item, index) => (
                  <tr
                    key={index}
                    className="bg-white border-b border-b-[#ccc] dark:bg-gray-800 dark:border-gray-700"
                  >
                    <td className="w-4 px-4 py-[26px]">
                      <div className="flex items-center">
                        <input
                          id={`checkbox-table-search-${index}`}
                          type="checkbox"
                          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        />
                        <label
                          htmlFor={`checkbox-table-search-${index}`}
                          className="sr-only"
                        >
                          checkbox
                        </label>
                      </div>
                    </td>
                    <td className="px-6 py-4 font-normal text-[#666] text-xs whitespace-nowrap">
                      {moment(item.createdAt).format("ll")}
                    </td>
                    <td className="px-6 py-4 font-normal text-[#666] text-xs">
                      {item.vendor}
                    </td>
                    <td className="px-6 py-4 font-normal text-[#666] text-xs">
                      {item.total}
                    </td>
                    <td className="px-6 py-4 font-normal text-[#666] text-xs">
                      {item.purchaseOrder}
                    </td>
                    <td className="px-6 py-4 font-normal text-[#666] text-xs">
                      {moment(item.invoiceDate).format("ll")}
                    </td>
                    <td
                      onClick={() => navigateToFile(item._id)}
                      className="cursor-pointer px-6 py-4 font-normal text-[#666] text-xs"
                    >
                      {item.fileName}
                    </td>
                    <td className="px-6 py-4 font-normal text-[#666] text-xs">
                      {item.documentID}
                    </td>
                    <td
                      className={`px-6 py-4 font-normal text-xs ${
                        statusColors[item.status.toLowerCase()] || "text-[#666]"
                      } cursor-pointer`}
                      onClick={() =>
                        item.status.toLowerCase() === "duplicate" &&
                        handleOpenModal(item)
                      }
                    >
                      {item.status}
                    </td>
                    <td className="px-6 py-4 font-normal text-[#666] text-xs">
                      <button
                        onClick={() => checkPrerequisites(item)}
                        disabled={item.status !== "approved"}
                      >
                        Send to ERP
                      </button>
                    </td>
                    <td class="flex items-center px-6 pt-[20px]">
                      <button
                        onClick={() => deleteDocument(item)}
                        className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="icon icon-tabler icon-tabler-trash"
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="#ff2825"
                          fill="none"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path d="M4 7l16 0" />
                          <path d="M10 11l0 6" />
                          <path d="M14 11l0 6" />
                          <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
                          <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                        </svg>
                      </button>
                      <button
                        onClick={() => navigateToFile(item._id)}
                        className="font-medium text-red-600 dark:text-red-500 hover:underline ms-3"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="icon icon-tabler icon-tabler-edit"
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="#004761"
                          fill="none"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path d="M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1" />
                          <path d="M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z" />
                          <path d="M16 5l3 3" />
                        </svg>
                      </button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        )}
      </div>
      {isModalOpen && <Duplicate closeModal={closeModal} />}
    </div>
  );
};

const mapStateToProps = ({ Settings, Auth }) => {
  let {} = Settings;
  return {};
};

const mapDispatchToProps = {
  overlayLoader,
};
export default connect(mapStateToProps, mapDispatchToProps)(ToReviewTable);
