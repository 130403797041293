import React, { useState, useEffect } from "react";
import axios from "axios";
import EventBus from "eventing-bus";
import { connect } from "react-redux";
import { overlayLoader } from "../../../../store/actions/Settings";

const UploadDocuments = ({ closeModal, ...props }) => {
// const UploadDocuments = (props) => {
  const [file, setFile] = useState(null);
  const [fileurl, setUrl] = useState(null);
  const [previewUrl, setPreviewUrl] = useState("");
  const [ocrData, setOcrData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const inputElement = document.getElementById("multiple_files");
    inputElement.addEventListener("change", handleFileChange);
    return () => {
      inputElement.removeEventListener("change", handleFileChange);
    };
  }, []);

  const handleFileChange1 = (event) => {
    console.log("event", event);
    const fileList = document.getElementById("fileList");
    fileList.innerHTML = "";
    Array.from(event.target.files).forEach((file) => {
      const fileName = document.createElement("p");
      fileName.textContent = file.name;
      fileList.appendChild(fileName);
      setFile(file);
    });
  };

  const handleFileChange = (event) => {
    console.log("event", event);

    const fileList = document.getElementById("fileList");
    fileList.innerHTML = "";
    Array.from(event.target.files).forEach((file) => {
      if (file.type == "application/pdf") {
        EventBus.publish("error", "For Testing Purpose use images only.");
        // return null;
      } else {
        const fileName = document.createElement("p");
        fileName.textContent = file.name;
        fileList.appendChild(fileName);
        setFile(file);
      }

      //       if(file.type == "application/pdf"){
      //   console.log('event', event)
      //
      //   const formData = new FormData();
      //   formData.append('file', file);
      //   try{
      //       axios.post('https://documentanalyzer.akru.co/convert-pdf', formData, {
      //         })
      //         .then(function (response) {
      //           console.log(response);
      //         })
      //         .catch(function (error) {
      //           console.error('Error:', error);
      //           console.log(error);
      //         });
      //       } catch (error) {
      //         console.error('Error:', error);
      //         alert('Error uploading file');
      //       }
      // }else{
      //   setFile(file);
      //   const reader = new FileReader();
      //   reader.onloadend = () => {
      //     setPreviewUrl(reader.result);
      //   };
      //   reader.readAsDataURL(file);
      // }
    });
  };

  const submitFile = (event) => {
    const formData = new FormData();
    formData.append("files", file);
    setLoading(true);
    // formData.append('description', 'textract file');
    try {
      props.overlayLoader({
        status: true,
        loadingMessage: "Uploading Document",
      });
      axios
        .post(
          "https://impdevapi.akru.co/api/ua/docuser/uploadFileUnauthorised",
          formData,
          {
            headers: {
              "Access-Control-Allow-Origin": "*",
              mode: "no-cors",
              Authorization: `${localStorage.getItem("token")}`,
            },
          }
        )
        .then(function (response) {
          console.log(response);
          console.log("second call ");

          console.log(response.data);
          const url = response.data.body[0].url;
          console.log("url in url", response.data.body[0].url);
          setUrl(response.data.body[0].url);
          const urlParts = url.split("/");
          // Get the last index of the split parts
          const lastIndex = urlParts[urlParts.length - 1];
          console.log(lastIndex);

          formData.append("bucket_name", "form-table-bucket");
          formData.append("file_name", lastIndex);
          const replacedStr = lastIndex.replace(/%20/g, " ");
          console.log("replacedStr", replacedStr);
          // setTimeout(function () {
          //   handleFetchResponse(replacedStr, url)
          // }, 30000);
          handleFetchResponse(replacedStr, url);
        })
        .catch(function (error) {
          setLoading(false);
          props.overlayLoader({
            status: false,
            loadingMessage: "",
          });
          EventBus.publish("error", "Error uploading file", error);
          console.error("Error:", error);
          console.log(error);
        });
    } catch (error) {
      setLoading(false);
      props.overlayLoader({
        status: false,
        loadingMessage: "",
      });
      console.error("Error:", error);
      EventBus.publish("error", "Error uploading file", error);
    }
    //       if(file.type == "application/pdf"){
    //   console.log('event', event)
    //
    //
    // }else{
    //   setFile(file);
    //   const reader = new FileReader();
    //   reader.onloadend = () => {
    //     setPreviewUrl(reader.result);
    //   };
    //   reader.readAsDataURL(file);
    // }
  };

  // const handleFetchResponse = (event, url) =>{
  //             try{
  //               axios.post('https://d6psye3q4m.execute-api.us-east-2.amazonaws.com/default/getFile', {
  //                 headers: {
  //                   'Access-Control-Allow-Origin': '*',
  //                   mode: 'no-cors',
  //                 },
  //                 bucket_name: 'form-table-bucket',
  //                 file_name: event
  //               })
  //               .then(function (response) {
  //                 setOcrData(response.data)
  //                 // extractGeometricValues(response.data)
  //                 console.log(response);
  //                 handleUploadtoBackend(response, event, url)
  //               })
  //               .catch(function (error) {
  //                 setLoading(false);
  //                 props.overlayLoader({
  //                   status: false,
  //                   loadingMessage: "",
  //                 })
  //                 EventBus.publish("error", 'Error uploading file', error)
  //                 console.error('Error:', error);
  //                 console.log(error);
  //               });
  //             } catch (error) {
  //               setLoading(false);
  //               props.overlayLoader({
  //                 status: false,
  //                 loadingMessage: "",
  //               })
  //               console.error('Error:', error);
  //               EventBus.publish("error", 'Error uploading file', error)
  //             }
  // }

  const handleFetchResponse = (event, url) => {
    console.log('handleFetchResponse', event, url);
    try {
      const apiUrl = "api/document/backgroundProcessing";
      const requestData = {
        bucket_name: "form-table-bucket",
        name: event,
        url: url
      };
      const headers = {
        Authorization: localStorage.getItem("token"),
        "Content-Type": "application/json", // Assuming you're sending JSON data
      };
      axios
        .post(apiUrl, requestData, { headers })
        .then(function (response) {
          console.log('response', response);
          setLoading(false);
          closeModal();
          props.overlayLoader({
            status: false,
            loadingMessage: "",
          });
        })
        .catch(function (error) {
          setLoading(false);
          props.overlayLoader({
            status: false,
            loadingMessage: "",
          });
          EventBus.publish("error", "Error uploading file", error);
          console.error("Error:", error);
          console.log(error);
        });
    } catch (error) {
      setLoading(false);
      props.overlayLoader({
        status: false,
        loadingMessage: "",
      });
      console.error("Error:", error);
      EventBus.publish("error", "Error uploading file", error);
    }
  };

  const handleUploadtoBackend = (event, fileName, url) => {
    console.log("url in documentdata", url, fileName);
    try {
      const apiUrl =
        "https://documentai-dev.akru.co/api/document/updateDocumentData";
      const requestData = {
        metaData: event.data.metaData,
        final_map: event.data.final_map,
        raw_text: event.data.raw_text,
        table: event.data.table,
        fileName: fileName,
        query_results: event.data.query_results,
        url: url,
      };
      const headers = {
        Authorization: localStorage.getItem("token"),
        "Content-Type": "application/json", // Assuming you're sending JSON data
      };
      axios
        .post(apiUrl, requestData, { headers })
        .then(function (response) {
          setLoading(false);
          closeModal();
          props.overlayLoader({
            status: false,
            loadingMessage: "",
          });
          // extractGeometricValues(response.data)
          console.log(response);
        })
        .catch(function (error) {
          setLoading(false);
          props.overlayLoader({
            status: false,
            loadingMessage: "",
          });
          EventBus.publish("error", "Error uploading file", error);
          console.error("Error:", error);
          console.log(error);
        });
    } catch (error) {
      setLoading(false);
      props.overlayLoader({
        status: false,
        loadingMessage: "",
      });
      console.error("Error:", error);
      EventBus.publish("error", "Error uploading file", error);
    }
  };

  //   const extractGeometricValues = async (data) => {
  //   const finalMap = data.final_map
  //   const updatedKeys = data.updated_keys
  //
  //   const metaData = data.metaData
  //   const matchData = () => {
  //     // Assuming metaData.Blocks contains the array of objects
  //     const blocks = metaData.Blocks;
  //
  //     const matches = Object.keys(finalMap).map(key => {
  //       // Attempt to find a block where the Text includes the key or is exactly the key
  //       // Note: Adjust the logic here if the key matching needs to be more flexible
  //       const entry = blocks.find(block => block.Text && (block.Text.includes(key) || block.Text === key));
  //
  //       if (entry) {
  //         // Destructuring to get the needed properties from the entry
  //         const { BlockType, Confidence, Text, Geometry } = entry;
  //         return { BlockType, Confidence, Text, Geometry };
  //       }
  //       return null;
  //     }).filter(entry => entry !== null); // Filter out any null entries
  //
  //     setMatchedData(matches);
  //   };
  //
  //   matchData();
  // }

  return (
    <div className="bg-black bg-opacity-60  absolute inset-0 z-30">
      <div className="flex justify-center  mt-[80px] lg-mt-0 items-start lg:items-center ">
        <div className="bg-white w-[70%] xl:w-[50%] p-[32px] rounded-lg ">
          <div className="flex flex-col xl:flex-row items-center justify-between">
            <div className="pb-[14px] xl:pb-0">
              <p className="text-[#191D23] font-medium text-[14px] xl:text-xl">
                Upload Document
              </p>
            </div>
            <div className="flex items-center gap-2">
              <div className="border border-[#CCC] bg-[#004761] rounded-[5px]">
                <button className="py-[8px] xl:py-[12px] px-[20px] xl:px-[30px] text-white text-xs font-normal">
                  Invoice
                </button>
              </div>
              <div className="border border-[#CCC] hover:bg-[#004761] bg-white rounded-[5px] mx-0 xl:mx-2.5">
                <button disabled className="py-[8px] xl:py-[12px] px-[20px] xl:px-[30px] text-[#666] hover:text-white text-xs font-normal cursor-not-allowed">
                  G702
                </button>
              </div>
              <div className="border border-[#CCC] hover:bg-[#004761] bg-white rounded-[5px]">
                <button disabled className="py-[8px] xl:py-[12px] px-[20px] xl:px-[30px] text-[#666] hover:text-white text-xs font-normal cursor-not-allowed">
                  Receipt
                </button>
              </div>
              <div className="border border-[#CCC] hover:bg-[#004761] bg-white rounded-[5px]">
                <button onClick={()=>closeModal()} className="py-[8px] xl:py-[12px] px-[20px] xl:px-[30px] text-[#666] hover:text-white text-xs font-normal">
                  Close
                </button>
              </div>
            </div>
          </div>

          {loading ? (
            <div className="border-[2px] border-dashed border-dashed-[#CCC] rounded-lg py-[56px] px-[48px] my-[38px] flex flex-col items-center">
              <div className=" flex items-center justify-center">
                <div role="status">
                  <svg
                    aria-hidden="true"
                    class="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
            </div>
          ) : (
            <div className="border-[2px] border-dashed border-dashed-[#CCC] rounded-lg py-[56px] px-[48px] my-[38px] flex flex-col items-center">
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="48"
                  height="48"
                  viewBox="0 0 48 48"
                  fill="none"
                >
                  <path
                    d="M24 24V42M24 24L19 28M24 24L29 28M10.068 18.234C8.17647 18.71 6.5238 19.8603 5.42055 21.4689C4.3173 23.0775 3.83941 25.0335 4.07666 26.9696C4.31391 28.9056 5.24997 30.6884 6.70898 31.983C8.16798 33.2776 10.0495 33.9948 12 34H14"
                    stroke="#004761"
                    stroke-width="4"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M31.6601 14.276C30.9765 11.5691 29.2892 9.22347 26.9403 7.7145C24.5914 6.20553 21.7566 5.64617 19.0106 6.14979C16.2646 6.65342 13.8128 8.18235 12.1522 10.4267C10.4916 12.671 9.7466 15.4627 10.0681 18.236C10.0681 18.236 10.3741 20 11.0001 21"
                    stroke="#052460"
                    stroke-width="4"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M34 34C35.4121 33.999 36.8079 33.699 38.0957 33.1197C39.3835 32.5404 40.534 31.6949 41.4715 30.6389C42.4089 29.583 43.1121 28.3404 43.5348 26.9931C43.9574 25.6457 44.0899 24.2241 43.9235 22.8219C43.7572 21.4196 43.2957 20.0685 42.5696 18.8575C41.8434 17.6464 40.869 16.6029 39.7105 15.7956C38.5519 14.9882 37.2356 14.4354 35.848 14.1735C34.4604 13.9116 33.0331 13.9465 31.66 14.276L29 15"
                    stroke="#052460"
                    stroke-width="4"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div className="py-[36px] w-full xl:w-[400px]">
                <p className="text-[16px] xl:text-[20px] text-[#666] font-normal text-center">
                  Browse and chose the files you want to upload from your
                  computer
                </p>
              </div>
              {/* upload file name */}
              <div id="fileList" class="mb-2 text-sm text-[#666]"></div>
              <div class="relative">
                <label
                  htmlFor="multiple_files"
                  className="block w-full bg-[#004761] text-white rounded-lg cursor-pointer p-2 px-4"
                >
                  <input
                    id="multiple_files"
                    type="file"
                    multiple
                    className="hidden"
                  />
                  <span className="text-lg">+</span>
                </label>
              </div>
            </div>
          )}

          <div className="flex justify-center ">
            <div className="border border-[#CCC] bg-[#004761]  rounded-[5px]">
              <button
                onClick={submitFile}
                className="py-[12px] px-[30px] text-white text-xs font-normal"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ Settings, Auth }) => {
  let {} = Settings;
  return {};
};

const mapDispatchToProps = {
  overlayLoader,
};
export default connect(mapStateToProps, mapDispatchToProps)(UploadDocuments);
