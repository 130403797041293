import React, { useState, useEffect } from "react";
import { overlayLoader } from "../../store/actions/Settings";
import { connect } from "react-redux";
import axios from "axios";
import EventBus from "eventing-bus";
import Select from "react-select";

const options = [
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
  // { value: "4", label: "4" },
];

// const users = [
//   { email: "dshakeel@gmail.com", name: "danial" },
//   { email: "sanwar@gmail.com", name: "sheeraz" },
//   { email: "dani@gmail.com", name: "dani" },
//   { email: "mohsin@gmail.com", name: "mohsin" },
// ];

const PolicyLevel = (props) => {
  const [singledocument, setSingleDocument] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [allPolicies, setAllPolicies] = useState(null);
  const [users, setAllUsers] = useState(null);
  const [formData, setFormData] = useState({
    min1: "",
    max1: "",
    min2: "",
    max2: "",
    min3: "",
    max3: "",
    min4: "",
    max4: "",
    level2approval: "",
    level3approval: "",
    level4approval: "",

    level2approver1: "",
    level2approver2: "",
    level2approver3: "",

    level3approver1: "",
    level3approver2: "",
    level3approver3: "",

    level4approver1: "",
    level4approver2: "",
    level4approver3: "",
  });

  useEffect(() => {
    getAllPolicies();
    getAllUsers();
  }, []);

  const getAllPolicies = () => {
    axios
      .get("/api/user/getAllPolicies", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          mode: "no-cors",
          Authorization: `${localStorage.getItem("token")}`,
        },
      })
      .then(function (response) {
        console.log("response api getAllPolicies", response.data.body);
        setAllPolicies(response.data.body);
      })
      .catch(function (error) {
        console.error("Error:", error);
      });
  };

  const getAllUsers = () => {
    axios
      .get("/api/user/getAllUsers", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          mode: "no-cors",
          Authorization: `${localStorage.getItem("token")}`,
        },
      })
      .then(function (response) {
        console.log("response api getAlgetAllUsers", response.data.body);
        if(response.data.body.length > 0){
          setAllUsers(response.data.body);
        }else{
          console.log('else setAllUsers');
          const defaultUser = [
            { email: localStorage.getItem('userEmail'), name: localStorage.getItem('userName') },
          ];
          setAllUsers(defaultUser);
        }
      })
      .catch(function (error) {
        console.error("Error:", error);
      });
  };

  const createPolicy = async () => {
    console.log("formData", formData, selectedOption);
    const level1Id = allPolicies.find((result) => result.level == 1);
    const level2Id = allPolicies.find((result) => result.level == 2);
    const level3Id = allPolicies.find((result) => result.level == 3);

    console.log("level1Id", level1Id, level2Id, level3Id);

    const level1 = {
      low: 0,
      high: 500,
      level: 1,
      autoApproval: true,
      numberOfApproval: 0,
      approvedBy: "",
    };

    const level2 = {
      low: formData.min2,
      high: formData.max2,
      level: 2,
      autoApproval: false,
      numberOfApproval: formData.level2approval,
      approvedBy: [formData.level2approver1, formData.level2approver2],
    };

    const level3 = {
      low: formData.min3,
      high: formData.max3,
      level: 3,
      autoApproval: false,
      numberOfApproval: formData.level3approval,
      approvedBy: [
        formData.level3approver1,
        formData.level3approver2,
        formData.level3approver3,
      ],
    };

    if (level1Id) {
      level1.policyId = level1Id._id;
    }
    if (level2Id) {
      level2.policyId = level2Id._id;
    }
    if (level3Id) {
      level3.policyId = level3Id._id;
    }

    const apiUrl = "/api/user/createApprovalPolicy";
    const headers = {
      Authorization: localStorage.getItem("token"),
      "Content-Type": "application/json",
    };

    const postPolicy = async (data) => {
      try {
        const response = await axios.post(apiUrl, data, { headers });
        console.log("Policy Added", response);
      } catch (error) {
        EventBus.publish("error", error);
        console.error("Error in policy creation:", error);
        props.overlayLoader({
          status: false,
          loadingMessage: "",
        });
      }
    };

    // Make API calls based on the selected option
    if (selectedOption.value >= 1) {
      await postPolicy(level1);
      EventBus.publish("success", "Policy 1 Added");
    }
    if (selectedOption.value >= 2) {
      await postPolicy(level2);
      EventBus.publish("success", "Policy 2 Added");
    }
    if (selectedOption.value >= 3) {
      await postPolicy(level3);
      EventBus.publish("success", "Policy 3 Added");
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    console.log(name, value);
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    console.log(`${name}: ${value}`);
  };

  return (
    <div>
      <div className="container-class">
        <div className="flex items-center justify-between  pt-[50px] pb-[20px]">
          <div>
            <p className="text-[#004761] font-semibold `text-[14px] xl:text-base">
              Approval Policies
            </p>
          </div>
          {/* buttons */}
          <div className="border border-[#004761] bg-[#004761] rounded-[5px] mx-2.5">
            <button
              onClick={createPolicy}
              className="py-[12px] px-12 text-white text-xs font-normal"
            >
              Save
            </button>
          </div>
        </div>
        <div className="border-b border-b-[#ccc] w-full">
          <hr className="border  border-[#052460] w-[100px]" />
        </div>

        {/* policy-level */}
        <div className="flex items-center justify-between pt-[35px] pb-[18px]">
          <div className="flex items-center">
            <div className="text-[10px] xl:text-sm text-black font-medium">
              <p>Policy Levels</p>
            </div>
            <div className="flex items-center pl-[100px]">
              <div className="text-[10px] xl:text-sm text-black font-medium pr-[22px]">
                <p>Policy Basis</p>
              </div>
              <div className="text-[10px] xl:text-sm text-black font-medium">
                <form className="w-full xl:w-[200px] relative">
                  <Select
                    defaultValue={selectedOption}
                    onChange={setSelectedOption}
                    options={options}
                  />
                  <div className="absolute inset-y-0 right-0 flex items-center pr-6 pointer-events-none">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="8"
                      height="5"
                      viewBox="0 0 8 5"
                      fill="none"
                    >
                      <path
                        d="M7.98647 0.124047C7.97318 0.0873128 7.95092 0.0559599 7.92247 0.0338999C7.89401 0.0118399 7.86063 4.97357e-05 7.82647 3.68173e-07H0.174242C0.140007 -7.57266e-05 0.106514 0.0116453 0.0779611 0.033695C0.0494081 0.0557447 0.027065 0.0871421 0.0137317 0.123952C0.000398509 0.160762 -0.00333174 0.201347 0.00300863 0.240621C0.00934901 0.279896 0.0254779 0.316112 0.0493739 0.344731L3.8754 4.9385C3.89161 4.95796 3.91099 4.97343 3.93241 4.98399C3.95383 4.99456 3.97684 5 4.0001 5C4.02335 5 4.04636 4.99456 4.06778 4.98399C4.0892 4.97343 4.10858 4.95796 4.12479 4.9385L7.95082 0.344528C7.97463 0.315913 7.9907 0.27974 7.99701 0.240527C8.00332 0.201314 7.99959 0.160801 7.98629 0.124047H7.98647Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                </form>
              </div>
            </div>
          </div>
          {/*}
          <div className="flex items-center">
            <div className="pr-2.5">
              <button className="bg-[#81C784] text-white py-[6px] px-5 outline-none rounded-[5px] text-xs font-mediums">
                Add Level
              </button>
            </div>
            <div>
              <button className="bg-white text-[#EF5350] border border-[#EF5350]  py-[6px] px-5 outline-none rounded-[5px] text-xs font-mediums">
                Remove Level
              </button>
            </div>
          </div>
        */}
        </div>
        {console.log("selectedOption", selectedOption)}
        {selectedOption && selectedOption.value == 1 ? (
          <>
            {" "}
            <div className="py-[18px]">
              <div className="bg-[#F2F4F7] rounded-[5px] py-5 px-7 flex items-center">
                <div className="pr-[39px]">
                  <p className="text-[#666] text-base font-medium">Level 1</p>
                </div>
                <div className="flex items-center">
                  <input
                    type="number"
                    className="bg-transparent border border-[#ccc] rounded-[5px] w-[60px] py-[6px] px-2 text-[#666] text-xs font-medium"
                    name="min1"
                    disabled
                    value="0"
                    onChange={handleChange}
                  />
                  <p className="text-[#666] text-sm font-medium px-2.5">to</p>
                  <input
                    type="number"
                    className="bg-transparent border border-[#ccc] rounded-[5px] w-[60px] py-[6px] px-2 text-[#666] text-xs font-medium"
                    disabled
                    name="max1"
                    value="500"
                    onChange={handleChange}
                  />
                  <span className="px-5">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="19"
                      height="8"
                      viewBox="0 0 19 8"
                      fill="none"
                    >
                      <path
                        d="M18.7823 3.54332L18.7816 3.54271L14.9036 0.187869C14.613 -0.0634537 14.1431 -0.0625185 13.8539 0.190062C13.5648 0.442611 13.5659 0.851095 13.8564 1.10245L16.4602 3.35484H0.742187C0.332277 3.35484 0 3.64368 0 4C0 4.35632 0.332277 4.64516 0.742187 4.64516H16.4601L13.8564 6.89755C13.5659 7.1489 13.5648 7.55739 13.854 7.80994C14.1432 8.06255 14.6131 8.06342 14.9036 7.81213L18.7817 4.45729L18.7823 4.45668C19.073 4.20448 19.0721 3.79468 18.7823 3.54332Z"
                        fill="black"
                      />
                    </svg>
                  </span>
                  <p className="text-[#666] text-sm font-medium ">
                    Required Approvals
                  </p>
                  <div className="px-[30px]">
                    <form className="w-[88px] relative">
                      <select
                        disabled
                        className="border border-[#ccc] bg-transparent text-sm rounded-[5px] text-[#666] outline-none py-1.5 px-8 appearance-none pr-8"
                      >
                        <option disabled selected>
                          0
                        </option>
                      </select>
                      <div className="absolute inset-y-0 right-0 flex items-center pr-5 pointer-events-none">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="8"
                          height="5"
                          viewBox="0 0 8 5"
                          fill="none"
                        >
                          <path
                            d="M7.98647 0.124047C7.97318 0.0873128 7.95092 0.0559599 7.92247 0.0338999C7.89401 0.0118399 7.86063 4.97357e-05 7.82647 3.68173e-07H0.174242C0.140007 -7.57266e-05 0.106514 0.0116453 0.0779611 0.033695C0.0494081 0.0557447 0.027065 0.0871421 0.0137317 0.123952C0.000398509 0.160762 -0.00333174 0.201347 0.00300863 0.240621C0.00934901 0.279896 0.0254779 0.316112 0.0493739 0.344731L3.8754 4.9385C3.89161 4.95796 3.91099 4.97343 3.93241 4.98399C3.95383 4.99456 3.97684 5 4.0001 5C4.02335 5 4.04636 4.99456 4.06778 4.98399C4.0892 4.97343 4.10858 4.95796 4.12479 4.9385L7.95082 0.344528C7.97463 0.315913 7.9907 0.27974 7.99701 0.240527C8.00332 0.201314 7.99959 0.160801 7.98629 0.124047H7.98647Z"
                            fill="#052460"
                          />
                        </svg>
                      </div>
                    </form>
                  </div>
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="19"
                      height="8"
                      viewBox="0 0 19 8"
                      fill="none"
                    >
                      <path
                        d="M18.7823 3.54332L18.7816 3.54271L14.9036 0.187869C14.613 -0.0634537 14.1431 -0.0625185 13.8539 0.190062C13.5648 0.442611 13.5659 0.851095 13.8564 1.10245L16.4602 3.35484H0.742187C0.332277 3.35484 0 3.64368 0 4C0 4.35632 0.332277 4.64516 0.742187 4.64516H16.4601L13.8564 6.89755C13.5659 7.1489 13.5648 7.55739 13.854 7.80994C14.1432 8.06255 14.6131 8.06342 14.9036 7.81213L18.7817 4.45729L18.7823 4.45668C19.073 4.20448 19.0721 3.79468 18.7823 3.54332Z"
                        fill="black"
                      />
                    </svg>
                  </span>
                  <p className="text-sm text-[#666] font-normal pl-5">
                    Auto <span className="text-[#81C784]">Approved</span>
                  </p>
                </div>
              </div>
            </div>
          </>
        ) : selectedOption && selectedOption.value == 2 ? (
          <>
            <div className="py-[18px]">
              <div className="bg-[#F2F4F7] rounded-[5px] py-5 px-7 flex items-center">
                <div className="pr-[39px]">
                  <p className="text-[#666] text-base font-medium">Level 1</p>
                </div>
                <div className="flex items-center">
                  <input
                    type="number"
                    className="bg-transparent border border-[#ccc] rounded-[5px] w-[60px] py-[6px] px-2 text-[#666] text-xs font-medium"
                    name="min1"
                    disabled
                    value="0"
                    onChange={handleChange}
                  />
                  <p className="text-[#666] text-sm font-medium px-2.5">to</p>
                  <input
                    type="number"
                    className="bg-transparent border border-[#ccc] rounded-[5px] w-[60px] py-[6px] px-2 text-[#666] text-xs font-medium"
                    disabled
                    name="max1"
                    value="500"
                    onChange={handleChange}
                  />
                  <span className="px-5">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="19"
                      height="8"
                      viewBox="0 0 19 8"
                      fill="none"
                    >
                      <path
                        d="M18.7823 3.54332L18.7816 3.54271L14.9036 0.187869C14.613 -0.0634537 14.1431 -0.0625185 13.8539 0.190062C13.5648 0.442611 13.5659 0.851095 13.8564 1.10245L16.4602 3.35484H0.742187C0.332277 3.35484 0 3.64368 0 4C0 4.35632 0.332277 4.64516 0.742187 4.64516H16.4601L13.8564 6.89755C13.5659 7.1489 13.5648 7.55739 13.854 7.80994C14.1432 8.06255 14.6131 8.06342 14.9036 7.81213L18.7817 4.45729L18.7823 4.45668C19.073 4.20448 19.0721 3.79468 18.7823 3.54332Z"
                        fill="black"
                      />
                    </svg>
                  </span>
                  <p className="text-[#666] text-sm font-medium ">
                    Required Approvals
                  </p>
                  <div className="px-[30px]">
                    <form className="w-[88px] relative">
                      <select
                        disabled
                        className="border border-[#ccc] bg-transparent text-sm rounded-[5px] text-[#666] outline-none py-1.5 px-8 appearance-none pr-8"
                      >
                        <option disabled selected>
                          0
                        </option>
                      </select>
                      <div className="absolute inset-y-0 right-0 flex items-center pr-5 pointer-events-none">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="8"
                          height="5"
                          viewBox="0 0 8 5"
                          fill="none"
                        >
                          <path
                            d="M7.98647 0.124047C7.97318 0.0873128 7.95092 0.0559599 7.92247 0.0338999C7.89401 0.0118399 7.86063 4.97357e-05 7.82647 3.68173e-07H0.174242C0.140007 -7.57266e-05 0.106514 0.0116453 0.0779611 0.033695C0.0494081 0.0557447 0.027065 0.0871421 0.0137317 0.123952C0.000398509 0.160762 -0.00333174 0.201347 0.00300863 0.240621C0.00934901 0.279896 0.0254779 0.316112 0.0493739 0.344731L3.8754 4.9385C3.89161 4.95796 3.91099 4.97343 3.93241 4.98399C3.95383 4.99456 3.97684 5 4.0001 5C4.02335 5 4.04636 4.99456 4.06778 4.98399C4.0892 4.97343 4.10858 4.95796 4.12479 4.9385L7.95082 0.344528C7.97463 0.315913 7.9907 0.27974 7.99701 0.240527C8.00332 0.201314 7.99959 0.160801 7.98629 0.124047H7.98647Z"
                            fill="#052460"
                          />
                        </svg>
                      </div>
                    </form>
                  </div>
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="19"
                      height="8"
                      viewBox="0 0 19 8"
                      fill="none"
                    >
                      <path
                        d="M18.7823 3.54332L18.7816 3.54271L14.9036 0.187869C14.613 -0.0634537 14.1431 -0.0625185 13.8539 0.190062C13.5648 0.442611 13.5659 0.851095 13.8564 1.10245L16.4602 3.35484H0.742187C0.332277 3.35484 0 3.64368 0 4C0 4.35632 0.332277 4.64516 0.742187 4.64516H16.4601L13.8564 6.89755C13.5659 7.1489 13.5648 7.55739 13.854 7.80994C14.1432 8.06255 14.6131 8.06342 14.9036 7.81213L18.7817 4.45729L18.7823 4.45668C19.073 4.20448 19.0721 3.79468 18.7823 3.54332Z"
                        fill="black"
                      />
                    </svg>
                  </span>
                  <p className="text-sm text-[#666] font-normal pl-5">
                    Auto <span className="text-[#81C784]">Approved</span>
                  </p>
                </div>
              </div>
            </div>
            <div className="py-[18px]">
              <div className=" px-7 flex items-center">
                <div className="pr-[39px]">
                  <p className="text-[#666] text-base font-medium">Level 2</p>
                </div>
                <div className="flex items-center">
                  <input
                    type="number"
                    className="bg-transparent border border-[#ccc] rounded-[5px] w-[60px] py-[6px] px-2 text-[#666] text-xs font-medium"
                    name="min2"
                    value={formData.min2}
                    onChange={handleChange}
                  />
                  <p className="text-[#666] text-sm font-medium px-2.5">to</p>
                  <input
                    type="number"
                    className="bg-transparent border border-[#ccc] rounded-[5px] w-[60px] py-[6px] px-2 text-[#666] text-xs font-medium"
                    name="max2"
                    value={formData.max2}
                    onChange={handleChange}
                  />
                  <span className="px-5">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="19"
                      height="8"
                      viewBox="0 0 19 8"
                      fill="none"
                    >
                      <path
                        d="M18.7823 3.54332L18.7816 3.54271L14.9036 0.187869C14.613 -0.0634537 14.1431 -0.0625185 13.8539 0.190062C13.5648 0.442611 13.5659 0.851095 13.8564 1.10245L16.4602 3.35484H0.742187C0.332277 3.35484 0 3.64368 0 4C0 4.35632 0.332277 4.64516 0.742187 4.64516H16.4601L13.8564 6.89755C13.5659 7.1489 13.5648 7.55739 13.854 7.80994C14.1432 8.06255 14.6131 8.06342 14.9036 7.81213L18.7817 4.45729L18.7823 4.45668C19.073 4.20448 19.0721 3.79468 18.7823 3.54332Z"
                        fill="black"
                      />
                    </svg>
                  </span>
                  <p className="text-[#666] text-sm font-medium ">
                    Required Approvals
                  </p>
                  <div className="px-[30px]">
                    <form className="w-[88px] relative">
                      <select
                        name="level2approval"
                        value={formData.level2approval}
                        onChange={handleChange}
                        className="border border-[#ccc] bg-transparent text-sm rounded-[5px] text-[#666] outline-none py-1.5 px-8 appearance-none pr-8"
                      >
                        <option selected>0</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                      </select>
                      <div className="absolute inset-y-0 right-0 flex items-center pr-5 pointer-events-none">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="8"
                          height="5"
                          viewBox="0 0 8 5"
                          fill="none"
                        >
                          <path
                            d="M7.98647 0.124047C7.97318 0.0873128 7.95092 0.0559599 7.92247 0.0338999C7.89401 0.0118399 7.86063 4.97357e-05 7.82647 3.68173e-07H0.174242C0.140007 -7.57266e-05 0.106514 0.0116453 0.0779611 0.033695C0.0494081 0.0557447 0.027065 0.0871421 0.0137317 0.123952C0.000398509 0.160762 -0.00333174 0.201347 0.00300863 0.240621C0.00934901 0.279896 0.0254779 0.316112 0.0493739 0.344731L3.8754 4.9385C3.89161 4.95796 3.91099 4.97343 3.93241 4.98399C3.95383 4.99456 3.97684 5 4.0001 5C4.02335 5 4.04636 4.99456 4.06778 4.98399C4.0892 4.97343 4.10858 4.95796 4.12479 4.9385L7.95082 0.344528C7.97463 0.315913 7.9907 0.27974 7.99701 0.240527C8.00332 0.201314 7.99959 0.160801 7.98629 0.124047H7.98647Z"
                            fill="#052460"
                          />
                        </svg>
                      </div>
                    </form>
                  </div>
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="19"
                      height="8"
                      viewBox="0 0 19 8"
                      fill="none"
                    >
                      <path
                        d="M18.7823 3.54332L18.7816 3.54271L14.9036 0.187869C14.613 -0.0634537 14.1431 -0.0625185 13.8539 0.190062C13.5648 0.442611 13.5659 0.851095 13.8564 1.10245L16.4602 3.35484H0.742187C0.332277 3.35484 0 3.64368 0 4C0 4.35632 0.332277 4.64516 0.742187 4.64516H16.4601L13.8564 6.89755C13.5659 7.1489 13.5648 7.55739 13.854 7.80994C14.1432 8.06255 14.6131 8.06342 14.9036 7.81213L18.7817 4.45729L18.7823 4.45668C19.073 4.20448 19.0721 3.79468 18.7823 3.54332Z"
                        fill="black"
                      />
                    </svg>
                  </span>

                  {formData.level2approval >= 1 && (
                    <>
                      <p className="text-sm text-[#666] font-normal pl-5">
                        Approver 1
                      </p>
                      <div className="px-[30px]">
                        <form className="w-[200px] relative">
                          <select
                            className="bg-[#F2F4F7] border border-[#ccc] text-sm rounded-[5px] text-[#666] outline-none py-1.5 px-8 appearance-none pr-8"
                            defaultValue={users[0].email}
                            name="level2approver1"
                            onChange={handleChange}
                          >
                            {users.map((user, index) => (
                              <React.Fragment key={index}>
                                <option value={user._id}>{user.email}</option>
                              </React.Fragment>
                            ))}
                          </select>
                          <div className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="8"
                              height="5"
                              viewBox="0 0 8 5"
                              fill="none"
                            >
                              <path
                                d="M7.98647 0.124047C7.97318 0.0873128 7.95092 0.0559599 7.92247 0.0338999C7.89401 0.0118399 7.86063 4.97357e-05 7.82647 3.68173e-07H0.174242C0.140007 -7.57266e-05 0.106514 0.0116453 0.0779611 0.033695C0.0494081 0.0557447 0.027065 0.0871421 0.0137317 0.123952C0.000398509 0.160762 -0.00333174 0.201347 0.00300863 0.240621C0.00934901 0.279896 0.0254779 0.316112 0.0493739 0.344731L3.8754 4.9385C3.89161 4.95796 3.91099 4.97343 3.93241 4.98399C3.95383 4.99456 3.97684 5 4.0001 5C4.02335 5 4.04636 4.99456 4.06778 4.98399C4.0892 4.97343 4.10858 4.95796 4.12479 4.9385L7.95082 0.344528C7.97463 0.315913 7.9907 0.27974 7.99701 0.240527C8.00332 0.201314 7.99959 0.160801 7.98629 0.124047H7.98647Z"
                                fill="#052460"
                              />
                            </svg>
                          </div>
                        </form>
                      </div>
                    </>
                  )}

                  {formData.level2approval >= 2 && (
                    <>
                      <p className="text-sm text-[#666] font-normal pl-5">
                        Approver 2
                      </p>
                      <div className="px-[30px]">
                        <form className="w-[200px] relative">
                          <select
                            className="bg-[#F2F4F7] border border-[#ccc] text-sm rounded-[5px] text-[#666] outline-none py-1.5 px-8 appearance-none pr-8"
                            defaultValue={users[0].email}
                            name="level2approver2"
                            onChange={handleChange}
                          >
                            {users.map((user, index) => (
                              <React.Fragment key={index}>
                                <option value={user._id}>{user.email}</option>
                              </React.Fragment>
                            ))}
                          </select>
                          <div className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="8"
                              height="5"
                              viewBox="0 0 8 5"
                              fill="none"
                            >
                              <path
                                d="M7.98647 0.124047C7.97318 0.0873128 7.95092 0.0559599 7.92247 0.0338999C7.89401 0.0118399 7.86063 4.97357e-05 7.82647 3.68173e-07H0.174242C0.140007 -7.57266e-05 0.106514 0.0116453 0.0779611 0.033695C0.0494081 0.0557447 0.027065 0.0871421 0.0137317 0.123952C0.000398509 0.160762 -0.00333174 0.201347 0.00300863 0.240621C0.00934901 0.279896 0.0254779 0.316112 0.0493739 0.344731L3.8754 4.9385C3.89161 4.95796 3.91099 4.97343 3.93241 4.98399C3.95383 4.99456 3.97684 5 4.0001 5C4.02335 5 4.04636 4.99456 4.06778 4.98399C4.0892 4.97343 4.10858 4.95796 4.12479 4.9385L7.95082 0.344528C7.97463 0.315913 7.9907 0.27974 7.99701 0.240527C8.00332 0.201314 7.99959 0.160801 7.98629 0.124047H7.98647Z"
                                fill="#052460"
                              />
                            </svg>
                          </div>
                        </form>
                      </div>
                    </>
                  )}

                  {formData.level2approval >= 3 && (
                    <>
                      <p className="text-sm text-[#666] font-normal pl-5">
                        Approver 3
                      </p>
                      <div className="px-[30px]">
                        <form className="w-[200px] relative">
                          <select
                            className="bg-[#F2F4F7] border border-[#ccc] text-sm rounded-[5px] text-[#666] outline-none py-1.5 px-8 appearance-none pr-8"
                            defaultValue={users[0].email}
                            name="level2approver3"
                            onChange={handleChange}
                          >
                            {users.map((user, index) => (
                              <React.Fragment key={index}>
                                <option value={user._id}>{user.email}</option>
                              </React.Fragment>
                            ))}
                          </select>
                          <div className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="8"
                              height="5"
                              viewBox="0 0 8 5"
                              fill="none"
                            >
                              <path
                                d="M7.98647 0.124047C7.97318 0.0873128 7.95092 0.0559599 7.92247 0.0338999C7.89401 0.0118399 7.86063 4.97357e-05 7.82647 3.68173e-07H0.174242C0.140007 -7.57266e-05 0.106514 0.0116453 0.0779611 0.033695C0.0494081 0.0557447 0.027065 0.0871421 0.0137317 0.123952C0.000398509 0.160762 -0.00333174 0.201347 0.00300863 0.240621C0.00934901 0.279896 0.0254779 0.316112 0.0493739 0.344731L3.8754 4.9385C3.89161 4.95796 3.91099 4.97343 3.93241 4.98399C3.95383 4.99456 3.97684 5 4.0001 5C4.02335 5 4.04636 4.99456 4.06778 4.98399C4.0892 4.97343 4.10858 4.95796 4.12479 4.9385L7.95082 0.344528C7.97463 0.315913 7.9907 0.27974 7.99701 0.240527C8.00332 0.201314 7.99959 0.160801 7.98629 0.124047H7.98647Z"
                                fill="#052460"
                              />
                            </svg>
                          </div>
                        </form>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </>
        ) : selectedOption && selectedOption.value == 3 ? (
          <>
            <div className="py-[18px]">
              <div className="bg-[#F2F4F7] rounded-[5px] py-5 px-7 flex items-center">
                <div className="pr-[39px]">
                  <p className="text-[#666] text-base font-medium">Level 1</p>
                </div>
                <div className="flex items-center">
                  <input
                    type="number"
                    className="bg-transparent border border-[#ccc] rounded-[5px] w-[60px] py-[6px] px-2 text-[#666] text-xs font-medium"
                    name="min1"
                    value="0"
                    disabled
                    onChange={handleChange}
                  />
                  <p className="text-[#666] text-sm font-medium px-2.5">to</p>
                  <input
                    type="number"
                    className="bg-transparent border border-[#ccc] rounded-[5px] w-[60px] py-[6px] px-2 text-[#666] text-xs font-medium"
                    name="max1"
                    value="500"
                    disabled
                    onChange={handleChange}
                  />
                  <span className="px-5">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="19"
                      height="8"
                      viewBox="0 0 19 8"
                      fill="none"
                    >
                      <path
                        d="M18.7823 3.54332L18.7816 3.54271L14.9036 0.187869C14.613 -0.0634537 14.1431 -0.0625185 13.8539 0.190062C13.5648 0.442611 13.5659 0.851095 13.8564 1.10245L16.4602 3.35484H0.742187C0.332277 3.35484 0 3.64368 0 4C0 4.35632 0.332277 4.64516 0.742187 4.64516H16.4601L13.8564 6.89755C13.5659 7.1489 13.5648 7.55739 13.854 7.80994C14.1432 8.06255 14.6131 8.06342 14.9036 7.81213L18.7817 4.45729L18.7823 4.45668C19.073 4.20448 19.0721 3.79468 18.7823 3.54332Z"
                        fill="black"
                      />
                    </svg>
                  </span>
                  <p className="text-[#666] text-sm font-medium ">
                    Required Approvals
                  </p>
                  <div className="px-[30px]">
                    <form className="w-[88px] relative">
                      <select
                        disabled
                        className="border border-[#ccc] bg-transparent text-sm rounded-[5px] text-[#666] outline-none py-1.5 px-8 appearance-none pr-8"
                      >
                        <option disabled selected>
                          0
                        </option>
                      </select>
                      <div className="absolute inset-y-0 right-0 flex items-center pr-5 pointer-events-none">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="8"
                          height="5"
                          viewBox="0 0 8 5"
                          fill="none"
                        >
                          <path
                            d="M7.98647 0.124047C7.97318 0.0873128 7.95092 0.0559599 7.92247 0.0338999C7.89401 0.0118399 7.86063 4.97357e-05 7.82647 3.68173e-07H0.174242C0.140007 -7.57266e-05 0.106514 0.0116453 0.0779611 0.033695C0.0494081 0.0557447 0.027065 0.0871421 0.0137317 0.123952C0.000398509 0.160762 -0.00333174 0.201347 0.00300863 0.240621C0.00934901 0.279896 0.0254779 0.316112 0.0493739 0.344731L3.8754 4.9385C3.89161 4.95796 3.91099 4.97343 3.93241 4.98399C3.95383 4.99456 3.97684 5 4.0001 5C4.02335 5 4.04636 4.99456 4.06778 4.98399C4.0892 4.97343 4.10858 4.95796 4.12479 4.9385L7.95082 0.344528C7.97463 0.315913 7.9907 0.27974 7.99701 0.240527C8.00332 0.201314 7.99959 0.160801 7.98629 0.124047H7.98647Z"
                            fill="#052460"
                          />
                        </svg>
                      </div>
                    </form>
                  </div>
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="19"
                      height="8"
                      viewBox="0 0 19 8"
                      fill="none"
                    >
                      <path
                        d="M18.7823 3.54332L18.7816 3.54271L14.9036 0.187869C14.613 -0.0634537 14.1431 -0.0625185 13.8539 0.190062C13.5648 0.442611 13.5659 0.851095 13.8564 1.10245L16.4602 3.35484H0.742187C0.332277 3.35484 0 3.64368 0 4C0 4.35632 0.332277 4.64516 0.742187 4.64516H16.4601L13.8564 6.89755C13.5659 7.1489 13.5648 7.55739 13.854 7.80994C14.1432 8.06255 14.6131 8.06342 14.9036 7.81213L18.7817 4.45729L18.7823 4.45668C19.073 4.20448 19.0721 3.79468 18.7823 3.54332Z"
                        fill="black"
                      />
                    </svg>
                  </span>
                  <p className="text-sm text-[#666] font-normal pl-5">
                    Auto <span className="text-[#81C784]">Approved</span>
                  </p>
                </div>
              </div>
            </div>
            <div className="py-[18px]">
              <div className=" px-7 flex items-center">
                <div className="pr-[39px]">
                  <p className="text-[#666] text-base font-medium">Level 2</p>
                </div>
                <div className="flex items-center">
                  <input
                    type="number"
                    className="bg-transparent border border-[#ccc] rounded-[5px] w-[60px] py-[6px] px-2 text-[#666] text-xs font-medium"
                    name="min2"
                    value={formData.min2}
                    onChange={handleChange}
                  />
                  <p className="text-[#666] text-sm font-medium px-2.5">to</p>
                  <input
                    type="number"
                    className="bg-transparent border border-[#ccc] rounded-[5px] w-[60px] py-[6px] px-2 text-[#666] text-xs font-medium"
                    name="max2"
                    value={formData.max2}
                    onChange={handleChange}
                  />
                  <span className="px-5">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="19"
                      height="8"
                      viewBox="0 0 19 8"
                      fill="none"
                    >
                      <path
                        d="M18.7823 3.54332L18.7816 3.54271L14.9036 0.187869C14.613 -0.0634537 14.1431 -0.0625185 13.8539 0.190062C13.5648 0.442611 13.5659 0.851095 13.8564 1.10245L16.4602 3.35484H0.742187C0.332277 3.35484 0 3.64368 0 4C0 4.35632 0.332277 4.64516 0.742187 4.64516H16.4601L13.8564 6.89755C13.5659 7.1489 13.5648 7.55739 13.854 7.80994C14.1432 8.06255 14.6131 8.06342 14.9036 7.81213L18.7817 4.45729L18.7823 4.45668C19.073 4.20448 19.0721 3.79468 18.7823 3.54332Z"
                        fill="black"
                      />
                    </svg>
                  </span>
                  <p className="text-[#666] text-sm font-medium ">
                    Required Approvals
                  </p>
                  <div className="px-[30px]">
                    <form className="w-[88px] relative">
                      <select
                        name="level2approval"
                        value={formData.level2approval}
                        onChange={handleChange}
                        className="border border-[#ccc] bg-transparent text-sm rounded-[5px] text-[#666] outline-none py-1.5 px-8 appearance-none pr-8"
                      >
                        <option selected>0</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                      </select>
                      <div className="absolute inset-y-0 right-0 flex items-center pr-5 pointer-events-none">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="8"
                          height="5"
                          viewBox="0 0 8 5"
                          fill="none"
                        >
                          <path
                            d="M7.98647 0.124047C7.97318 0.0873128 7.95092 0.0559599 7.92247 0.0338999C7.89401 0.0118399 7.86063 4.97357e-05 7.82647 3.68173e-07H0.174242C0.140007 -7.57266e-05 0.106514 0.0116453 0.0779611 0.033695C0.0494081 0.0557447 0.027065 0.0871421 0.0137317 0.123952C0.000398509 0.160762 -0.00333174 0.201347 0.00300863 0.240621C0.00934901 0.279896 0.0254779 0.316112 0.0493739 0.344731L3.8754 4.9385C3.89161 4.95796 3.91099 4.97343 3.93241 4.98399C3.95383 4.99456 3.97684 5 4.0001 5C4.02335 5 4.04636 4.99456 4.06778 4.98399C4.0892 4.97343 4.10858 4.95796 4.12479 4.9385L7.95082 0.344528C7.97463 0.315913 7.9907 0.27974 7.99701 0.240527C8.00332 0.201314 7.99959 0.160801 7.98629 0.124047H7.98647Z"
                            fill="#052460"
                          />
                        </svg>
                      </div>
                    </form>
                  </div>
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="19"
                      height="8"
                      viewBox="0 0 19 8"
                      fill="none"
                    >
                      <path
                        d="M18.7823 3.54332L18.7816 3.54271L14.9036 0.187869C14.613 -0.0634537 14.1431 -0.0625185 13.8539 0.190062C13.5648 0.442611 13.5659 0.851095 13.8564 1.10245L16.4602 3.35484H0.742187C0.332277 3.35484 0 3.64368 0 4C0 4.35632 0.332277 4.64516 0.742187 4.64516H16.4601L13.8564 6.89755C13.5659 7.1489 13.5648 7.55739 13.854 7.80994C14.1432 8.06255 14.6131 8.06342 14.9036 7.81213L18.7817 4.45729L18.7823 4.45668C19.073 4.20448 19.0721 3.79468 18.7823 3.54332Z"
                        fill="black"
                      />
                    </svg>
                  </span>

                  {formData.level2approval >= 1 && (
                    <>
                      <p className="text-sm text-[#666] font-normal pl-5">
                        Approver 1
                      </p>
                      <div className="px-[30px]">
                        <form className="w-[200px] relative">
                          <select
                            className="bg-[#F2F4F7] border border-[#ccc] text-sm rounded-[5px] text-[#666] outline-none py-1.5 px-8 appearance-none pr-8"
                            defaultValue={users[0].email}
                            name="level2approver1"
                            onChange={handleChange}
                          >
                            {users.map((user, index) => (
                              <React.Fragment key={index}>
                                <option value={user._id}>{user.email}</option>
                              </React.Fragment>
                            ))}
                          </select>
                          <div className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="8"
                              height="5"
                              viewBox="0 0 8 5"
                              fill="none"
                            >
                              <path
                                d="M7.98647 0.124047C7.97318 0.0873128 7.95092 0.0559599 7.92247 0.0338999C7.89401 0.0118399 7.86063 4.97357e-05 7.82647 3.68173e-07H0.174242C0.140007 -7.57266e-05 0.106514 0.0116453 0.0779611 0.033695C0.0494081 0.0557447 0.027065 0.0871421 0.0137317 0.123952C0.000398509 0.160762 -0.00333174 0.201347 0.00300863 0.240621C0.00934901 0.279896 0.0254779 0.316112 0.0493739 0.344731L3.8754 4.9385C3.89161 4.95796 3.91099 4.97343 3.93241 4.98399C3.95383 4.99456 3.97684 5 4.0001 5C4.02335 5 4.04636 4.99456 4.06778 4.98399C4.0892 4.97343 4.10858 4.95796 4.12479 4.9385L7.95082 0.344528C7.97463 0.315913 7.9907 0.27974 7.99701 0.240527C8.00332 0.201314 7.99959 0.160801 7.98629 0.124047H7.98647Z"
                                fill="#052460"
                              />
                            </svg>
                          </div>
                        </form>
                      </div>
                    </>
                  )}

                  {formData.level2approval >= 2 && (
                    <>
                      <p className="text-sm text-[#666] font-normal pl-5">
                        Approver 2
                      </p>
                      <div className="px-[30px]">
                        <form className="w-[200px] relative">
                          <select
                            className="bg-[#F2F4F7] border border-[#ccc] text-sm rounded-[5px] text-[#666] outline-none py-1.5 px-8 appearance-none pr-8"
                            defaultValue={users[0].email}
                            name="level2approver2"
                            onChange={handleChange}
                          >
                            {users.map((user, index) => (
                              <React.Fragment key={index}>
                                <option value={user._id}>{user.email}</option>
                              </React.Fragment>
                            ))}
                          </select>
                          <div className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="8"
                              height="5"
                              viewBox="0 0 8 5"
                              fill="none"
                            >
                              <path
                                d="M7.98647 0.124047C7.97318 0.0873128 7.95092 0.0559599 7.92247 0.0338999C7.89401 0.0118399 7.86063 4.97357e-05 7.82647 3.68173e-07H0.174242C0.140007 -7.57266e-05 0.106514 0.0116453 0.0779611 0.033695C0.0494081 0.0557447 0.027065 0.0871421 0.0137317 0.123952C0.000398509 0.160762 -0.00333174 0.201347 0.00300863 0.240621C0.00934901 0.279896 0.0254779 0.316112 0.0493739 0.344731L3.8754 4.9385C3.89161 4.95796 3.91099 4.97343 3.93241 4.98399C3.95383 4.99456 3.97684 5 4.0001 5C4.02335 5 4.04636 4.99456 4.06778 4.98399C4.0892 4.97343 4.10858 4.95796 4.12479 4.9385L7.95082 0.344528C7.97463 0.315913 7.9907 0.27974 7.99701 0.240527C8.00332 0.201314 7.99959 0.160801 7.98629 0.124047H7.98647Z"
                                fill="#052460"
                              />
                            </svg>
                          </div>
                        </form>
                      </div>
                    </>
                  )}

                  {formData.level2approval >= 3 && (
                    <>
                      <p className="text-sm text-[#666] font-normal pl-5">
                        Approver 3
                      </p>
                      <div className="px-[30px]">
                        <form className="w-[200px] relative">
                          <select
                            className="bg-[#F2F4F7] border border-[#ccc] text-sm rounded-[5px] text-[#666] outline-none py-1.5 px-8 appearance-none pr-8"
                            defaultValue={users[0].email}
                            name="level2approver3"
                            onChange={handleChange}
                          >
                            {users.map((user, index) => (
                              <React.Fragment key={index}>
                                <option value={user._id}>{user.email}</option>
                              </React.Fragment>
                            ))}
                          </select>
                          <div className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="8"
                              height="5"
                              viewBox="0 0 8 5"
                              fill="none"
                            >
                              <path
                                d="M7.98647 0.124047C7.97318 0.0873128 7.95092 0.0559599 7.92247 0.0338999C7.89401 0.0118399 7.86063 4.97357e-05 7.82647 3.68173e-07H0.174242C0.140007 -7.57266e-05 0.106514 0.0116453 0.0779611 0.033695C0.0494081 0.0557447 0.027065 0.0871421 0.0137317 0.123952C0.000398509 0.160762 -0.00333174 0.201347 0.00300863 0.240621C0.00934901 0.279896 0.0254779 0.316112 0.0493739 0.344731L3.8754 4.9385C3.89161 4.95796 3.91099 4.97343 3.93241 4.98399C3.95383 4.99456 3.97684 5 4.0001 5C4.02335 5 4.04636 4.99456 4.06778 4.98399C4.0892 4.97343 4.10858 4.95796 4.12479 4.9385L7.95082 0.344528C7.97463 0.315913 7.9907 0.27974 7.99701 0.240527C8.00332 0.201314 7.99959 0.160801 7.98629 0.124047H7.98647Z"
                                fill="#052460"
                              />
                            </svg>
                          </div>
                        </form>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="py-[18px]">
              <div className="bg-[#F2F4F7] rounded-[5px] py-5 px-7 flex items-center">
                <div className="pr-[39px]">
                  <p className="text-[#666] text-base font-medium">Level 3</p>
                </div>
                <div className="flex items-center">
                  <input
                    type="number"
                    className="bg-transparent border border-[#ccc] rounded-[5px] w-[60px] py-[6px] px-2 text-[#666] text-xs font-medium"
                    name="min3"
                    value={formData.min3}
                    onChange={handleChange}
                  />
                  <p className="text-[#666] text-sm font-medium px-2.5">to</p>
                  <input
                    type="number"
                    className="bg-transparent border border-[#ccc] rounded-[5px] w-[60px] py-[6px] px-2 text-[#666] text-xs font-medium"
                    name="max3"
                    value={formData.max3}
                    onChange={handleChange}
                  />
                  <span className="px-5">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="19"
                      height="8"
                      viewBox="0 0 19 8"
                      fill="none"
                    >
                      <path
                        d="M18.7823 3.54332L18.7816 3.54271L14.9036 0.187869C14.613 -0.0634537 14.1431 -0.0625185 13.8539 0.190062C13.5648 0.442611 13.5659 0.851095 13.8564 1.10245L16.4602 3.35484H0.742187C0.332277 3.35484 0 3.64368 0 4C0 4.35632 0.332277 4.64516 0.742187 4.64516H16.4601L13.8564 6.89755C13.5659 7.1489 13.5648 7.55739 13.854 7.80994C14.1432 8.06255 14.6131 8.06342 14.9036 7.81213L18.7817 4.45729L18.7823 4.45668C19.073 4.20448 19.0721 3.79468 18.7823 3.54332Z"
                        fill="black"
                      />
                    </svg>
                  </span>
                  <p className="text-[#666] text-sm font-medium ">
                    Required Approvals
                  </p>
                  <div className="px-[30px]">
                    <form className="w-[88px] relative">
                      <select
                        name="level3approval"
                        value={formData.level3approval}
                        onChange={handleChange}
                        className="border border-[#ccc] bg-transparent text-sm rounded-[5px] text-[#666] outline-none py-1.5 px-8 appearance-none pr-8"
                      >
                        <option selected>0</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                      </select>
                      <div className="absolute inset-y-0 right-0 flex items-center pr-5 pointer-events-none">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="8"
                          height="5"
                          viewBox="0 0 8 5"
                          fill="none"
                        >
                          <path
                            d="M7.98647 0.124047C7.97318 0.0873128 7.95092 0.0559599 7.92247 0.0338999C7.89401 0.0118399 7.86063 4.97357e-05 7.82647 3.68173e-07H0.174242C0.140007 -7.57266e-05 0.106514 0.0116453 0.0779611 0.033695C0.0494081 0.0557447 0.027065 0.0871421 0.0137317 0.123952C0.000398509 0.160762 -0.00333174 0.201347 0.00300863 0.240621C0.00934901 0.279896 0.0254779 0.316112 0.0493739 0.344731L3.8754 4.9385C3.89161 4.95796 3.91099 4.97343 3.93241 4.98399C3.95383 4.99456 3.97684 5 4.0001 5C4.02335 5 4.04636 4.99456 4.06778 4.98399C4.0892 4.97343 4.10858 4.95796 4.12479 4.9385L7.95082 0.344528C7.97463 0.315913 7.9907 0.27974 7.99701 0.240527C8.00332 0.201314 7.99959 0.160801 7.98629 0.124047H7.98647Z"
                            fill="#052460"
                          />
                        </svg>
                      </div>
                    </form>
                  </div>
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="19"
                      height="8"
                      viewBox="0 0 19 8"
                      fill="none"
                    >
                      <path
                        d="M18.7823 3.54332L18.7816 3.54271L14.9036 0.187869C14.613 -0.0634537 14.1431 -0.0625185 13.8539 0.190062C13.5648 0.442611 13.5659 0.851095 13.8564 1.10245L16.4602 3.35484H0.742187C0.332277 3.35484 0 3.64368 0 4C0 4.35632 0.332277 4.64516 0.742187 4.64516H16.4601L13.8564 6.89755C13.5659 7.1489 13.5648 7.55739 13.854 7.80994C14.1432 8.06255 14.6131 8.06342 14.9036 7.81213L18.7817 4.45729L18.7823 4.45668C19.073 4.20448 19.0721 3.79468 18.7823 3.54332Z"
                        fill="black"
                      />
                    </svg>
                  </span>

                  {formData.level3approval >= 1 && (
                    <>
                      <p className="text-sm text-[#666] font-normal pl-5">
                        Approver 1
                      </p>
                      <div className="px-[30px]">
                        <form className="w-[200px] relative">
                          <select
                            className="bg-[#F2F4F7] border border-[#ccc] text-sm rounded-[5px] text-[#666] outline-none py-1.5 px-8 appearance-none pr-8"
                            defaultValue={users[0].email}
                            name="level3approver1"
                            onChange={handleChange}
                          >
                            {users.map((user, index) => (
                              <React.Fragment key={index}>
                                <option value={user._id}>{user.email}</option>
                              </React.Fragment>
                            ))}
                          </select>
                          <div className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="8"
                              height="5"
                              viewBox="0 0 8 5"
                              fill="none"
                            >
                              <path
                                d="M7.98647 0.124047C7.97318 0.0873128 7.95092 0.0559599 7.92247 0.0338999C7.89401 0.0118399 7.86063 4.97357e-05 7.82647 3.68173e-07H0.174242C0.140007 -7.57266e-05 0.106514 0.0116453 0.0779611 0.033695C0.0494081 0.0557447 0.027065 0.0871421 0.0137317 0.123952C0.000398509 0.160762 -0.00333174 0.201347 0.00300863 0.240621C0.00934901 0.279896 0.0254779 0.316112 0.0493739 0.344731L3.8754 4.9385C3.89161 4.95796 3.91099 4.97343 3.93241 4.98399C3.95383 4.99456 3.97684 5 4.0001 5C4.02335 5 4.04636 4.99456 4.06778 4.98399C4.0892 4.97343 4.10858 4.95796 4.12479 4.9385L7.95082 0.344528C7.97463 0.315913 7.9907 0.27974 7.99701 0.240527C8.00332 0.201314 7.99959 0.160801 7.98629 0.124047H7.98647Z"
                                fill="#052460"
                              />
                            </svg>
                          </div>
                        </form>
                      </div>
                    </>
                  )}

                  {formData.level3approval >= 2 && (
                    <>
                      <p className="text-sm text-[#666] font-normal pl-5">
                        Approver 2
                      </p>
                      <div className="px-[30px]">
                        <form className="w-[200px] relative">
                          <select
                            className="bg-[#F2F4F7] border border-[#ccc] text-sm rounded-[5px] text-[#666] outline-none py-1.5 px-8 appearance-none pr-8"
                            defaultValue={users[0].email}
                            name="level3approver2"
                            onChange={handleChange}
                          >
                            {users.map((user, index) => (
                              <React.Fragment key={index}>
                                <option value={user._id}>{user.email}</option>
                              </React.Fragment>
                            ))}
                          </select>
                          <div className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="8"
                              height="5"
                              viewBox="0 0 8 5"
                              fill="none"
                            >
                              <path
                                d="M7.98647 0.124047C7.97318 0.0873128 7.95092 0.0559599 7.92247 0.0338999C7.89401 0.0118399 7.86063 4.97357e-05 7.82647 3.68173e-07H0.174242C0.140007 -7.57266e-05 0.106514 0.0116453 0.0779611 0.033695C0.0494081 0.0557447 0.027065 0.0871421 0.0137317 0.123952C0.000398509 0.160762 -0.00333174 0.201347 0.00300863 0.240621C0.00934901 0.279896 0.0254779 0.316112 0.0493739 0.344731L3.8754 4.9385C3.89161 4.95796 3.91099 4.97343 3.93241 4.98399C3.95383 4.99456 3.97684 5 4.0001 5C4.02335 5 4.04636 4.99456 4.06778 4.98399C4.0892 4.97343 4.10858 4.95796 4.12479 4.9385L7.95082 0.344528C7.97463 0.315913 7.9907 0.27974 7.99701 0.240527C8.00332 0.201314 7.99959 0.160801 7.98629 0.124047H7.98647Z"
                                fill="#052460"
                              />
                            </svg>
                          </div>
                        </form>
                      </div>
                    </>
                  )}

                  {formData.level3approval >= 3 && (
                    <>
                      <p className="text-sm text-[#666] font-normal pl-5">
                        Approver 3
                      </p>
                      <div className="px-[30px]">
                        <form className="w-[200px] relative">
                          <select
                            className="bg-[#F2F4F7] border border-[#ccc] text-sm rounded-[5px] text-[#666] outline-none py-1.5 px-8 appearance-none pr-8"
                            defaultValue={users[0].email}
                            name="level3approver3"
                            onChange={handleChange}
                          >
                            {users.map((user, index) => (
                              <React.Fragment key={index}>
                                <option value={user._id}>{user.email}</option>
                              </React.Fragment>
                            ))}
                          </select>
                          <div className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="8"
                              height="5"
                              viewBox="0 0 8 5"
                              fill="none"
                            >
                              <path
                                d="M7.98647 0.124047C7.97318 0.0873128 7.95092 0.0559599 7.92247 0.0338999C7.89401 0.0118399 7.86063 4.97357e-05 7.82647 3.68173e-07H0.174242C0.140007 -7.57266e-05 0.106514 0.0116453 0.0779611 0.033695C0.0494081 0.0557447 0.027065 0.0871421 0.0137317 0.123952C0.000398509 0.160762 -0.00333174 0.201347 0.00300863 0.240621C0.00934901 0.279896 0.0254779 0.316112 0.0493739 0.344731L3.8754 4.9385C3.89161 4.95796 3.91099 4.97343 3.93241 4.98399C3.95383 4.99456 3.97684 5 4.0001 5C4.02335 5 4.04636 4.99456 4.06778 4.98399C4.0892 4.97343 4.10858 4.95796 4.12479 4.9385L7.95082 0.344528C7.97463 0.315913 7.9907 0.27974 7.99701 0.240527C8.00332 0.201314 7.99959 0.160801 7.98629 0.124047H7.98647Z"
                                fill="#052460"
                              />
                            </svg>
                          </div>
                        </form>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </>
        ) : selectedOption && selectedOption.value == 4 ? (
          <>
            <div className="py-[18px]">
              <div className="bg-[#F2F4F7] rounded-[5px] py-5 px-7 flex items-center">
                <div className="pr-[39px]">
                  <p className="text-[#666] text-base font-medium">Level 1</p>
                </div>
                <div className="flex items-center">
                  <input
                    type="number"
                    className="bg-transparent border border-[#ccc] rounded-[5px] w-[60px] py-[6px] px-2 text-[#666] text-xs font-medium"
                    name="min1"
                    value="0"
                    disabled
                    onChange={handleChange}
                  />
                  <p className="text-[#666] text-sm font-medium px-2.5">to</p>
                  <input
                    type="number"
                    className="bg-transparent border border-[#ccc] rounded-[5px] w-[60px] py-[6px] px-2 text-[#666] text-xs font-medium"
                    name="max1"
                    value="500"
                    disabled
                    onChange={handleChange}
                  />
                  <span className="px-5">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="19"
                      height="8"
                      viewBox="0 0 19 8"
                      fill="none"
                    >
                      <path
                        d="M18.7823 3.54332L18.7816 3.54271L14.9036 0.187869C14.613 -0.0634537 14.1431 -0.0625185 13.8539 0.190062C13.5648 0.442611 13.5659 0.851095 13.8564 1.10245L16.4602 3.35484H0.742187C0.332277 3.35484 0 3.64368 0 4C0 4.35632 0.332277 4.64516 0.742187 4.64516H16.4601L13.8564 6.89755C13.5659 7.1489 13.5648 7.55739 13.854 7.80994C14.1432 8.06255 14.6131 8.06342 14.9036 7.81213L18.7817 4.45729L18.7823 4.45668C19.073 4.20448 19.0721 3.79468 18.7823 3.54332Z"
                        fill="black"
                      />
                    </svg>
                  </span>
                  <p className="text-[#666] text-sm font-medium ">
                    Required Approvals
                  </p>
                  <div className="px-[30px]">
                    <form className="w-[88px] relative">
                      <select className="border border-[#ccc] bg-transparent text-sm rounded-[5px] text-[#666] outline-none py-1.5 px-8 appearance-none pr-8">
                        <option selected>0</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                      </select>
                      <div className="absolute inset-y-0 right-0 flex items-center pr-5 pointer-events-none">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="8"
                          height="5"
                          viewBox="0 0 8 5"
                          fill="none"
                        >
                          <path
                            d="M7.98647 0.124047C7.97318 0.0873128 7.95092 0.0559599 7.92247 0.0338999C7.89401 0.0118399 7.86063 4.97357e-05 7.82647 3.68173e-07H0.174242C0.140007 -7.57266e-05 0.106514 0.0116453 0.0779611 0.033695C0.0494081 0.0557447 0.027065 0.0871421 0.0137317 0.123952C0.000398509 0.160762 -0.00333174 0.201347 0.00300863 0.240621C0.00934901 0.279896 0.0254779 0.316112 0.0493739 0.344731L3.8754 4.9385C3.89161 4.95796 3.91099 4.97343 3.93241 4.98399C3.95383 4.99456 3.97684 5 4.0001 5C4.02335 5 4.04636 4.99456 4.06778 4.98399C4.0892 4.97343 4.10858 4.95796 4.12479 4.9385L7.95082 0.344528C7.97463 0.315913 7.9907 0.27974 7.99701 0.240527C8.00332 0.201314 7.99959 0.160801 7.98629 0.124047H7.98647Z"
                            fill="#052460"
                          />
                        </svg>
                      </div>
                    </form>
                  </div>
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="19"
                      height="8"
                      viewBox="0 0 19 8"
                      fill="none"
                    >
                      <path
                        d="M18.7823 3.54332L18.7816 3.54271L14.9036 0.187869C14.613 -0.0634537 14.1431 -0.0625185 13.8539 0.190062C13.5648 0.442611 13.5659 0.851095 13.8564 1.10245L16.4602 3.35484H0.742187C0.332277 3.35484 0 3.64368 0 4C0 4.35632 0.332277 4.64516 0.742187 4.64516H16.4601L13.8564 6.89755C13.5659 7.1489 13.5648 7.55739 13.854 7.80994C14.1432 8.06255 14.6131 8.06342 14.9036 7.81213L18.7817 4.45729L18.7823 4.45668C19.073 4.20448 19.0721 3.79468 18.7823 3.54332Z"
                        fill="black"
                      />
                    </svg>
                  </span>
                  <p className="text-sm text-[#666] font-normal pl-5">
                    Auto <span className="text-[#81C784]">Approved</span>
                  </p>
                </div>
              </div>
            </div>
            <div className="py-[18px]">
              <div className=" px-7 flex items-center">
                <div className="pr-[39px]">
                  <p className="text-[#666] text-base font-medium">Level 2</p>
                </div>
                <div className="flex items-center">
                  <input
                    type="number"
                    className="bg-transparent border border-[#ccc] rounded-[5px] w-[60px] py-[6px] px-2 text-[#666] text-xs font-medium"
                    name="min2"
                    value={formData.min2}
                    onChange={handleChange}
                  />
                  <p className="text-[#666] text-sm font-medium px-2.5">to</p>
                  <input
                    type="number"
                    className="bg-transparent border border-[#ccc] rounded-[5px] w-[60px] py-[6px] px-2 text-[#666] text-xs font-medium"
                    name="max2"
                    value={formData.max2}
                    onChange={handleChange}
                  />
                  <span className="px-5">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="19"
                      height="8"
                      viewBox="0 0 19 8"
                      fill="none"
                    >
                      <path
                        d="M18.7823 3.54332L18.7816 3.54271L14.9036 0.187869C14.613 -0.0634537 14.1431 -0.0625185 13.8539 0.190062C13.5648 0.442611 13.5659 0.851095 13.8564 1.10245L16.4602 3.35484H0.742187C0.332277 3.35484 0 3.64368 0 4C0 4.35632 0.332277 4.64516 0.742187 4.64516H16.4601L13.8564 6.89755C13.5659 7.1489 13.5648 7.55739 13.854 7.80994C14.1432 8.06255 14.6131 8.06342 14.9036 7.81213L18.7817 4.45729L18.7823 4.45668C19.073 4.20448 19.0721 3.79468 18.7823 3.54332Z"
                        fill="black"
                      />
                    </svg>
                  </span>
                  <p className="text-[#666] text-sm font-medium ">
                    Required Approvals
                  </p>
                  <div className="px-[30px]">
                    <form className="w-[88px] relative">
                      <select
                        name="level4approval"
                        value={formData.level4approval}
                        onChange={handleChange}
                        className="border border-[#ccc] bg-transparent text-sm rounded-[5px] text-[#666] outline-none py-1.5 px-8 appearance-none pr-8"
                      >
                        <option selected>0</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                      </select>
                      <div className="absolute inset-y-0 right-0 flex items-center pr-5 pointer-events-none">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="8"
                          height="5"
                          viewBox="0 0 8 5"
                          fill="none"
                        >
                          <path
                            d="M7.98647 0.124047C7.97318 0.0873128 7.95092 0.0559599 7.92247 0.0338999C7.89401 0.0118399 7.86063 4.97357e-05 7.82647 3.68173e-07H0.174242C0.140007 -7.57266e-05 0.106514 0.0116453 0.0779611 0.033695C0.0494081 0.0557447 0.027065 0.0871421 0.0137317 0.123952C0.000398509 0.160762 -0.00333174 0.201347 0.00300863 0.240621C0.00934901 0.279896 0.0254779 0.316112 0.0493739 0.344731L3.8754 4.9385C3.89161 4.95796 3.91099 4.97343 3.93241 4.98399C3.95383 4.99456 3.97684 5 4.0001 5C4.02335 5 4.04636 4.99456 4.06778 4.98399C4.0892 4.97343 4.10858 4.95796 4.12479 4.9385L7.95082 0.344528C7.97463 0.315913 7.9907 0.27974 7.99701 0.240527C8.00332 0.201314 7.99959 0.160801 7.98629 0.124047H7.98647Z"
                            fill="#052460"
                          />
                        </svg>
                      </div>
                    </form>
                  </div>
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="19"
                      height="8"
                      viewBox="0 0 19 8"
                      fill="none"
                    >
                      <path
                        d="M18.7823 3.54332L18.7816 3.54271L14.9036 0.187869C14.613 -0.0634537 14.1431 -0.0625185 13.8539 0.190062C13.5648 0.442611 13.5659 0.851095 13.8564 1.10245L16.4602 3.35484H0.742187C0.332277 3.35484 0 3.64368 0 4C0 4.35632 0.332277 4.64516 0.742187 4.64516H16.4601L13.8564 6.89755C13.5659 7.1489 13.5648 7.55739 13.854 7.80994C14.1432 8.06255 14.6131 8.06342 14.9036 7.81213L18.7817 4.45729L18.7823 4.45668C19.073 4.20448 19.0721 3.79468 18.7823 3.54332Z"
                        fill="black"
                      />
                    </svg>
                  </span>

                  {formData.level2approval >= 1 && (
                    <>
                      <p className="text-sm text-[#666] font-normal pl-5">
                        Approver 1
                      </p>
                      <div className="px-[30px]">
                        <form className="w-[200px] relative">
                          <select
                            className="bg-[#F2F4F7] border border-[#ccc] text-sm rounded-[5px] text-[#666] outline-none py-1.5 px-8 appearance-none pr-8"
                            defaultValue={users[0].email}
                            name="level2approver1"
                            onChange={handleChange}
                          >
                            {users.map((user, index) => (
                              <React.Fragment key={index}>
                                <option value={user._id}>{user.email}</option>
                              </React.Fragment>
                            ))}
                          </select>
                          <div className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="8"
                              height="5"
                              viewBox="0 0 8 5"
                              fill="none"
                            >
                              <path
                                d="M7.98647 0.124047C7.97318 0.0873128 7.95092 0.0559599 7.92247 0.0338999C7.89401 0.0118399 7.86063 4.97357e-05 7.82647 3.68173e-07H0.174242C0.140007 -7.57266e-05 0.106514 0.0116453 0.0779611 0.033695C0.0494081 0.0557447 0.027065 0.0871421 0.0137317 0.123952C0.000398509 0.160762 -0.00333174 0.201347 0.00300863 0.240621C0.00934901 0.279896 0.0254779 0.316112 0.0493739 0.344731L3.8754 4.9385C3.89161 4.95796 3.91099 4.97343 3.93241 4.98399C3.95383 4.99456 3.97684 5 4.0001 5C4.02335 5 4.04636 4.99456 4.06778 4.98399C4.0892 4.97343 4.10858 4.95796 4.12479 4.9385L7.95082 0.344528C7.97463 0.315913 7.9907 0.27974 7.99701 0.240527C8.00332 0.201314 7.99959 0.160801 7.98629 0.124047H7.98647Z"
                                fill="#052460"
                              />
                            </svg>
                          </div>
                        </form>
                      </div>
                    </>
                  )}

                  {formData.level2approval >= 2 && (
                    <>
                      <p className="text-sm text-[#666] font-normal pl-5">
                        Approver 2
                      </p>
                      <div className="px-[30px]">
                        <form className="w-[200px] relative">
                          <select
                            className="bg-[#F2F4F7] border border-[#ccc] text-sm rounded-[5px] text-[#666] outline-none py-1.5 px-8 appearance-none pr-8"
                            defaultValue={users[0].email}
                            name="level2approver2"
                            onChange={handleChange}
                          >
                            {users.map((user, index) => (
                              <React.Fragment key={index}>
                                <option value={user._id}>{user.email}</option>
                              </React.Fragment>
                            ))}
                          </select>
                          <div className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="8"
                              height="5"
                              viewBox="0 0 8 5"
                              fill="none"
                            >
                              <path
                                d="M7.98647 0.124047C7.97318 0.0873128 7.95092 0.0559599 7.92247 0.0338999C7.89401 0.0118399 7.86063 4.97357e-05 7.82647 3.68173e-07H0.174242C0.140007 -7.57266e-05 0.106514 0.0116453 0.0779611 0.033695C0.0494081 0.0557447 0.027065 0.0871421 0.0137317 0.123952C0.000398509 0.160762 -0.00333174 0.201347 0.00300863 0.240621C0.00934901 0.279896 0.0254779 0.316112 0.0493739 0.344731L3.8754 4.9385C3.89161 4.95796 3.91099 4.97343 3.93241 4.98399C3.95383 4.99456 3.97684 5 4.0001 5C4.02335 5 4.04636 4.99456 4.06778 4.98399C4.0892 4.97343 4.10858 4.95796 4.12479 4.9385L7.95082 0.344528C7.97463 0.315913 7.9907 0.27974 7.99701 0.240527C8.00332 0.201314 7.99959 0.160801 7.98629 0.124047H7.98647Z"
                                fill="#052460"
                              />
                            </svg>
                          </div>
                        </form>
                      </div>
                    </>
                  )}

                  {formData.level2approval >= 3 && (
                    <>
                      <p className="text-sm text-[#666] font-normal pl-5">
                        Approver 3
                      </p>
                      <div className="px-[30px]">
                        <form className="w-[200px] relative">
                          <select
                            className="bg-[#F2F4F7] border border-[#ccc] text-sm rounded-[5px] text-[#666] outline-none py-1.5 px-8 appearance-none pr-8"
                            defaultValue={users[0].email}
                            name="level2approver3"
                            onChange={handleChange}
                          >
                            {users.map((user, index) => (
                              <React.Fragment key={index}>
                                <option value={user._id}>{user.email}</option>
                              </React.Fragment>
                            ))}
                          </select>
                          <div className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="8"
                              height="5"
                              viewBox="0 0 8 5"
                              fill="none"
                            >
                              <path
                                d="M7.98647 0.124047C7.97318 0.0873128 7.95092 0.0559599 7.92247 0.0338999C7.89401 0.0118399 7.86063 4.97357e-05 7.82647 3.68173e-07H0.174242C0.140007 -7.57266e-05 0.106514 0.0116453 0.0779611 0.033695C0.0494081 0.0557447 0.027065 0.0871421 0.0137317 0.123952C0.000398509 0.160762 -0.00333174 0.201347 0.00300863 0.240621C0.00934901 0.279896 0.0254779 0.316112 0.0493739 0.344731L3.8754 4.9385C3.89161 4.95796 3.91099 4.97343 3.93241 4.98399C3.95383 4.99456 3.97684 5 4.0001 5C4.02335 5 4.04636 4.99456 4.06778 4.98399C4.0892 4.97343 4.10858 4.95796 4.12479 4.9385L7.95082 0.344528C7.97463 0.315913 7.9907 0.27974 7.99701 0.240527C8.00332 0.201314 7.99959 0.160801 7.98629 0.124047H7.98647Z"
                                fill="#052460"
                              />
                            </svg>
                          </div>
                        </form>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="py-[18px]">
              <div className="bg-[#F2F4F7] rounded-[5px] py-5 px-7 flex items-center">
                <div className="pr-[39px]">
                  <p className="text-[#666] text-base font-medium">Level 3</p>
                </div>
                <div className="flex items-center">
                  <input
                    type="number"
                    className="bg-transparent border border-[#ccc] rounded-[5px] w-[60px] py-[6px] px-2 text-[#666] text-xs font-medium"
                    name="min3"
                    value={formData.min3}
                    onChange={handleChange}
                  />
                  <p className="text-[#666] text-sm font-medium px-2.5">to</p>
                  <input
                    type="number"
                    className="bg-transparent border border-[#ccc] rounded-[5px] w-[60px] py-[6px] px-2 text-[#666] text-xs font-medium"
                    name="max3"
                    value={formData.max3}
                    onChange={handleChange}
                  />
                  <span className="px-5">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="19"
                      height="8"
                      viewBox="0 0 19 8"
                      fill="none"
                    >
                      <path
                        d="M18.7823 3.54332L18.7816 3.54271L14.9036 0.187869C14.613 -0.0634537 14.1431 -0.0625185 13.8539 0.190062C13.5648 0.442611 13.5659 0.851095 13.8564 1.10245L16.4602 3.35484H0.742187C0.332277 3.35484 0 3.64368 0 4C0 4.35632 0.332277 4.64516 0.742187 4.64516H16.4601L13.8564 6.89755C13.5659 7.1489 13.5648 7.55739 13.854 7.80994C14.1432 8.06255 14.6131 8.06342 14.9036 7.81213L18.7817 4.45729L18.7823 4.45668C19.073 4.20448 19.0721 3.79468 18.7823 3.54332Z"
                        fill="black"
                      />
                    </svg>
                  </span>
                  <p className="text-[#666] text-sm font-medium ">
                    Required Approvals
                  </p>
                  <div className="px-[30px]">
                    <form className="w-[88px] relative">
                      <select className="border border-[#ccc] bg-transparent text-sm rounded-[5px] text-[#666] outline-none py-1.5 px-8 appearance-none pr-8">
                        <option selected>0</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                      </select>
                      <div className="absolute inset-y-0 right-0 flex items-center pr-5 pointer-events-none">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="8"
                          height="5"
                          viewBox="0 0 8 5"
                          fill="none"
                        >
                          <path
                            d="M7.98647 0.124047C7.97318 0.0873128 7.95092 0.0559599 7.92247 0.0338999C7.89401 0.0118399 7.86063 4.97357e-05 7.82647 3.68173e-07H0.174242C0.140007 -7.57266e-05 0.106514 0.0116453 0.0779611 0.033695C0.0494081 0.0557447 0.027065 0.0871421 0.0137317 0.123952C0.000398509 0.160762 -0.00333174 0.201347 0.00300863 0.240621C0.00934901 0.279896 0.0254779 0.316112 0.0493739 0.344731L3.8754 4.9385C3.89161 4.95796 3.91099 4.97343 3.93241 4.98399C3.95383 4.99456 3.97684 5 4.0001 5C4.02335 5 4.04636 4.99456 4.06778 4.98399C4.0892 4.97343 4.10858 4.95796 4.12479 4.9385L7.95082 0.344528C7.97463 0.315913 7.9907 0.27974 7.99701 0.240527C8.00332 0.201314 7.99959 0.160801 7.98629 0.124047H7.98647Z"
                            fill="#052460"
                          />
                        </svg>
                      </div>
                    </form>
                  </div>
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="19"
                      height="8"
                      viewBox="0 0 19 8"
                      fill="none"
                    >
                      <path
                        d="M18.7823 3.54332L18.7816 3.54271L14.9036 0.187869C14.613 -0.0634537 14.1431 -0.0625185 13.8539 0.190062C13.5648 0.442611 13.5659 0.851095 13.8564 1.10245L16.4602 3.35484H0.742187C0.332277 3.35484 0 3.64368 0 4C0 4.35632 0.332277 4.64516 0.742187 4.64516H16.4601L13.8564 6.89755C13.5659 7.1489 13.5648 7.55739 13.854 7.80994C14.1432 8.06255 14.6131 8.06342 14.9036 7.81213L18.7817 4.45729L18.7823 4.45668C19.073 4.20448 19.0721 3.79468 18.7823 3.54332Z"
                        fill="black"
                      />
                    </svg>
                  </span>
                  <p className="text-sm text-[#666] font-normal pl-5">
                    Approver 1
                  </p>
                  <div className="px-[30px]">
                    <form className="w-[200px] relative">
                      <select className="bg-[#F2F4F7] border border-[#ccc]  text-sm rounded-[5px] text-[#666] outline-none py-1.5 px-8 appearance-none pr-8">
                        <option selected>johnsmith@mail.com</option>
                        <option value="1">johnsmith@mail.com</option>
                        <option value="2">johnsmith@mail.com</option>
                        <option value="3">johnsmith@mail.com</option>
                        <option value="4">johnsmith@mail.com</option>
                      </select>
                      <div className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="8"
                          height="5"
                          viewBox="0 0 8 5"
                          fill="none"
                        >
                          <path
                            d="M7.98647 0.124047C7.97318 0.0873128 7.95092 0.0559599 7.92247 0.0338999C7.89401 0.0118399 7.86063 4.97357e-05 7.82647 3.68173e-07H0.174242C0.140007 -7.57266e-05 0.106514 0.0116453 0.0779611 0.033695C0.0494081 0.0557447 0.027065 0.0871421 0.0137317 0.123952C0.000398509 0.160762 -0.00333174 0.201347 0.00300863 0.240621C0.00934901 0.279896 0.0254779 0.316112 0.0493739 0.344731L3.8754 4.9385C3.89161 4.95796 3.91099 4.97343 3.93241 4.98399C3.95383 4.99456 3.97684 5 4.0001 5C4.02335 5 4.04636 4.99456 4.06778 4.98399C4.0892 4.97343 4.10858 4.95796 4.12479 4.9385L7.95082 0.344528C7.97463 0.315913 7.9907 0.27974 7.99701 0.240527C8.00332 0.201314 7.99959 0.160801 7.98629 0.124047H7.98647Z"
                            fill="#052460"
                          />
                        </svg>
                      </div>
                    </form>
                  </div>
                  <p className="text-sm text-[#666] font-normal pl-5">
                    Approver 2
                  </p>
                  <div className="px-[30px]">
                    <form className="w-[200px] relative">
                      <select className="bg-[#F2F4F7] border border-[#ccc]  text-sm rounded-[5px] text-[#666] outline-none py-1.5 px-8 appearance-none pr-8">
                        <option selected>johnsmith@mail.com</option>
                        <option value="1">johnsmith@mail.com</option>
                        <option value="2">johnsmith@mail.com</option>
                        <option value="3">johnsmith@mail.com</option>
                        <option value="4">johnsmith@mail.com</option>
                      </select>
                      <div className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="8"
                          height="5"
                          viewBox="0 0 8 5"
                          fill="none"
                        >
                          <path
                            d="M7.98647 0.124047C7.97318 0.0873128 7.95092 0.0559599 7.92247 0.0338999C7.89401 0.0118399 7.86063 4.97357e-05 7.82647 3.68173e-07H0.174242C0.140007 -7.57266e-05 0.106514 0.0116453 0.0779611 0.033695C0.0494081 0.0557447 0.027065 0.0871421 0.0137317 0.123952C0.000398509 0.160762 -0.00333174 0.201347 0.00300863 0.240621C0.00934901 0.279896 0.0254779 0.316112 0.0493739 0.344731L3.8754 4.9385C3.89161 4.95796 3.91099 4.97343 3.93241 4.98399C3.95383 4.99456 3.97684 5 4.0001 5C4.02335 5 4.04636 4.99456 4.06778 4.98399C4.0892 4.97343 4.10858 4.95796 4.12479 4.9385L7.95082 0.344528C7.97463 0.315913 7.9907 0.27974 7.99701 0.240527C8.00332 0.201314 7.99959 0.160801 7.98629 0.124047H7.98647Z"
                            fill="#052460"
                          />
                        </svg>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div className="py-[18px]">
              <div className="bg-[#F2F4F7] rounded-[5px] py-5 px-7 flex items-center">
                <div className="pr-[39px]">
                  <p className="text-[#666] text-base font-medium">Level 4</p>
                </div>
                <div className="flex items-center">
                  <input
                    type="number"
                    className="bg-transparent border border-[#ccc] rounded-[5px] w-[60px] py-[6px] px-2 text-[#666] text-xs font-medium"
                    name="min4"
                    value={formData.min4}
                    onChange={handleChange}
                  />
                  <p className="text-[#666] text-sm font-medium px-2.5">to</p>
                  <input
                    type="number"
                    className="bg-transparent border border-[#ccc] rounded-[5px] w-[60px] py-[6px] px-2 text-[#666] text-xs font-medium"
                    name="max4"
                    value={formData.max4}
                    onChange={handleChange}
                  />
                  <span className="px-5">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="19"
                      height="8"
                      viewBox="0 0 19 8"
                      fill="none"
                    >
                      <path
                        d="M18.7823 3.54332L18.7816 3.54271L14.9036 0.187869C14.613 -0.0634537 14.1431 -0.0625185 13.8539 0.190062C13.5648 0.442611 13.5659 0.851095 13.8564 1.10245L16.4602 3.35484H0.742187C0.332277 3.35484 0 3.64368 0 4C0 4.35632 0.332277 4.64516 0.742187 4.64516H16.4601L13.8564 6.89755C13.5659 7.1489 13.5648 7.55739 13.854 7.80994C14.1432 8.06255 14.6131 8.06342 14.9036 7.81213L18.7817 4.45729L18.7823 4.45668C19.073 4.20448 19.0721 3.79468 18.7823 3.54332Z"
                        fill="black"
                      />
                    </svg>
                  </span>
                  <p className="text-[#666] text-sm font-medium ">
                    Required Approvals
                  </p>
                  <div className="px-[30px]">
                    <form className="w-[88px] relative">
                      <select className="border border-[#ccc] bg-transparent text-sm rounded-[5px] text-[#666] outline-none py-1.5 px-8 appearance-none pr-8">
                        <option selected>0</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                      </select>
                      <div className="absolute inset-y-0 right-0 flex items-center pr-5 pointer-events-none">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="8"
                          height="5"
                          viewBox="0 0 8 5"
                          fill="none"
                        >
                          <path
                            d="M7.98647 0.124047C7.97318 0.0873128 7.95092 0.0559599 7.92247 0.0338999C7.89401 0.0118399 7.86063 4.97357e-05 7.82647 3.68173e-07H0.174242C0.140007 -7.57266e-05 0.106514 0.0116453 0.0779611 0.033695C0.0494081 0.0557447 0.027065 0.0871421 0.0137317 0.123952C0.000398509 0.160762 -0.00333174 0.201347 0.00300863 0.240621C0.00934901 0.279896 0.0254779 0.316112 0.0493739 0.344731L3.8754 4.9385C3.89161 4.95796 3.91099 4.97343 3.93241 4.98399C3.95383 4.99456 3.97684 5 4.0001 5C4.02335 5 4.04636 4.99456 4.06778 4.98399C4.0892 4.97343 4.10858 4.95796 4.12479 4.9385L7.95082 0.344528C7.97463 0.315913 7.9907 0.27974 7.99701 0.240527C8.00332 0.201314 7.99959 0.160801 7.98629 0.124047H7.98647Z"
                            fill="#052460"
                          />
                        </svg>
                      </div>
                    </form>
                  </div>
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="19"
                      height="8"
                      viewBox="0 0 19 8"
                      fill="none"
                    >
                      <path
                        d="M18.7823 3.54332L18.7816 3.54271L14.9036 0.187869C14.613 -0.0634537 14.1431 -0.0625185 13.8539 0.190062C13.5648 0.442611 13.5659 0.851095 13.8564 1.10245L16.4602 3.35484H0.742187C0.332277 3.35484 0 3.64368 0 4C0 4.35632 0.332277 4.64516 0.742187 4.64516H16.4601L13.8564 6.89755C13.5659 7.1489 13.5648 7.55739 13.854 7.80994C14.1432 8.06255 14.6131 8.06342 14.9036 7.81213L18.7817 4.45729L18.7823 4.45668C19.073 4.20448 19.0721 3.79468 18.7823 3.54332Z"
                        fill="black"
                      />
                    </svg>
                  </span>
                  <p className="text-sm text-[#666] font-normal pl-5">
                    Approver 1
                  </p>
                  <div className="px-[30px]">
                    <form className="w-[200px] relative">
                      <select className="bg-[#F2F4F7] border border-[#ccc]  text-sm rounded-[5px] text-[#666] outline-none py-1.5 px-8 appearance-none pr-8">
                        <option selected>johnsmith@mail.com</option>
                        <option value="1">johnsmith@mail.com</option>
                        <option value="2">johnsmith@mail.com</option>
                        <option value="3">johnsmith@mail.com</option>
                        <option value="4">johnsmith@mail.com</option>
                      </select>
                      <div className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="8"
                          height="5"
                          viewBox="0 0 8 5"
                          fill="none"
                        >
                          <path
                            d="M7.98647 0.124047C7.97318 0.0873128 7.95092 0.0559599 7.92247 0.0338999C7.89401 0.0118399 7.86063 4.97357e-05 7.82647 3.68173e-07H0.174242C0.140007 -7.57266e-05 0.106514 0.0116453 0.0779611 0.033695C0.0494081 0.0557447 0.027065 0.0871421 0.0137317 0.123952C0.000398509 0.160762 -0.00333174 0.201347 0.00300863 0.240621C0.00934901 0.279896 0.0254779 0.316112 0.0493739 0.344731L3.8754 4.9385C3.89161 4.95796 3.91099 4.97343 3.93241 4.98399C3.95383 4.99456 3.97684 5 4.0001 5C4.02335 5 4.04636 4.99456 4.06778 4.98399C4.0892 4.97343 4.10858 4.95796 4.12479 4.9385L7.95082 0.344528C7.97463 0.315913 7.9907 0.27974 7.99701 0.240527C8.00332 0.201314 7.99959 0.160801 7.98629 0.124047H7.98647Z"
                            fill="#052460"
                          />
                        </svg>
                      </div>
                    </form>
                  </div>
                  <p className="text-sm text-[#666] font-normal pl-5">
                    Approver 2
                  </p>
                  <div className="px-[30px]">
                    <form className="w-[200px] relative">
                      <select className="bg-[#F2F4F7] border border-[#ccc]  text-sm rounded-[5px] text-[#666] outline-none py-1.5 px-8 appearance-none pr-8">
                        <option selected>johnsmith@mail.com</option>
                        <option value="1">johnsmith@mail.com</option>
                        <option value="2">johnsmith@mail.com</option>
                        <option value="3">johnsmith@mail.com</option>
                        <option value="4">johnsmith@mail.com</option>
                      </select>
                      <div className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="8"
                          height="5"
                          viewBox="0 0 8 5"
                          fill="none"
                        >
                          <path
                            d="M7.98647 0.124047C7.97318 0.0873128 7.95092 0.0559599 7.92247 0.0338999C7.89401 0.0118399 7.86063 4.97357e-05 7.82647 3.68173e-07H0.174242C0.140007 -7.57266e-05 0.106514 0.0116453 0.0779611 0.033695C0.0494081 0.0557447 0.027065 0.0871421 0.0137317 0.123952C0.000398509 0.160762 -0.00333174 0.201347 0.00300863 0.240621C0.00934901 0.279896 0.0254779 0.316112 0.0493739 0.344731L3.8754 4.9385C3.89161 4.95796 3.91099 4.97343 3.93241 4.98399C3.95383 4.99456 3.97684 5 4.0001 5C4.02335 5 4.04636 4.99456 4.06778 4.98399C4.0892 4.97343 4.10858 4.95796 4.12479 4.9385L7.95082 0.344528C7.97463 0.315913 7.9907 0.27974 7.99701 0.240527C8.00332 0.201314 7.99959 0.160801 7.98629 0.124047H7.98647Z"
                            fill="#052460"
                          />
                        </svg>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>{/* Fallback content if none of the above conditions are met */}</>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = ({ Settings, Auth }) => {
  let {} = Settings;
  return {};
};

const mapDispatchToProps = {
  overlayLoader,
};
export default connect(mapStateToProps, mapDispatchToProps)(PolicyLevel);
