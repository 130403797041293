var initialState = {
  showOtpLoader: false,
  overlayLoading: false,
  loadingMessage: "Loading",
}
const Settings = (state = initialState, { type, payload }) => {
  switch (type) {

    case "OTP_LOADER":
      return {
        ...state,
        showOtpLoader: payload,
      }

    case "OVERLAY_LOADER":
      return {
        ...state,
        overlayLoading: payload.status,
        error: undefined,
        loadingMessage: payload.loadingMessage,
      }

    default:
      return state
  }
}
export default Settings
