import React, { useState } from "react";
import MyIntegrations from "./myIntegrations";
import ManualSyncing from "./manualSyncing";

const Tabs = () => {
  const [activeTab, setActiveTab] = useState("My Integration");

  const tabData = {
    "My Integration": <MyIntegrations />,
    "Manual Syncing": <ManualSyncing />,
  };

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  return (
    <div>
      <div className="container-class">
        <div classN>
          <div className="text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700">
            <div className="pt-[50px] flex items-center flex-col-reverse lg:flex-row justify-between">
              {/* tabs-links */}
              <ul className="flex flex-wrap w-full lg:w-[50%]  ">
                {Object.keys(tabData).map((tabName) => (
                  <li key={tabName} className="me-2 cursor-pointer">
                    <button
                      onClick={() => handleTabClick(tabName)}
                      className={`inline-block p-[20px]  border-b-2 rounded-t-lg ${
                        activeTab === tabName
                          ? "text-[#004761] border-[#004761] "
                          : "border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 font-normal"
                      }`}
                      aria-current={activeTab === tabName ? "page" : "false"}
                    >
                      {tabName}
                    </button>
                  </li>
                ))}
              </ul>
              {/* buttons */}
              {activeTab === "My Integration" && (
                <div className="flex items-center mb-[18px]">
                  <div className="border border-[#004761] bg-[#004761] rounded-[5px] mx-2.5">
                    <button className="flex items-center py-[12px] px-[32px] text-white text-xs font-normal">
                      <div className="pr-[10px]">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="13"
                          viewBox="0 0 16 13"
                          fill="none"
                        >
                          <path
                            d="M0.592998 8.14596C0.771084 8.14596 0.947388 8.06641 1.06374 7.91445L1.32196 7.57905C1.70841 9.87161 3.31414 11.8329 5.59364 12.6242C6.29529 12.8676 7.01535 12.9845 7.72591 12.9845C9.85344 12.9845 11.9008 11.935 13.1267 10.0871C13.3077 9.81403 13.2329 9.44539 12.9598 9.26434C12.6856 9.0821 12.3187 9.15808 12.1365 9.43114C10.8015 11.4459 8.27086 12.2983 5.98364 11.5029C4.08585 10.844 2.76208 9.19191 2.47952 7.27334L2.98765 7.66454C3.24825 7.8634 3.62045 7.81591 3.81991 7.5559C4.01996 7.2959 3.97187 6.9237 3.71187 6.72365C3.71187 6.72365 2.30796 5.64267 2.3044 5.6403C2.00937 5.41294 1.58968 5.28531 1.32256 5.63377L0.123446 7.19083C-0.0766033 7.45083 -0.0285201 7.82303 0.231485 8.02308C0.33893 8.10559 0.466557 8.14596 0.592998 8.14596Z"
                            fill="white"
                          />
                          <path
                            d="M2.4746 3.72052C2.74707 3.90158 3.11571 3.82678 3.29795 3.55372C4.633 1.53957 7.16597 0.687133 9.4508 1.48199C11.3486 2.1409 12.6724 3.79294 12.9549 5.71152L12.4468 5.32032C12.1862 5.12087 11.814 5.16836 11.6145 5.42895C11.4145 5.68896 11.4626 6.06116 11.7226 6.26121C11.7226 6.26121 13.1265 7.34159 13.13 7.34456C13.4304 7.57548 13.8406 7.70489 14.1119 7.35109L15.311 5.79403C15.511 5.53403 15.463 5.16183 15.203 4.96178C14.943 4.76173 14.5702 4.8104 14.3707 5.07041L14.1125 5.4058C13.726 3.11325 12.1203 1.15194 9.84081 0.360643C7.03893 -0.611704 3.94262 0.431283 2.30779 2.89717C2.12674 3.17083 2.20154 3.53887 2.4746 3.72052Z"
                            fill="white"
                          />
                        </svg>
                      </div>
                      Upload Files
                    </button>
                  </div>
                </div>
              )}

              {activeTab === "Manual Syncing" && (
                <div className="flex items-center mb-[18px]">
                  <div className="border border-[#004761] rounded-[5px]">
                    <button className="py-[12px] px-12 text-[#004761] text-xs font-normal">
                      Sample Files
                    </button>
                  </div>
                  <div className="border border-[#004761] bg-[#004761] rounded-[5px] mx-2.5">
                    <button className="py-[12px] px-12 text-white text-xs font-normal">
                      Upload Files
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="pt-[35px]">{tabData[activeTab]}</div>
        </div>
      </div>
    </div>
  );
};

export default Tabs;
